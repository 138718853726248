import { userRequest } from "../../api/requestMethods";
import {
  addStopFailure,
  addStopStart,
  addStopSuccess,
  deleteStopFailure,
  deleteStopStart,
  deleteStopSuccess,
  getFilterStopsFailure,
  getFilterStopsStart,
  getFilterStopsSuccess,
  getStopsFailure,
  getStopsStart,
  getStopsSuccess,
  updateStopFailure,
  updateStopStart,
  updateStopSuccess,
} from "../reducers/stopsReducer";

export const getStops = async (dispatch) => {
  dispatch(getStopsStart());
  try {
    const res = await userRequest.get("stops/getStops");
    // console.log("response from get stops ==>", res);
    dispatch(getStopsSuccess(res.data));
  } catch (error) {
    console.log("error from stopsFailure >>>", error);
    dispatch(getStopsFailure());
  }
};

export const getFilterStops = async (dispatch, inputData) => {
  console.log("call >>");
  dispatch(getFilterStopsStart());
  try {
    const res = await userRequest.post("stops/getStopsByDate", inputData);
    // console.log("response from get stops ==>", res);
    dispatch(getFilterStopsSuccess(res.data));
    return res.data;
  } catch (error) {
    console.log("error from stopsFailure >>>", error);
    dispatch(getFilterStopsFailure());
  }
};

export const createStops = async (dispatch, inputData) => {
  // console.log("inputData >>>", inputData);
  dispatch(addStopStart());
  try {
    const res = await userRequest.post("stops/createStops", inputData);
    console.log("response from add stops ==>", res);
    dispatch(addStopSuccess(res.data));
  } catch (error) {
    console.log("error from stopsFailure >>>", error);
    dispatch(addStopFailure());
  }
};

export const updateStops = async (inputData, dispatch) => {
  dispatch(updateStopStart());
  try {
    const res = await userRequest.post("stops/updateStops", inputData);
    console.log("response from update stops ==>", res);
    dispatch(updateStopSuccess(res.data));
  } catch (error) {
    console.log("error from stopsFailure >>>", error);
    dispatch(updateStopFailure());
  }
};

export const deleteStops = async (id, dispatch) => {
  dispatch(deleteStopStart());
  try {
    const res = await userRequest.post("stops/deleteStops", { id });
    // console.log("response from delete stops ==>", res);
    dispatch(deleteStopSuccess(res.data));
  } catch (error) {
    console.log("error from stopsFailure >>>", error);
    dispatch(deleteStopFailure());
  }
};
