import React from "react";
import { useLocation } from "react-router-dom";
// import "./ToolBar.css";

const ToolBar = () => {
  const location = useLocation();
  const toolBarName = location.pathname
  let barName = toolBarName?.split("/")
  barName = barName[1].toLocaleUpperCase()
  return (
    <div className="toolbar py-2">
      {/*begin::Container*/}
      <div className="container-fluid d-flex align-items-center">
        <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
          {/*begin::Title*/}
          <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {toolBarName === "/" ? "DASHBOARD" : barName}
            {/*begin::Separator*/}
            <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
            {/*end::Separator*/}
            {/*begin::Description*/}
            {/* <small className="text-muted fs-7 fw-bold my-1 ms-1">
              #Description
            </small> */}
            {/*end::Description*/}
          </h1>
          {/*end::Title*/}
        </div>
        {/*begin::Page title*/}
        <div className="flex-grow-1 flex-shrink-0 me-5">
          {/*begin::Page title*/}
          {/*end::Page title*/}
        </div>
        {/*end::Page title*/}

      </div>
      {/*end::Container*/}
    </div>
  );
};

export default ToolBar;


        // {/*begin::Action group*/}
        // <div className="d-flex align-items-center flex-wrap">
        //   {/*begin::Wrapper*/}
        //   <div className="flex-shrink-0 me-2">
        //     <ul className="nav">
        //       <li className="nav-item">
        //         <a
        //           className="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light active fw-bold fs-7 px-4 me-1"
        //           data-bs-toggle="tab"
        //           href
        //         >
        //           Day
        //         </a>
        //       </li>
        //       <li className="nav-item">
        //         <a
        //           className="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-bold fs-7 px-4 me-1"
        //           data-bs-toggle="tab"
        //           href
        //         >
        //           Week
        //         </a>
        //       </li>
        //       <li className="nav-item">
        //         <a
        //           className="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-bold fs-7 px-4"
        //           data-bs-toggle="tab"
        //           href
        //         >
        //           Year
        //         </a>
        //       </li>
        //     </ul>
        //   </div>
        //   {/*end::Wrapper*/}
        //   {/*begin::Wrapper*/}
        //   <div className="d-flex align-items-center">
        //     {/*begin::Daterangepicker*/}
        //     <a
        //       href
        //       className="btn btn-sm btn-bg-light btn-color-gray-500 btn-active-color-primary me-2"
        //       title
        //       data-bs-original-title="Select dashboard daterange"
        //     >
        //       <span className="fw-bold me-1">Today:</span>
        //       <span className="fw-bolder">Jun 17</span>
        //     </a>
        //     {/*end::Daterangepicker*/}
        //     {/*begin::Actions*/}
        //     <div className="d-flex align-items-center">
        //       <button
        //         type="button"
        //         className="btn btn-sm btn-icon btn-color-primary btn-active-light btn-active-color-primary"
        //       >
        //         {/*begin::Svg Icon | path: icons/duotune/files/fil005.svg*/}
        //         <span className="svg-icon svg-icon-2x">
        //           <svg
        //             xmlns="http://www.w3.org/2000/svg"
        //             width={24}
        //             height={24}
        //             viewBox="0 0 24 24"
        //             fill="none"
        //           >
        //             <path
        //               opacity="0.3"
        //               d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13.5L12.5 13V10C12.5 9.4 12.6 9.5 12 9.5C11.4 9.5 11.5 9.4 11.5 10L11 13L8 13.5C7.4 13.5 7 13.4 7 14C7 14.6 7.4 14.5 8 14.5H11V18C11 18.6 11.4 19 12 19C12.6 19 12.5 18.6 12.5 18V14.5L16 14C16.6 14 17 14.6 17 14C17 13.4 16.6 13.5 16 13.5Z"
        //               fill="currentColor"
        //             />
        //             <rect
        //               x={11}
        //               y={19}
        //               width={10}
        //               height={2}
        //               rx={1}
        //               transform="rotate(-90 11 19)"
        //               fill="currentColor"
        //             />
        //             <rect
        //               x={7}
        //               y={13}
        //               width={10}
        //               height={2}
        //               rx={1}
        //               fill="currentColor"
        //             />
        //             <path
        //               d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
        //               fill="currentColor"
        //             />
        //           </svg>
        //         </span>
        //         {/*end::Svg Icon*/}
        //       </button>
        //     </div>
        //     {/*end::Actions*/}
        //   </div>
        //   {/*end::Wrapper*/}
        // </div>
        // {/*end::Action group*/}