import { createSlice } from "@reduxjs/toolkit";

const skuSlice = createSlice({
  name: "sku",
  initialState: {
    skus: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    getSkuStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getSkuSuccess: (state, action) => {
      state.isFetching = false;
      state.skus = action.payload;
    },
    getSkuFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE skuS
    deleteSkuStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteSkuSuccess: (state, action) => {
      state.isFetching = false;
      // state.skus.splice(
      //   // state.skus.data?.findIndex(
      //   state.skus.findIndex(
      //     //   (item) => item.id === action.payload.id
      //     (item) => item.id === action.payload
      //   ),
      //   1
      // );
    },
    deleteSkuFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE skuS
    updateSkuStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateSkuSuccess: (state, action) => {
      state.isFetching = false;
      // state.skus[
      //   state.skus.findIndex((item) => item.id === action.payload.id)
      // ] = action.payload.sku;
    },
    updateSkuFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD skuS
    addSkuStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addSkuSuccess: (state, action) => {
      state.isFetching = false;
      console.log("action.payload", action.payload);
      state.skus.push(action.payload);
    },
    addSkuFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  addSkuFailure,
  addSkuStart,
  addSkuSuccess,
  getSkuFailure,
  getSkuStart,
  getSkuSuccess,
  updateSkuFailure,
  updateSkuStart,
  updateSkuSuccess,
  deleteSkuFailure,
  deleteSkuStart,
  deleteSkuSuccess,
} = skuSlice.actions;
export default skuSlice.reducer;
