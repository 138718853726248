import React from 'react'
import ToolBar from '../../components/ToolBar/ToolBar'

const Info = () => {
    return (
        <>
            {/* begin: Toolbar */}
            <ToolBar />
            {/* end: Toolbar */}
            <div className="content d-flex flex-column flex-column-fluid">
                {/*begin::Container*/}
                <div className="container-xxl">
                    <div className="card card-docs flex-row-fluid mb-2">
                        {/*begin::Card Body*/}
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                            {/*begin::Section*/}
                            <div className="px-md-10 pt-md-10 pb-md-5">
                                {/*begin::Block*/}
                                <div className="mb-20">
                                    <h1 className="fs-2tx fw-bold mb-8">WHAT IS
                                        <span className="d-inline-block position-relative ms-2">
                                            <span className="d-inline-block mb-2">OEE</span>?
                                            <span className="d-inline-block position-absolute h-8px bottom-0 end-0 start-0 bg-success translate rounded" />
                                        </span>
                                    </h1>
                                    <div className="fw-semibold fs-2 text-gray-500 mb-10">
                                        OEE{" "}<span className="fw-bold text-gray-900">(Overall Equipment Effectiveness)</span>{" "}is the gold standard for measuring manufacturing productivity.
                                        Simply put – it identifies the percentage of manufacturing time that is truly productive.
                                        An OEE score of 100% means you are manufacturing only Good Parts, as fast as possible, with no Stop Time.
                                        In the language of OEE that means 100% Quality{" "}
                                        <span className="fw-bold text-gray-900">(only Good Parts)</span>,{" "}
                                        100% Performance{" "}<span className="fw-bold text-gray-900">(as fast as possible)</span>,{" "}
                                        and 100% Availability{" "}<span className="fw-bold text-gray-900">(no Stop Time)</span>.{" "}
                                        The most advanced
                                    </div>
                                    <div className="fw-semibold fs-2 text-gray-500 mb-10">
                                        Measuring OEE is a manufacturing best practice. By measuring OEE and the underlying losses, you will gain important insights on how to systematically improve your manufacturing process. OEE is the single best metric for identifying losses, benchmarking progress, and improving the productivity of manufacturing equipment (i.e., eliminating waste).
                                        This website is devoted to mastering the art and science of OEE. Understand it. Measure it. Improve it.
                                    </div>
                                    <div className="fw-semibold fs-2 text-gray-500 mb-10">
                                        Start here on your path to mastering OEE.
                                    </div>
                                </div>
                                {/*end::Block*/}
                                {/*begin::Row*/}
                                <div className="row g-0">
                                    {/*begin::Col*/}
                                    <div className="col-xl-3 col-lg-6 col-md-6 mb-10">
                                        <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                            <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-danger bg-opacity-90 mb-10">
                                                {/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                                                <span className="svg-icon svg-icon-danger svg-icon-3x">
                                                    <img src="./icons/oee-icon.webp" alt="" width={24} height={24} />
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </div>
                                            {/* <h1 className="mb-5">Overall Equipment Effectiveness</h1> */}
                                            <h1 className="mb-5">OEE</h1>

                                            <div className="fs-4 text-gray-600 py-3">
                                                OEE takes into account all losses. An OEE score of 100% means you are manufacturing only Good Parts, as fast as possible, with no Stop Time.
                                            </div>
                                        </div>
                                    </div>
                                    {/*end::Col*/}
                                    {/*begin::Col*/}
                                    <div className="col-xl-3 col-lg-6 col-md-6 mb-10">
                                        <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                            <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-warning bg-opacity-90 mb-10">
                                                {/*begin::Svg Icon | path: icons/duotune/abstract/abs026.svg*/}
                                                <span className="svg-icon svg-icon-warning svg-icon-3x">
                                                    <img src="./icons/performance-icon.webp" alt="" width={24} height={24} />

                                                </span>
                                                {/*end::Svg Icon*/}
                                            </div>
                                            <h1 className="mb-5">Performance</h1>

                                            <div className="fs-4 text-gray-600 py-3">
                                                Performance takes into account Slow Cycles and Small Stops. A Performance score of 100% means when the process is running it is running as fast as possible.
                                            </div>
                                        </div>
                                    </div>
                                    {/*end::Col*/}
                                    {/*begin::Col*/}
                                    <div className="col-xl-3 col-lg-6 col-md-6 mb-10">
                                        <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                            <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-primary bg-opacity-90 mb-10">
                                                {/*begin::Svg Icon | path: icons/duotune/coding/cod001.svg*/}
                                                <span className="svg-icon svg-icon-primary svg-icon-3x">
                                                    <img src="./icons/availability-icon.webp" alt="" width={24} height={24} />

                                                </span>
                                                {/*end::Svg Icon*/}
                                            </div>
                                            <h1 className="mb-5">Availability</h1>

                                            <div className="fs-4 text-gray-600 py-3">
                                                Availability takes into account Unplanned and Planned Stops. An Availability score of 100% means the process is always running during Planned Production Time.
                                            </div>
                                        </div>
                                    </div>
                                    {/*end::Col*/}
                                    {/*begin::Col*/}
                                    <div className="col-xl-3 col-lg-6 col-md-6 mb-10">
                                        <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                            <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-success bg-opacity-90 mb-10">
                                                {/*begin::Svg Icon | path: icons/duotune/art/art006.svg*/}
                                                <span className="svg-icon svg-icon-success svg-icon-3x">
                                                    <img src="./icons/quality-icon.webp" alt="" width={24} height={24} />
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </div>
                                            <h1 className="mb-5">Quality</h1>

                                            <div className="fs-4 text-gray-600 py-3">
                                                Quality takes into account Defects (including parts that need Rework). A Quality score of 100% means there are no Defects (only Good Parts are being produced).
                                            </div>
                                        </div>
                                    </div>
                                    {/*end::Col*/}

                                </div>
                                {/*end::Row*/}
                            </div>
                            {/*end::Section*/}
                        </div>
                        {/*end::Card Body*/}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Info



                                    // {/*begin::Col*/}
                                    // <div className="col-md-6 mb-10">
                                    //     <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                    //         <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-success bg-opacity-90 mb-10">
                                    //             {/*begin::Svg Icon | path: icons/duotune/coding/cod003.svg*/}
                                    //             <span className="svg-icon svg-icon-success svg-icon-3x">
                                    //                 <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    //                     <path d="M16.95 18.9688C16.75 18.9688 16.55 18.8688 16.35 18.7688C15.85 18.4688 15.75 17.8688 16.05 17.3688L19.65 11.9688L16.05 6.56876C15.75 6.06876 15.85 5.46873 16.35 5.16873C16.85 4.86873 17.45 4.96878 17.75 5.46878L21.75 11.4688C21.95 11.7688 21.95 12.2688 21.75 12.5688L17.75 18.5688C17.55 18.7688 17.25 18.9688 16.95 18.9688ZM7.55001 18.7688C8.05001 18.4688 8.15 17.8688 7.85 17.3688L4.25001 11.9688L7.85 6.56876C8.15 6.06876 8.05001 5.46873 7.55001 5.16873C7.05001 4.86873 6.45 4.96878 6.15 5.46878L2.15 11.4688C1.95 11.7688 1.95 12.2688 2.15 12.5688L6.15 18.5688C6.35 18.8688 6.65 18.9688 6.95 18.9688C7.15 18.9688 7.35001 18.8688 7.55001 18.7688Z" fill="currentColor" />
                                    //                     <path opacity="0.3" d="M10.45 18.9687C10.35 18.9687 10.25 18.9687 10.25 18.9687C9.75 18.8687 9.35 18.2688 9.55 17.7688L12.55 5.76878C12.65 5.26878 13.25 4.8687 13.75 5.0687C14.25 5.1687 14.65 5.76878 14.45 6.26878L11.45 18.2688C11.35 18.6688 10.85 18.9687 10.45 18.9687Z" fill="currentColor" />
                                    //                 </svg>
                                    //             </span>
                                    //             {/*end::Svg Icon*/}
                                    //         </div>
                                    //         <h1 className="mb-5">3rd Party Plugins</h1>
                                    //         <div className="fs-4 text-gray-600 py-3">All popular 3rd-party plugins are deeply customized in order to perfectly meet our design system requirements and blend with your unique in-house components.</div>
                                    //         <a href="javascript:void" className="btn btn-lg btn-flex btn-link btn-color-success">Learn more
                                    //             {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                    //             <span className="svg-icon ms-2 svg-icon-3">
                                    //                 <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    //                     <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                    //                     <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
                                    //                 </svg>
                                    //             </span>
                                    //             {/*end::Svg Icon*/}
                                    //         </a>
                                    //     </div>
                                    // </div>
                                    // {/*end::Col*/}
                                    // {/*begin::Col*/}
                                    // <div className="col-md-6 mb-10">
                                    //     <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                                    //         <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-primary bg-opacity-90 mb-10">
                                    //             {/*begin::Svg Icon | path: icons/duotune/abstract/abs038.svg*/}
                                    //             <span className="svg-icon svg-icon-primary svg-icon-3x">
                                    //                 <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    //                     <path d="M12.0444 17.9444V12.1444L17.0444 15.0444C18.6444 15.9444 19.1445 18.0444 18.2445 19.6444C17.3445 21.2444 15.2445 21.7444 13.6445 20.8444C12.6445 20.2444 12.0444 19.1444 12.0444 17.9444ZM7.04445 15.0444L12.0444 12.1444L7.04445 9.24445C5.44445 8.34445 3.44444 8.84445 2.44444 10.4444C1.54444 12.0444 2.04445 14.0444 3.64445 15.0444C4.74445 15.6444 6.04445 15.6444 7.04445 15.0444ZM12.0444 6.34444V12.1444L17.0444 9.24445C18.6444 8.34445 19.1445 6.24444 18.2445 4.64444C17.3445 3.04444 15.2445 2.54445 13.6445 3.44445C12.6445 4.04445 12.0444 5.14444 12.0444 6.34444Z" fill="currentColor" />
                                    //                     <path opacity="0.3" d="M7.04443 9.24445C6.04443 8.64445 5.34442 7.54444 5.34442 6.34444C5.34442 4.54444 6.84444 3.04443 8.64444 3.04443C10.4444 3.04443 11.9444 4.54444 11.9444 6.34444V12.1444L7.04443 9.24445ZM17.0444 15.0444C18.0444 15.6444 19.3444 15.6444 20.3444 15.0444C21.9444 14.1444 22.4444 12.0444 21.5444 10.4444C20.6444 8.84444 18.5444 8.34445 16.9444 9.24445L11.9444 12.1444L17.0444 15.0444ZM7.04443 15.0444C6.04443 15.6444 5.34442 16.7444 5.34442 17.9444C5.34442 19.7444 6.84444 21.2444 8.64444 21.2444C10.4444 21.2444 11.9444 19.7444 11.9444 17.9444V12.1444L7.04443 15.0444Z" fill="currentColor" />
                                    //                 </svg>
                                    //             </span>
                                    //             {/*end::Svg Icon*/}
                                    //         </div>
                                    //         <h1 className="mb-5">SVG &amp; Font Icons</h1>
                                    //         <div className="fs-4 text-gray-600 py-3">Fulfill your icon needs with over 5000 available icons from in-house designed Duotune and open source Bootstrap Icons, Font Awesome, and Line Awesome sets.</div>
                                    //         <a href="javascript:void" className="btn btn-lg btn-flex btn-link btn-color-primary">Browse icons
                                    //             {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                    //             <span className="svg-icon ms-2 svg-icon-3">
                                    //                 <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    //                     <rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="currentColor" />
                                    //                     <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
                                    //                 </svg>
                                    //             </span>
                                    //             {/*end::Svg Icon*/}
                                    //         </a>
                                    //     </div>
                                    // </div>
                                    // {/*end::Col*/}