import { userRequest } from "../../api/requestMethods";
import {
  addOrderFailure,
  addOrderStart,
  addOrderSuccess,
  getOrderFailure,
  getOrderStart,
  getOrderSuccess,
  updateOrderFailure,
  updateOrderStart,
  updateOrderSuccess,
  deleteOrderFailure,
  deleteOrderStart,
  deleteOrderSuccess,
} from "../reducers/orderReducer";

export const createOrder = async (dispatch, userData) => {
  dispatch(addOrderStart());
  try {
    // console.log("userData from createOrder", userData);
    const res = await userRequest.post("order/createOrder", userData);
    console.log("response order api", res);
    dispatch(addOrderSuccess(res.data));
    return res;
  } catch (error) {
    console.log("error from catch block of  add Sku", error);
    dispatch(addOrderFailure());
  }
};

export const getOrder = async (dispatch) => {
  dispatch(getOrderStart());
  try {
    const res = await userRequest.get("order/getOrders");
    // console.log("response from get team function in getOrder ", res);
    // localStorage.getItem("token");
    dispatch(getOrderSuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(getOrderFailure());
  }
};

export const deleteOrder = async (id, dispatch) => {
  dispatch(deleteOrderStart());
  try {
    // console.log(id);
    const res = await userRequest.post("order/deleteOrder", { id });
    // console.log("response from delete order function in order", res);
    // localStorage.getItem("token");
    dispatch(deleteOrderSuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteOrderFailure());
  }
};
export const updateOrder = async (userData, dispatch) => {
  dispatch(updateOrderStart());
  try {
    const res = await userRequest.post("order/updateOrder", userData);
    // console.log("response from update order function in orderAPi", res);
    // localStorage.getItem("token");
    dispatch(updateOrderSuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateOrderFailure());
  }
};
