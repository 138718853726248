import React, { useEffect, useState } from "react";
import ToolBar from "../../components/ToolBar/ToolBar";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createComplain,
  getComplains,
} from "../../store/redux-apis/complainApiCalls";

const SUCCESS_TICKETS = (
  <span className="svg-icon svg-icon-2x me-5 ms-n1 mt-2 svg-icon-success">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.3"
        d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM11.7 17.7L16 14C16.4 13.6 16.4 12.9 16 12.5C15.6 12.1 15.4 12.6 15 13L11 16L9 15C8.6 14.6 8.4 14.1 8 14.5C7.6 14.9 8.1 15.6 8.5 16L10.3 17.7C10.5 17.9 10.8 18 11 18C11.2 18 11.5 17.9 11.7 17.7Z"
        fill="currentColor"
      />
      <path
        d="M10.4343 15.4343L9.25 14.25C8.83579 13.8358 8.16421 13.8358 7.75 14.25C7.33579 14.6642 7.33579 15.3358 7.75 15.75L10.2929 18.2929C10.6834 18.6834 11.3166 18.6834 11.7071 18.2929L16.25 13.75C16.6642 13.3358 16.6642 12.6642 16.25 12.25C15.8358 11.8358 15.1642 11.8358 14.75 12.25L11.5657 15.4343C11.2533 15.7467 10.7467 15.7467 10.4343 15.4343Z"
        fill="currentColor"
      />
      <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
    </svg>
  </span>
);

const WARNING_TICKETS = (
  <span className="svg-icon svg-icon-2x me-5 ms-n1 mt-2 svg-icon-warning">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.3"
        d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13.5L12.5 13V10C12.5 9.4 12.6 9.5 12 9.5C11.4 9.5 11.5 9.4 11.5 10L11 13L8 13.5C7.4 13.5 7 13.4 7 14C7 14.6 7.4 14.5 8 14.5H11V18C11 18.6 11.4 19 12 19C12.6 19 12.5 18.6 12.5 18V14.5L16 14C16.6 14 17 14.6 17 14C17 13.4 16.6 13.5 16 13.5Z"
        fill="currentColor"
      />
      <rect
        x={11}
        y={19}
        width={10}
        height={2}
        rx={1}
        transform="rotate(-90 11 19)"
        fill="currentColor"
      />
      <rect x={7} y={13} width={10} height={2} rx={1} fill="currentColor" />
      <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
    </svg>
  </span>
);

const Complains = () => {
  const allTickets = useSelector((state) => state.complain.complains?.data);
  // const ticketsDataForMT = allTickets?.map((cat) => ({ ...cat }));
  const lastTicket = allTickets?.at(-1);
  // console.log("lastTicket >>>", lastTicket);

  //states
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [userData, setUserData] = useState({
    ticketNo: "",
    userId: "",
    complainDetail: "",
    isResolved: false,
    timestamp: new Date(),
    status: "",
    title: "",
  });
  const [userId, setUserId] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    getComplains(dispatch);
  }, [dispatch, refresh]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ticketNo: lastTicket.id,
      userId: userId,
      complainDetail: userData.complainDetail,
      isResolved: userData.isResolved,
      timestamp: userData.timestamp,
      status: userData.status,
      title: userData.title,
    };
    console.log("data >>>>", data);
    if (
      data &&
      data.ticketNo &&
      data.userId &&
      data.complainDetail &&
      data.timestamp &&
      data.status &&
      data.title
    ) {
      console.log("condition passed >>>");
      createComplain(dispatch, data);
      setRefresh((preValue) => !preValue);
      handleClose();
    }
  };
  return (
    <>
      {/* begin: Toolbar */}
      <ToolBar />
      {/* end: Toolbar */}
      {/*begin::Content*/}
      <div className="content d-flex flex-column flex-column-fluid">
        {/*begin::Container*/}
        <div className="container-xxl">
          {/*begin::Hero card*/}
          <div className="card mb-12">
            {/*begin::Hero body*/}
            <div className="card-body flex-column p-5">
              {/*begin::Hero nav*/}
              <div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5">
                {/*begin::Nav*/}
                <ul className="nav flex-wrap border-transparent fw-bolder">
                  {/*begin::Nav item*/}
                  <li className="nav-item my-1">
                    <a
                      className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase active"
                      href="javascript:void(0)"
                    >
                      tickets
                    </a>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className="nav-item my-1">
                    <a
                      className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                      href="javascript:void(0)"
                    >
                      Update tickets
                    </a>
                  </li>
                  {/*end::Nav item*/}
                </ul>
                {/*end::Nav*/}
                {/*begin::Action*/}
                <Button
                  //   variant="primary"
                  className="btn btn-primary fw-bolder fs-8 fs-lg-base"
                  onClick={handleShow}
                >
                  Create Ticket
                </Button>
                {/*end::Action*/}
                {/*begin::Modal - Add task*/}
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  {/*begin::Modal body*/}
                  <Modal.Body className="scroll-y px-10 px-lg-15 pt-0 pb-15">
                    {/*begin:Form*/}
                    <form className="form" onSubmit={handleSubmit}>
                      {/*begin::Heading*/}
                      <div className="mb-13 text-center">
                        {/*begin::Title*/}
                        <h1 className="mb-3">Create Ticket</h1>
                        {/*end::Title*/}
                      </div>
                      {/*end::Heading*/}
                      {/*begin::Input group*/}
                      <div className="d-flex flex-column mb-8 fv-row">
                        {/*begin::Label*/}
                        <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                          <span className="required">Ticket No:</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title="Specify a subject for your issue"
                          />
                        </label>
                        {/*end::Label*/}
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          disabled
                          value={lastTicket && `#${lastTicket?.id}`}
                        />
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="d-flex flex-column mb-8 fv-row">
                        {/*begin::Label*/}
                        <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                          <span className="required">Ticket title:</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title="Specify a subject for your issue"
                          />
                        </label>
                        {/*end::Label*/}
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder="Enter your ticket subject"
                          name="subject"
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              title: e.target.value,
                            })
                          }
                        />
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="row g-9 mb-8">
                        {/*begin::Col*/}
                        <div className="col-md-12 fv-row">
                          <label className="required fs-6 fw-bold mb-2">
                            Status
                          </label>
                          <select
                            className="form-select form-select-solid"
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                status: e.target.value,
                              })
                            }
                          >
                            <option value={"open"} selected="selected">
                              Open
                            </option>
                            <option value={"pending"}>Pending</option>
                            <option value={"resolved"}>Resolved</option>
                            <option value={"closed"}>Closed</option>
                          </select>
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="d-flex flex-column mb-8 fv-row">
                        <label className="fs-6 fw-bold mb-2">Description</label>
                        <textarea
                          className="form-control form-control-solid"
                          rows={4}
                          name="description"
                          placeholder="Type your ticket description"
                          defaultValue={""}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              complainDetail: e.target.value,
                            })
                          }
                        />
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Actions*/}
                      <div className="text-center">
                        <button
                          type="reset"
                          className="btn btn-light me-3"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary">
                          <span className="indicator-label">Submit</span>
                          {/* <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span> */}
                        </button>
                      </div>
                      {/*end::Actions*/}
                    </form>
                    {/*end:Form*/}
                  </Modal.Body>
                  {/*end::Modal body*/}
                </Modal>
                {/*end::Modal - Add task*/}
              </div>
              {/*end::Hero nav*/}
            </div>
            {/*end::Hero body*/}
          </div>
          {/*end::Hero card*/}
          {/*begin::Card*/}
          <div className="card">
            {/*begin::Card body*/}
            <div className="card-body">
              {/*begin::Layout*/}
              <div className="d-flex flex-column flex-xl-row p-7">
                {/*begin::Content*/}
                <div className="flex-lg-row-fluid me-xl-15 mb-20 mb-xl-0">
                  {/*begin::Tickets*/}
                  <div className="mb-0">
                    {/*begin::Search form*/}
                    <form className="form mb-15">
                      {/*begin::Input wrapper*/}
                      <div className="position-relative">
                        {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                        <span className="svg-icon svg-icon-1 svg-icon-primary position-absolute top-50 translate-middle ms-9">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height={2}
                              rx={1}
                              transform="rotate(45 17.0365 15.1223)"
                              fill="currentColor"
                            />
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid ps-14"
                          name="search"
                          defaultValue
                          placeholder="Search"
                        />
                      </div>
                      {/*end::Input wrapper*/}
                    </form>
                    {/*end::Search form*/}
                    {/*begin::Heading*/}
                    <h1 className="text-dark mb-10">Public Tickets</h1>
                    {/*end::Heading*/}
                    {/*begin::Tickets List*/}
                    <div className="mb-10">
                      {/*begin::Ticket*/}
                      {allTickets?.map((item) => {
                        return (
                          <div className="d-flex mb-10">
                            {/*begin::Symbol*/}
                            {/* {/*begin::Svg Icon |} */}
                            {/* {item.isResolved ? SUCCESS_TICKETS : WARNING_TICKETS} */}
                            {/*end::Svg Icon*/}
                            {/*end::Symbol*/}
                            {/* Ticket No begin:  */}
                            <span className="svg-icon svg-icon-2x me-5 ms-n1 mt-2 fs-2x">
                              #{item.id}
                            </span>
                            {/* Ticket No end:  */}
                            {/*begin::Section*/}
                            <div className="d-flex flex-column">
                              {/*begin::Content*/}
                              <div className="d-flex align-items-center mb-2">
                                {/*begin::Title*/}
                                <a
                                  href="javascript:void(0)"
                                  className="text-dark text-hover-primary fs-4 me-3 fw-bold"
                                >
                                  {item.title}
                                </a>
                                {/*end::Title*/}
                                {/*begin::Label*/}
                                <span
                                  className={`badge badge-light my-1 
                                  ${item.status === "open" ? "bg-light-danger text-danger" : ""}
                                  ${item.status === "pending" ? "bg-light-info text-info" : ""}
                                  ${item.status === "closed" ? "bg-light-success text-success" : ""}
                                  ${item.status === "resolved" ? "bg-light-warning text-warning" : ""}
                                  `}
                                >
                                  {item.status}
                                </span>
                                {/*end::Label*/}
                              </div>
                              {/*end::Content*/}
                              {/*begin::Text*/}
                              <span className="text-muted fw-bold fs-6">
                                {item.complainDetail}
                              </span>
                              {/*end::Text*/}
                            </div>
                            {/*end::Section*/}
                          </div>
                        );
                      })}
                      {/*end::Ticket*/}
                      {/*begin::Ticket*/}

                      <div className="d-flex mb-10">
                        {/*begin::Symbol*/}
                        {/*begin::Svg Icon | path: icons/duotune/files/fil008.svg*/}
                        <span className="svg-icon svg-icon-2x me-5 ms-n1 mt-2 svg-icon-success">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM11.7 17.7L16 14C16.4 13.6 16.4 12.9 16 12.5C15.6 12.1 15.4 12.6 15 13L11 16L9 15C8.6 14.6 8.4 14.1 8 14.5C7.6 14.9 8.1 15.6 8.5 16L10.3 17.7C10.5 17.9 10.8 18 11 18C11.2 18 11.5 17.9 11.7 17.7Z"
                              fill="currentColor"
                            />
                            <path
                              d="M10.4343 15.4343L9.25 14.25C8.83579 13.8358 8.16421 13.8358 7.75 14.25C7.33579 14.6642 7.33579 15.3358 7.75 15.75L10.2929 18.2929C10.6834 18.6834 11.3166 18.6834 11.7071 18.2929L16.25 13.75C16.6642 13.3358 16.6642 12.6642 16.25 12.25C15.8358 11.8358 15.1642 11.8358 14.75 12.25L11.5657 15.4343C11.2533 15.7467 10.7467 15.7467 10.4343 15.4343Z"
                              fill="currentColor"
                            />
                            <path
                              d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                        {/*end::Symbol*/}
                        {/*begin::Section*/}
                        <div className="d-flex flex-column">
                          {/*begin::Content*/}
                          <div className="d-flex align-items-center mb-2">
                            {/*begin::Title*/}
                            <a
                              href="javascript:void(0)"
                              className="text-dark text-hover-primary fs-4 me-3 fw-bold"
                            >
                              How to use OEE?
                            </a>
                            {/*end::Title*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light my-1">
                              React
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Content*/}
                          {/*begin::Text*/}
                          <span className="text-muted fw-bold fs-6">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Excepturi nisi dignissimos quod in, incidunt
                            dolorum asperiores! Sequi, eos.
                            <br />
                            Pariatur quam enim minima consectetur ducimus animi
                            ullam facere ab aut dolor.
                          </span>
                          {/*end::Text*/}
                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Ticket*/}
                    </div>
                    {/*end::Tickets List*/}
                    {/*begin::Pagination*/}
                    <ul className="pagination">
                      <li className="page-item previous disabled">
                        <a href="javascript:void(0)" className="page-link">
                          <i className="previous" />
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="javascript:void(0)" className="page-link">
                          1
                        </a>
                      </li>
                      <li className="page-item active">
                        <a href="javascript:void(0)" className="page-link">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="javascript:void(0)" className="page-link">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="javascript:void(0)" className="page-link">
                          4
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="javascript:void(0)" className="page-link">
                          5
                        </a>
                      </li>
                      <li className="page-item">
                        <a href="javascript:void(0)" className="page-link">
                          6
                        </a>
                      </li>
                      <li className="page-item next">
                        <a href="javascript:void(0)" className="page-link">
                          <i className="next" />
                        </a>
                      </li>
                    </ul>
                    {/*end::Pagination*/}
                  </div>
                  {/*end::Tickets*/}
                </div>
                {/*end::Content*/}
                {/*begin::Sidebar*/}
                <div className="flex-column flex-lg-row-auto w-100 mw-lg-300px mw-xxl-350px">
                  {/*begin::More channels*/}
                  <div className="card-rounded bg-primary bg-opacity-5 p-10 mb-15">
                    {/*begin::Title*/}
                    <h2 className="text-dark fw-bolder mb-11">More Channels</h2>
                    {/*end::Title*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center mb-10">
                      {/*begin::Icon*/}
                      <i className="bi bi-file-earmark-text text-primary fs-1 me-5" />
                      {/*end::SymIconbol*/}
                      {/*begin::Info*/}
                      <div className="d-flex flex-column">
                        <h5 className="text-gray-800 fw-bolder">
                          Project Briefing
                        </h5>
                        {/*begin::Section*/}
                        <div className="fw-bold">
                          {/*begin::Desc*/}
                          <span className="text-muted">Check out our </span>
                          {/*end::Desc*/}
                          {/*begin::Link*/}
                          <a href="javascript:void(0)" className="link-primary">
                            Support Policy
                          </a>
                          {/*end::Link*/}
                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center mb-10">
                      {/*begin::Icon*/}
                      <i className="bi bi-chat-square-text-fill text-primary fs-1 me-5" />
                      {/*end::SymIconbol*/}
                      {/*begin::Info*/}
                      <div className="d-flex flex-column">
                        <h5 className="text-gray-800 fw-bolder">
                          More to discuss?
                        </h5>
                        {/*begin::Section*/}
                        <div className="fw-bold">
                          {/*begin::Desc*/}
                          <span className="text-muted">Email us to </span>
                          {/*end::Desc*/}
                          {/*begin::Link*/}
                          <a href="javascript:void(0)" className="link-primary">
                            support@pakistanautomation.com
                          </a>
                          {/*end::Link*/}
                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center mb-10">
                      {/*begin::Icon*/}
                      <i className="bi bi-twitter text-primary fs-1 me-5" />
                      {/*end::SymIconbol*/}
                      {/*begin::Info*/}
                      <div className="d-flex flex-column">
                        <h5 className="text-gray-800 fw-bolder">Latest News</h5>
                        {/*begin::Section*/}
                        <div className="fw-bold">
                          {/*begin::Desc*/}
                          <span className="text-muted">Follow us at </span>
                          {/*end::Desc*/}
                          {/*begin::Link*/}
                          <a href="javascript:void(0)" className="link-primary">
                            Pakistan Automation Facebook
                          </a>
                          {/*end::Link*/}
                        </div>
                        {/*end::Section*/}
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                  {/*end::More channels*/}
                  {/*begin::Documentations*/}
                  <div className="card-rounded bg-primary bg-opacity-5 p-10 mb-15">
                    {/*begin::Title*/}
                    <h1 className="fw-bolder text-dark mb-9">Documentation</h1>
                    {/*end::Title*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center mb-6">
                      {/*begin::Icon*/}
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr071.svg*/}
                      <span className="svg-icon svg-icon-2 ms-n1 me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                      {/*end::Icon*/}
                      {/*begin::Subtitle*/}
                      <a
                        href="javascript:void(0)"
                        className="fw-bold text-gray-800 text-hover-primary fs-5 m-0"
                      >
                        Admin Panel
                      </a>
                      {/*end::Subtitle*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                  {/*end::Documentations*/}
                </div>
                {/*end::Sidebar*/}
              </div>
              {/*end::Layout*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Content*/}
    </>
  );
};

export default Complains;
