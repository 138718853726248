import { createSlice } from "@reduxjs/toolkit";

const distributerSlice = createSlice({
  name: "distributer",
  initialState: {
    distributers: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET Distributer
    getDistributerStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getDistributerSuccess: (state, action) => {
      state.isFetching = false;
      state.distributers = action.payload;
    },
    getDistributerFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE Distributer
    deleteDistributerStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteDistributerSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteDistributerFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE Distributer
    updateDistributerStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateDistributerSuccess: (state, action) => {
      state.isFetching = false;
      console.log("action.payload >>>",action.payload);
    },
    updateDistributerFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD Distributer
    addDistributerStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addDistributerSuccess: (state, action) => {
      state.isFetching = false;
      state.distributers.push(action.payload);
    },
    addDistributerFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getDistributerStart,
  getDistributerSuccess,
  getDistributerFailure,
  deleteDistributerStart,
  deleteDistributerSuccess,
  deleteDistributerFailure,
  updateDistributerStart,
  updateDistributerSuccess,
  updateDistributerFailure,
  addDistributerStart,
  addDistributerSuccess,
  addDistributerFailure,
} = distributerSlice.actions;
export default distributerSlice.reducer;
