import React from 'react';
import { Column } from '@ant-design/plots';

const TopFrequentStops = ({ data }) => {
    // const TopFrequentStops = () => {
    // const data = [
    //     {
    //         type: '家具家电',
    //         value: 38,
    //     },
    //     {
    //         type: '粮油副食',
    //         value: 52,
    //     },
    //     {
    //         type: '生鲜水果',
    //         value: 61,
    //     },
    //     {
    //         type: '美容洗护',
    //         value: 145,
    //     },
    // ];
    const config = {
        data,
        autoFit: true,
        xField: 'type',
        yField: 'value',
        label: {
            // label 
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // Style
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
                // fontSize: '10px',
            },
        },
        xAxis: {
            // label: {
            //     // autoHide: true,
            //     autoHide: false,
            //     autoRotate: true,

            // },
            // style: {
            //     fontSize: '10px',
            // },
            label: false,
        },
        meta: {
            type: {
                alias: 'Type',
            },
            value: {
                alias: 'Count',
            },
        },
        // label: {
        //     type: 'inner',
        //     offset: '-8%',
        //     content: '{name}',
        //     style: {
        //         fontSize: 10,
        //     },
        // },
    };
    return <Column {...config} />;
};

export default TopFrequentStops;