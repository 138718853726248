import { userRequest } from "../../api/requestMethods";
import {
  addComplainFailure,
  addComplainStart,
  addComplainSuccess,
  deleteComplainFailure,
  deleteComplainStart,
  deleteComplainSuccess,
  getComplainFailure,
  getComplainStart,
  getComplainSuccess,
  updateComplainFailure,
  updateComplainStart,
  updateComplainSuccess,
} from "../reducers/complainReducer";

export const getComplains = async (dispatch) => {
  console.log("call >>");
  dispatch(getComplainStart());
  try {
    const res = await userRequest.get("complain/getComplains");
    console.log("response from get complain ==>", res);
    dispatch(getComplainSuccess(res.data));
  } catch (error) {
    console.log("error from complainFailure >>>", error);
    dispatch(getComplainFailure());
  }
};

export const createComplain = async (dispatch, inputData) => {
  dispatch(addComplainStart());
  try {
    const res = await userRequest.post("/complain/createComplain", inputData);
    console.log("response from add Complain ==>", res);
    dispatch(addComplainSuccess(res.data));
  } catch (error) {
    console.log("error from ComplainFailure >>>", error);
    dispatch(addComplainFailure());
  }
};

export const updateComplain = async (inputData, dispatch) => {
  dispatch(updateComplainStart());
  try {
    const res = await userRequest.post("/complain/updateComplains", inputData);
    console.log("response from update complain ==>", res);
    dispatch(updateComplainSuccess(res.data));
  } catch (error) {
    console.log("error from complainFailure >>>", error);
    dispatch(updateComplainFailure());
  }
};

export const deleteComplain = async (id, dispatch) => {
  dispatch(deleteComplainStart());
  try {
    const res = await userRequest.post("/complain/deleteComplain", { id });
    console.log("response from delete complain ==>", res);
    dispatch(deleteComplainSuccess(res.data));
  } catch (error) {
    console.log("error from complainFailure >>>", error);
    dispatch(deleteComplainFailure());
  }
};
