import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import MaterialTable from "material-table";
import ToolBar from "../../components/ToolBar/ToolBar";
import { useDispatch, useSelector } from "react-redux";
import { createDistributer, deleteDistributer, getDistributer, updateDistributer } from "../../store/redux-apis/distributerApiCalls";

const Distributer = () => {

  const allDistributer = useSelector((state) => state.distributer.distributers?.data);
  console.log("allDistributer >>>", allDistributer);
  const distributerDataForMT = allDistributer?.map((d) => ({ ...d }));
  //states
  const [refresh, setRefresh] = useState(1);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({
    distributerName: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  useEffect(() => {
    getDistributer(dispatch);
  }, [dispatch, refresh]);


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userData);
    if (
      userData &&
      userData.distributerName
    ) {
      console.log("condition passed >>>");
      createDistributer(dispatch, userData);
      setRefresh((preValue) => !preValue);
      getDistributer(dispatch);
      handleClose();
      setUserData({ distributerName: "", });
    }
    handleClose();
  };



  const tableColumns = [{ title: "DistributerName", field: "distributerName" }];
  // const tableRows = [
  //   { distributerName: "Distributer" },
  //   { distributerName: "Distributer" },
  //   { distributerName: "Distributer" },
  //   { distributerName: "Distributer" },
  //   { distributerName: "Distributer" },
  // ];
  return (
    <>
      {/* begin: Toolbar */}
      <ToolBar />
      {/* end: Toolbar */}
      <div className="content d-flex flex-column flex-column-fluid">
        {/*begin::Container*/}
        <div className="container-xxl">
          {/*begin::Row*/}
          <div className="card">
            <div className="card-header border-0 pt-6 d-flex justify-content-between">
              {/*begin::Card title*/}
              <div className="card-title">
                {/*begin::Search*/}
                <div className="d-flex align-items-center position-relative my-1">
                  {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}

                </div>
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Toolbar*/}
                <div className="d-flex justify-content-end">
                  {/*begin::Add Distributer*/}
                  <Button
                    variant="primary"
                    className="btn btn-primary"
                    onClick={handleShow}
                  >
                    Add Distributer
                  </Button>

                  {/*end::Add Distributer*/}
                </div>
                {/*end::Toolbar*/}
                {/*begin::Modal - Add task*/}
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  {/*begin::Modal body*/}
                  <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
                    {/*begin::Form*/}
                    <form
                      className="form fv-plugins-bootstrap5 fv-plugins-framework"
                      onSubmit={handleSubmit}
                    >
                      {/*begin::Scroll*/}
                      <div
                        className="d-flex flex-column scroll-y me-n7 pe-7"
                        style={{ maxHeight: "422px" }}
                      >
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Distributer Name
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            name="DistributerName"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="Distributer name"
                            value={userData.distributerName}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                distributerName: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Scroll*/}
                      {/*begin::Actions*/}
                      <div className="text-center pt-15">
                        <button
                          type="reset"
                          className="btn btn-light me-3"
                          onClick={handleClose}
                        >
                          Discard
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                        // onClick={handleClose}
                        >
                          <span className="indicator-label">Submit</span>
                          {/* <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span> */}
                        </button>
                      </div>
                      {/*end::Actions*/}
                      <div />
                    </form>
                    {/*end::Form*/}
                  </Modal.Body>
                  {/*end::Modal body*/}
                </Modal>
                {/*end::Modal - Add task*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            <div className="material-table__box">
              <MaterialTable
                style={{
                  zIndex: 0
                }}
                // title="Distributer Details"
                columns={tableColumns}
                data={distributerDataForMT}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...distributerDataForMT];
                        const index = oldData.id;
                        dataUpdate[index] = newData;
                        updateDistributer(dataUpdate[index], dispatch);
                        setRefresh((preValue) => !preValue);
                        resolve();
                      }, 1000);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const index = oldData.id;
                        console.log("index >>>", index);
                        deleteDistributer(index, dispatch);
                        setRefresh((preValue) => !preValue);
                        resolve();
                      }, 1000);
                    }),
                }}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportCsv: (columns, data) => {
                    alert(
                      "You should develop a code to export " +
                      data.length +
                      " rows"
                    );
                  },
                  showTitle: false,
                  filtering: true,
                  search: false,
                  toolbar: false,

                  pageSizeOptions: [
                    5,
                    10,
                    20,
                    { value: distributerDataForMT?.length, label: "All" },
                  ],
                  // pageSizeOptions: [5, 10, 20],
                  headerStyle: {
                    color: "#A1A5B7 ",
                    borderBottom: "#E4E6EF",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dashed",
                    fontWeight: "600",
                    fontSize: "16px",
                  },
                }}
              />
            </div>
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Container*/}
      </div>
    </>
  );
};

export default Distributer;
