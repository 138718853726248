import React from "react";

const Footer = () => {
  return (
    <div className="footer py-4 d-flex flex-lg-column">
      {/*begin::Container*/}
      <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        {/*begin::Copyright*/}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-1">
            {new Date().getFullYear()}©
          </span>
          <a
            href="https://www.pakistanautomation.com.pk"
            rel="noreferrer"
            target="_blank"
            className="text-gray-800 text-hover-primary"
          >
            Pakistan Automation
          </a>
        </div>
        {/*end::Copyright*/}
        {/*begin::Menu*/}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <a
              href="https://www.pakistanautomation.com.pk"
              rel="noreferrer"
              target="_blank"
              className="menu-link px-2"
            >
              About
            </a>
          </li>
          <li className="menu-item">
            <a
              href="https://www.pakistanautomation.com.pk"
              rel="noreferrer"
              target="_blank"
              className="menu-link px-2"
            >
              Support
            </a>
          </li>
          <li className="menu-item">
            <a
              href="https://www.pakistanautomation.com.pk"
              rel="noreferrer"
              target="_blank"
              className="menu-link px-2"
            >
              Purchase
            </a>
          </li>
        </ul>
        {/*end::Menu*/}
      </div>
      {/*end::Container*/}
    </div>
  );
};

export default Footer;
