import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import MaterialTable from "material-table";
import ToolBar from "../../components/ToolBar/ToolBar";
import { useEffect } from "react";
import "./Shift.css";
import { createShift, deleteShift, getShifts, updateShift } from "../../store/redux-apis/shiftApiCalls";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// DateTimePickerTabs
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const Shift = () => {
  const shiftsData = useSelector((state) => state.shift.shifts.data);
  const shiftsDataForMT = shiftsData?.map((d) => ({ ...d }));
  console.log("shiftsDataForMT >>>", shiftsDataForMT);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [userData, setUserData] = useState({
    shiftName: "",
    startTime: "",
    endTime: "",
    shiftPattern: "",
  });
  // For Date Pikcer
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())

  // For Filter date functions
  const handleChangeOne = (newValue) => setStartDate(newValue);
  const handleChangeTwo = async (newValue) => {
    console.log("new Value >>>", newValue);
    console.log("star date >>>", startDate);
    setEndDate(newValue)
  };

  const [refresh, setRefresh] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    getShifts(dispatch);
  }, [dispatch, refresh]);


  const handleSubmit = (e) => {
    e.preventDefault();
    let userObj = {
      shiftName: userData.shiftName,
      startTime: startDate,
      endTime: endDate,
      shiftPattern: userData.shiftPattern,
    }
    console.log("userObj >>>", userObj);
    if (
      userObj &&
      userObj.shiftName &&
      userObj.startTime &&
      userObj.endTime &&
      userObj.shiftPattern
    ) {
      console.log("condition passed >>>");
      createShift(dispatch, userObj);
      setRefresh((preValue) => !preValue);
      getShifts(dispatch);
      handleClose();
      setUserData({
        shiftName: "",
        startTime: "",
        endTime: "",
        shiftPattern: "",
      });
    }
    handleClose();
  };


  const tableColumns = [
    { title: "Shift Name", field: "shiftName", lookup: { A: "A", B: "B", C: "C" } },
    { title: "Start Time", field: "startTime" },
    { title: "End Time", field: "endTime" },
  ];

  return (
    <>
      {/* begin: Toolbar */}
      <ToolBar />
      {/* end: Toolbar */}
      <div className="content d-flex flex-column flex-column-fluid">
        {/*begin::Container*/}
        <div className="container-xxl">
          {/*begin::Row*/}
          <div className="card">
            <div className="card-header border-0 pt-6 d-flex justify-content-between">
              {/*begin::Card title*/}
              <div className="card-title">
                {/*begin::Search*/}
                <div className="d-flex align-items-center position-relative my-1">
                  {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}

                </div>
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Toolbar*/}
                <div className="d-flex justify-content-end">
                  {/*begin::Add Shift*/}
                  <Button
                    variant="primary"
                    className="btn btn-primary"
                    onClick={handleShow}
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="11.364"
                          y="20.364"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11.364 20.364)"
                          fill="currentColor"
                        />
                        <rect
                          x="4.36396"
                          y="11.364"
                          width={16}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}Add Shift
                  </Button>

                  {/*end::Add Shift*/}
                </div>
                {/*end::Toolbar*/}
                {/*begin::Modal - Add task*/}
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  {/*begin::Modal body*/}
                  <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
                    {/*begin::Form*/}
                    <form
                      className="form fv-plugins-bootstrap5 fv-plugins-framework"
                      onSubmit={handleSubmit}
                    >
                      {/*begin::Scroll*/}
                      <div
                        className="d-flex flex-column scroll-y me-n7 pe-7"
                        style={{ maxHeight: "422px" }}
                      >
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Shift Name
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            name="shiftName"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="Shift name"
                            value={userData.shiftName}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                shiftName: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Start Time
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                <TimePicker
                                  label="Time"
                                  // value={userData.startTime}
                                  // onChange={(e) =>
                                  //   setUserData({
                                  //     ...userData,
                                  //     startTime: e.target.value,
                                  //   })
                                  // }
                                  value={startDate}
                                  onChange={handleChangeOne}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            End Time
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                <TimePicker
                                  label="Time"
                                  // value={userData.endTime}
                                  // onChange={(e) =>
                                  //   setUserData({
                                  //     ...userData,
                                  //     endTime: e.target.value,
                                  //   })
                                  // }
                                  value={endDate}
                                  onChange={handleChangeTwo}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>

                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Shift Pattern
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            name="shiftPattern"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            value={userData.shiftPattern}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                shiftPattern: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Scroll*/}
                      {/*begin::Actions*/}
                      <div className="text-center pt-15">
                        <button
                          type="reset"
                          className="btn btn-light me-3"
                          onClick={handleClose}
                        >
                          Discard
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                        >
                          <span className="indicator-label">Submit</span>
                          {/* <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span> */}
                        </button>
                      </div>
                      {/*end::Actions*/}
                      <div />
                    </form>
                    {/*end::Form*/}
                  </Modal.Body>
                  {/*end::Modal body*/}
                </Modal>
                {/*end::Modal - Add task*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            <div className="material-table__box">
              <MaterialTable
                style={{
                  zIndex: 0
                }}
                columns={tableColumns}
                data={shiftsDataForMT}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...shiftsDataForMT];
                        const index = oldData.id;
                        dataUpdate[index] = newData;
                        updateShift(dataUpdate[index], dispatch);
                        setRefresh((preValue) => !preValue);
                        resolve();
                      }, 1000);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const index = oldData.id;
                        console.log("index >>>", index);
                        deleteShift(index, dispatch);
                        setRefresh((preValue) => !preValue);
                        resolve();
                      }, 1000);
                    }),
                }}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportCsv: (columns, data) => {
                    alert(
                      "You should develop a code to export " +
                      data.length +
                      " rows"
                    );
                  },
                  showTitle: false,
                  filtering: true,
                  search: false,
                  toolbar: false,

                  pageSizeOptions: [
                    5,
                    10,
                    { value: shiftsDataForMT?.length, label: "All" },
                  ],
                  // pageSizeOptions: [5, 10, 20],
                  headerStyle: {
                    color: "#A1A5B7 ",
                    borderBottom: "#E4E6EF",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dashed",
                    fontWeight: "600",
                    fontSize: "16px",
                  },
                }}
              />
            </div>
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Container*/}
      </div>
    </>
  );
};

export default Shift;
