import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import "./SideBar.css";
import { GlobalContext } from '../../context/Context';


const SideBar = () => {
  const { state } = useContext(GlobalContext);
  // console.log("state sideBar >>>", state);

  const location = useLocation();
  // console.log("location >>>", location);

  const list = [
    {
      id: 3,
      url: "/productionView",
      icon: "fa-solid fa-eye",
      text: "ProductionView",
    },
    {
      id: 2,
      url: "/stopDetails",
      icon: "fa-solid fa-stopwatch",
      text: "StopDetails",
    },
    {
      id: 4,
      url: "/curruntStatus",
      icon: "fa-solid fa-battery-half",
      text: "CurruntStatus",
    },
    {
      id: 1,
      url: "/orders",
      icon: "fa-brands fa-first-order",
      text: "Orders",
    },
    {
      id: 5,
      url: "/lines",
      icon: "fa-solid fa-lines-leaning",
      text: "Lines",
    },
    {
      id: 6,
      url: "/sku",
      icon: "fa-brands fa-product-hunt",
      text: "SKU",
    },
    {
      id: 7,
      url: "/team",
      icon: "fa-solid fa-people-group",
      text: "Team",
    },
    // {
    //   id: 8,
    //   url: "/category",
    //   icon: "fa-solid fa-database",
    //   text: "Category",
    // },
    {
      id: 9,
      url: "/shift",
      icon: "fa-solid fa-business-time",
      text: "Shift",
    },
    {
      id: 10,
      url: "/causes",
      icon: "fa-solid fa-icicles",
      text: "Causes",
    },
    {
      id: 10,
      url: "/distributer",
      icon: "fa-solid fa-icicles",
      text: "Distributer",
    },
    // {
    //   id:11,
    //   url: "/complains",
    //   icon: "fa-solid fa-screwdriver-wrench",
    //   text: "Complains"
    // },
    // {
    //   id:12,
    //   url: "/users",
    //   icon: "fa-solid fa-screwdriver-wrench",
    //   text: "Users"
    // },
  ];



  return (
    // <div className="aside pb-5 pt-5 pt-lg-0" style={{height: "100vh"}}>
    <>
      <div className="aside aside-fixed pb-5 pt-5 pt-lg-0">
        {/*begin::Brand*/}
        <div className="aside-logo py-8">
          {/*begin::Logo*/}
          <a href="javascript:void" className="d-flex align-items-center fw-boldest fs-2hx">
            OEE
            {/* <img
            alt="Logo"
            src="assets/media/logos/logo-demo6.svg"
            className="h-45px logo"
          /> */}
          </a>
          {/*end::Logo*/}
        </div>
        {/*end::Brand*/}
        {/*begin::Aside menu*/}
        <div className="aside-menu flex-column-fluid">
          {/*begin::Aside Menu*/}
          <div
            className="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1"
            // style={{ height: "520px" }}
            style={{ height: "70vh" }}
          >
            {/*begin::Menu*/}
            <div className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold">
              <div
                className={`menu-item py-2 ${location.pathname === "/" ? "here show" : ""
                  }`}
              >
                <span className="menu-link menu-center">
                  <Link to="/">
                    <span className="menu-icon me-0">
                      <i className="fa-solid fa-house-user fs-2"></i>
                    </span>
                  </Link>
                  <Link to="/">
                    <span className="menu-title">Home</span>
                  </Link>
                </span>
              </div>

              {/* <div className={`menu-item py-2 ${location.pathname ==="/system" ? "here show": ""}`}>
              <span className="menu-link menu-center">
                <Link to="/system">
                  <span className="menu-icon me-0">
                    <i className="fa-solid fa-gear fs-2"></i>
                  </span>
                </Link>
                <Link to="/system">
                  <span className="menu-title">System</span>
                </Link>
              </span>
            </div> */}
              {list.map((item, i) => {
                return (
                  <div
                    className={`menu-item py-1 ${location.pathname === item.url ? "here show" : ""
                      }`}
                    key={item.id}
                  >
                    <span className="menu-link menu-center">
                      <Link to={item.url}>
                        <span className="menu-icon me-0">
                          <i className={`${item.icon} fs-2`}></i>
                        </span>
                      </Link>
                      <Link to={item.url}>
                        <span className="menu-title">{item.text}</span>
                      </Link>
                    </span>
                  </div>
                );
              })}
              {/* <div className="menu-item py-3"> */}
              {/* <div
                className={`menu-item py-2 ${location.pathname === "/complains" ? "here show" : ""
                  }`}
              >
                <span className="menu-link menu-center">
                  <Link to="/complains">
                    <span className="menu-icon me-0">
                      <i className="fa-solid fa-screwdriver-wrench fs-2"></i>
                    </span>
                  </Link>
                  <Link to="/complains">
                    <span className="menu-title">Complains</span>
                  </Link>
                </span>
              </div> */}
              {/* <div className="menu-item py-3"> */}
              <div
                className={`menu-item py-2 ${location.pathname === "/users" ? "here show" : ""
                  }`}
              >
                <span className="menu-link menu-center">
                  <Link to="/users">
                    <span className="menu-icon me-0">
                      <i className="fa-solid fa-user-gear fs-2"></i>
                    </span>
                  </Link>
                  <Link to="/users">
                    <span className="menu-title">Users</span>
                  </Link>
                </span>
              </div>
            </div>
            {/*end::Menu*/}
          </div>
          {/*end::Aside Menu*/}
        </div>
        {/*end::Aside menu*/}
        {/*begin::Footer*/}
        <div className="aside-footer flex-column-auto">
          {/*begin::Menu*/}
          <div className="d-flex justify-content-center">
            <Link
              to="/information"
            >
              <button
                type="button"
                className="btn btm-sm btn-icon btn-active-color-primary"
              >
                {/*begin::Svg Icon | path: icons/duotune/general/gen008.svg*/}
                <span className="svg-icon svg-icon-1">
                  <i className="fa-solid fa-circle-info"></i>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z"
                      fill="currentColor"
                    />
                  </svg> */}
                </span>
                {/*end::Svg Icon*/}
              </button>
            </Link>

          </div>
          {/*end::Menu*/}
        </div>
        {/*end::Footer*/}
      </div>
      {state.toggleMobile &&
        (
          <div className="aside d-flex aside-fixed pb-5 pt-5 pt-lg-0">
            {/*begin::Brand*/}
            <div className="aside-logo py-8">
              {/*begin::Logo*/}
              <a href="javascript:void" className="d-flex align-items-center">
                OEE
                {/* <img
            alt="Logo"
            src="assets/media/logos/logo-demo6.svg"
            className="h-45px logo"
          /> */}
              </a>
              {/*end::Logo*/}
            </div>
            {/*end::Brand*/}
            {/*begin::Aside menu*/}
            <div className="aside-menu flex-column-fluid">
              {/*begin::Aside Menu*/}
              <div
                className="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1"
              // style={{ height: "520px" }}
              // style={{ height: "70vh" }}
              >
                {/*begin::Menu*/}
                <div className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold">
                  <div
                    className={`menu-item py-2 ${location.pathname === "/" ? "here show" : ""
                      }`}
                  >
                    <span className="menu-link menu-center">
                      <Link to="/">
                        <span className="menu-icon me-0">
                          <i className="fa-solid fa-house-user fs-2"></i>
                        </span>
                      </Link>
                      <Link to="/">
                        <span className="menu-title">Home</span>
                      </Link>
                    </span>
                  </div>
                  {list.map((item, i) => {
                    return (
                      <div
                        className={`menu-item py-1 ${location.pathname === item.url ? "here show" : ""
                          }`}
                        key={item.id}
                      >
                        <span className="menu-link menu-center">
                          <Link to={item.url}>
                            <span className="menu-icon me-0">
                              <i className={`${item.icon} fs-2`}></i>
                            </span>
                          </Link>
                          <Link to={item.url}>
                            <span className="menu-title" style={{ fontSize: '9px' }}>{item.text}</span>
                          </Link>
                        </span>
                      </div>
                    );
                  })}
                  {/* <div className="menu-item py-3"> */}
                  <div
                    className={`menu-item py-2 ${location.pathname === "/complains" ? "here show" : ""
                      }`}
                  >
                    <span className="menu-link menu-center">
                      <Link to="/complains">
                        <span className="menu-icon me-0">
                          <i className="fa-solid fa-screwdriver-wrench fs-2"></i>
                        </span>
                      </Link>
                      <Link to="/complains">
                        <span className="menu-title">Complains</span>
                      </Link>
                    </span>
                  </div>
                  {/* <div className="menu-item py-3"> */}
                  <div
                    className={`menu-item py-2 ${location.pathname === "/users" ? "here show" : ""
                      }`}
                  >
                    <span className="menu-link menu-center">
                      <Link to="/users">
                        <span className="menu-icon me-0">
                          <i className="fa-solid fa-user-gear fs-2"></i>
                        </span>
                      </Link>
                      <Link to="/users">
                        <span className="menu-title">Users</span>
                      </Link>
                    </span>
                  </div>
                </div>
                {/*end::Menu*/}
              </div>
              {/*end::Aside Menu*/}
            </div>
            {/*end::Aside menu*/}
          </div>
        )
      }
    </>

  );
};

export default SideBar;
