import { createSlice } from "@reduxjs/toolkit";

const shiftSlice = createSlice({
  name: "shift",
  initialState: {
    shifts: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET SHIFT
    getShiftStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getShiftSuccess: (state, action) => {
      state.isFetching = false;
      state.shifts = action.payload;
    },
    getShiftFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE SHIFT
    deleteShiftStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteShiftSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteShiftFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE SHIFT
    updateShiftStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateShiftSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateShiftFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD SHIFT
    addShiftStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addShiftSuccess: (state, action) => {
      state.isFetching = false;
      state.shifts.push(action.payload);
    },
    addShiftFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getShiftStart,
  getShiftSuccess,
  getShiftFailure,
  deleteShiftStart,
  deleteShiftSuccess,
  deleteShiftFailure,
  updateShiftStart,
  updateShiftSuccess,
  updateShiftFailure,
  addShiftStart,
  addShiftSuccess,
  addShiftFailure,
} = shiftSlice.actions;
export default shiftSlice.reducer;
