import { publicRequest } from "../../api/requestMethods";
import {
  loginFailure,
  loginStart,
  loginSuccess,
  logoutSuccess,
} from "../reducers/authReducer";
import { refreshPage } from "../../reloader/reloader";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    console.log("response from login ==>", res);
    const getToken = res.data?.token;
    localStorage.setItem("token", getToken);
    dispatch(loginSuccess(res.data));
    refreshPage();
    return res;
  } catch (error) {
    console.log("error from loginFailure >>>", error);
    dispatch(loginFailure());
    return error;
  }
};

export const logout = async (dispatch) => {
  localStorage.removeItem("token");
  dispatch(logoutSuccess());
};
