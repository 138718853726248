import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import MaterialTable from "material-table";
import ToolBar from "../../components/ToolBar/ToolBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  createLines,
  deleteLines,
  getLines,
  updateLines,
} from "../../store/redux-apis/lineApiCalls";

const Lines = () => {
  const linesData = useSelector((state) => state.line.lines?.data);
  const [userData, setUserData] = useState({
    machineName: "",
    deviceId: "",
  });
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(1);


  useEffect(() => {
    getLines(dispatch);
  }, [dispatch, refresh]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userData);
    handleClose();
    createLines(dispatch, userData);
    setRefresh((preValue) => !preValue);

  };

  // Material Table work
  const tableColumns = [
    { title: "Machine/Device Name", field: "deviceName" },
    { title: "Device Id", field: "deviceId" },
  ];
  const lineDataForMT = linesData?.map((o) => ({ ...o }));

  // button for Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* begin: Toolbar */}
      <ToolBar />
      {/* end: Toolbar */}
      <div className="content d-flex flex-column flex-column-fluid">
        {/*begin::Container*/}
        <div className="container-xxl">
          {/*begin::Row*/}
          <div className="card">
            <div className="card-header border-0 pt-6 d-flex justify-content-between">
              {/*begin::Card title*/}
              <div className="card-title">
                {/*begin::Search*/}
                <div className="d-flex align-items-center position-relative my-1">
                  {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}

                </div>
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Toolbar*/}
                <div className="d-flex justify-content-end">
                  {/*begin::Add Line*/}
                  <Button
                    variant="primary"
                    className="btn btn-primary"
                    onClick={handleShow}
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="11.364"
                          y="20.364"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11.364 20.364)"
                          fill="currentColor"
                        />
                        <rect
                          x="4.36396"
                          y="11.364"
                          width={16}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}Add Line
                  </Button>

                  {/*end::Add Line*/}
                </div>
                {/*end::Toolbar*/}
                {/*begin::Modal - Add task*/}
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  {/*begin::Modal body*/}
                  <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
                    {/*begin::Form*/}
                    <form
                      className="form fv-plugins-bootstrap5 fv-plugins-framework"
                      action="#"
                      onSubmit={handleSubmit}
                    >
                      {/*begin::Scroll*/}
                      <div
                        className="d-flex flex-column scroll-y me-n7 pe-7"
                        style={{ maxHeight: "422px" }}
                      >
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Machine Name
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            name="machineName"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="Machine name"
                            value={userData.machineName}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                machineName: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Device Id
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            name="deviceId"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="123"
                            value={userData.deviceId}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                deviceId: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Scroll*/}
                      {/*begin::Actions*/}
                      <div className="text-center pt-15">
                        <button
                          type="reset"
                          className="btn btn-light me-3"
                          onClick={handleClose}
                        >
                          Discard
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                        // onClick={handleClose}
                        >
                          <span className="indicator-label">Submit</span>
                          {/* <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span> */}
                        </button>
                      </div>
                      {/*end::Actions*/}
                      <div />
                    </form>
                    {/*end::Form*/}
                  </Modal.Body>
                  {/*end::Modal body*/}
                </Modal>
                {/*end::Modal - Add task*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            <div className="material-table__box">
              <MaterialTable
                style={{
                  zIndex: 0
                }}
                // title="Line Details"
                columns={tableColumns}
                data={lineDataForMT}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...lineDataForMT];
                        const index = oldData.id;
                        console.log("index >>>", index);
                        dataUpdate[index] = newData;
                        updateLines(dataUpdate[index], dispatch);
                        setRefresh((preValue) => !preValue);

                        setShow(false)
                        resolve();
                      }, 1000);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const index = oldData.id;
                        console.log("index >>>", index);
                        // alert("You want to delete " + oldData.deviceName);
                        deleteLines(index, dispatch);
                        setRefresh((preValue) => !preValue);

                        setShow(false);
                        resolve();
                      }, 1000);
                    }),
                }}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportCsv: (columns, data) => {
                    alert(
                      "You should develop a code to export " +
                      data.length +
                      " rows"
                    );
                  },
                  showTitle: false,
                  filtering: true,
                  search: false,
                  toolbar: false,

                  pageSizeOptions: [
                    5,
                    10,
                    20,
                    { value: lineDataForMT?.length, label: "All" },
                  ],
                  // pageSizeOptions: [5, 10, 20],
                  headerStyle: {
                    color: "#A1A5B7 ",
                    borderBottom: "#E4E6EF",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dashed",
                    fontWeight: "600",
                    fontSize: "16px",
                  },
                }}
              />
            </div>
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Container*/}
      </div>
    </>
  );
};

export default Lines;
