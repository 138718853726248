import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import MaterialTable from "material-table";
import ToolBar from "../../components/ToolBar/ToolBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getCauses,
  createCause,
  deleteCause,
  updateCause
} from "../../store/redux-apis/causeApiCalls";

const Causes = () => {
  const causesData = useSelector((state) => state.cause.causes?.data);
  console.log("causesData", causesData);
  const causesDataForMT = causesData?.map((s) => ({ ...s }));
  console.log("causesDataForMT >>>", causesDataForMT);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [userData, setUserData] = useState({
    causeOne: "",
    causeTwo: "",
    causeThree: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userData);
    createCause(dispatch, userData)
    setIsRefresh((preValue) => !preValue)
    handleClose()
  }

  //useEffect
  useEffect(() => {
    getCauses(dispatch);
  }, [dispatch, isRefresh]);


  const tableColumns = [
    { title: "Cause One", field: "causeOne" },
    { title: "Cause Two", field: "causeTwo" },
    { title: "Cause Three", field: "causeThree" },
  ];
  return (
    <>
      {/* begin: Toolbar */}
      <ToolBar />
      {/* end: Toolbar */}
      <div className="content d-flex flex-column flex-column-fluid">
        {/*begin::Container*/}
        <div className="container-xxl">
          {/*begin::Row*/}
          <div className="card">
            <div className="card-header border-0 pt-6 d-flex justify-content-between">
              {/*begin::Card title*/}
              <div className="card-title">
                {/*begin::Search*/}
                <div className="d-flex align-items-center position-relative my-1">
                  {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}

                </div>
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Toolbar*/}
                <div className="d-flex justify-content-end">
                  {/*begin::Add SKU*/}
                  <Button
                    variant="primary"
                    className="btn btn-primary"
                    onClick={handleShow}
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="11.364"
                          y="20.364"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11.364 20.364)"
                          fill="currentColor"
                        />
                        <rect
                          x="4.36396"
                          y="11.364"
                          width={16}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}Add Causes
                  </Button>

                  {/*end::Add SKU*/}
                </div>
                {/*end::Toolbar*/}
                {/*begin::Modal - Add task*/}
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Team</Modal.Title>
                  </Modal.Header>
                  {/*begin::Modal body*/}
                  <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
                    {/*begin::Form*/}
                    <form
                      className="form fv-plugins-bootstrap5 fv-plugins-framework"
                      onSubmit={handleSubmit}
                    >
                      {/*begin::Scroll*/}
                      <div
                        className="d-flex flex-column scroll-y me-n7 pe-7"
                        style={{ maxHeight: "422px" }}
                      >
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Cause One
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            name="causeOne"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="Cause One"
                            value={userData.causeOne}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                causeOne: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Cause Two
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            name="causeTwo"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="Cause Two"
                            value={userData.causeTwo}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                causeTwo: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Cause Three
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            name="causeThree"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="Cause Three"
                            value={userData.causeThree}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                causeThree: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Scroll*/}
                      {/*begin::Actions*/}
                      <div className="text-center pt-15">
                        <button
                          type="reset"
                          className="btn btn-light me-3"
                          onClick={handleClose}
                        >
                          Discard
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                        >
                          <span className="indicator-label">Submit</span>
                          {/* <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span> */}
                        </button>
                      </div>
                      {/*end::Actions*/}
                      <div />
                    </form>
                    {/*end::Form*/}
                  </Modal.Body>
                  {/*end::Modal body*/}
                </Modal>
                {/*end::Modal - Add task*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            <div className="material-table__box">
              <MaterialTable
                style={{
                  zIndex: 0
                }}
                // title="User Details"
                columns={tableColumns}
                data={causesDataForMT}
                actions={[
                  {
                    icon: "save",
                    tooltip: "Save User",
                    onClick: (event, rowData) =>
                      alert("You saved " + rowData.name),
                  },
                  (rowData) => ({
                    icon: "delete",
                    tooltip: "Delete User",
                    onClick: (event, rowData) =>
                      alert("You want to delete " + rowData.name),
                    disabled: rowData.birthYear < 2000,
                  }),
                ]}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportCsv: (columns, data) => {
                    alert(
                      "You should develop a code to export " +
                      data.length +
                      " rows"
                    );
                  },
                  showTitle: false,
                  filtering: true,
                  search: false,
                  toolbar: false,

                  pageSizeOptions: [
                    5,
                    10,
                    20,
                    { value: causesDataForMT?.length, label: "All" },
                  ],
                  // pageSizeOptions: [5, 10, 20],
                  headerStyle: {
                    color: "#A1A5B7 ",
                    borderBottom: "#E4E6EF",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dashed",
                    fontWeight: "600",
                    fontSize: "16px",
                  },
                }}
              />
            </div>
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Container*/}
      </div>
    </>
  );
};

export default Causes;
