import React from "react";

const SuccussDownArrow = () => {
  return (
    <span className="svg-icon svg-icon-5 svg-icon-success ms-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect
          opacity="0.5"
          x={13}
          y={6}
          width={13}
          height={2}
          rx={1}
          transform="rotate(90 13 6)"
          fill="currentColor"
        />
        <path
          d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
          fill="currentColor"
        />
      </svg>
    </span>
  )
}

const DangerDownArrow = () => {
  return (
    <span className="svg-icon svg-icon-5 svg-icon-danger ms-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect
          opacity="0.5"
          x={11}
          y={18}
          width={13}
          height={2}
          rx={1}
          transform="rotate(-90 11 18)"
          fill="currentColor"
        />
        <path
          d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
          fill="currentColor"
        />
      </svg>
    </span>
  )
}

const WidgetTwo = ({
  shippableValue,
  availbleMinutes,
  plannedMinutes,
  shutdownMinutes,
  upTime,
  unplannedDownTime,
  unplannedStops,
  plannedDownTime,
  plannedStops,
  totalStops,
  totalDownTime,
  totalWaste,
  wolfOneDownTime,
  wolfTwoDownTime,
  wolfThreeDownTime,
  technicalStops,
  operationalStops,
  technicalStopsTime,
  operationalStopsTime,
  mttr,
  mtbf
}) => {
  return (
    <div className="card card-xl-stretch mb-xl-8"

    >
      {/*begin::Beader*/}
      <div className="card-header border-0 pt-5 pb-0">
        <h3 className="w-100 card-title align-items-center flex-column">
          <span className="card-label text-center fw-bolder fs-3 mb-1">K.P.I,s</span>
          <span className="text-center fw-bold fs-6">
            Key performance indicators
          </span>
        </h3>
        <div className="card-toolbar">
          {/*begin::Menu*/}
          {/* <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
          > */}
          {/*begin::Svg Icon | path: icons/duotune/general/gen024.svg*/}
          {/* <span className="svg-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect
                    x={5}
                    y={5}
                    width={5}
                    height={5}
                    rx={1}
                    fill="currentColor"
                  />
                  <rect
                    x={14}
                    y={5}
                    width={5}
                    height={5}
                    rx={1}
                    fill="currentColor"
                    opacity="0.3"
                  />
                  <rect
                    x={5}
                    y={14}
                    width={5}
                    height={5}
                    rx={1}
                    fill="currentColor"
                    opacity="0.3"
                  />
                  <rect
                    x={14}
                    y={14}
                    width={5}
                    height={5}
                    rx={1}
                    fill="currentColor"
                    opacity="0.3"
                  />
                </g>
              </svg>
            </span> */}
          {/*end::Svg Icon*/}
          {/* </button> */}
          {/*end::Menu*/}
        </div>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="card-body p-0 d-flex flex-column scroll-y"
        style={{ maxHeight: "400px" }}

      >
        {/*begin::Stats*/}
        <div className="card-p  bg-body flex-grow-1 pt-0">
          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0">
            {/*begin::Col*/}
            <div className="col h-100 mr-8 ">
              {/*begin::Label*/}
              <div className="d-flex flex-column bg-light-primary px-2 mx-1 rounded-2">
                <div className=" fs-6  fw-bold text-primary text-center fw-semibold fs-6 align-self-start d-flex justify-content-center w-100">Shippable <br /> Products</div>
                {/*end::Label*/}
                {/*begin::Stat*/}
                <div className=" align-self-baseline d-flex justify-content-center w-100">
                  <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{shippableValue}Pcs</div>
                </div>
                {/*end::Stat*/}
              </div>
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="col h-100 ">
              {/*begin::Label*/}
              <div className="d-flex flex-column bg-light-primary px-2 mx-1 rounded-2">

                <div className=" fs-6  fw-bold text-primary text-center fw-semibold fs-6 align-self-start d-flex justify-content-center w-100">Available <br /> Minutes</div>
                {/*end::Label*/}
                {/*begin::Stat*/}
                <div className="fs-7 pt-1 fw-bolder d-flex justify-content-center w-100 align-self-baseline">{availbleMinutes}</div>
                {/*end::Stat*/}
              </div>
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0 mt-8">
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col mr-8">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Planned <br />Minutes</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{plannedMinutes}</div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Shutdown <br />Minutes</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="d-flex align-items-center">
                <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{shutdownMinutes}</div>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                {/* <DangerDownArrow /> */}
                {/*end::Svg Icon*/}
              </div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0 mt-8">
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col mr-8">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Total<br /> DownTime</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{totalDownTime} </div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Total <br />Waste (%)</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="d-flex align-items-center">
                <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{totalWaste}</div>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                {/* <DangerDownArrow /> */}
                {/*end::Svg Icon*/}
              </div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0 mt-8">
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col mr-8 ">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Up <br />Time</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{upTime}</div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Unplan<br />DownTime</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="d-flex align-items-center">
                <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{unplannedDownTime}</div>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                {/* <DangerDownArrow /> */}
                {/*end::Svg Icon*/}
              </div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0 mt-8">
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col mr-8">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Unplanned<br /> Stops</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{unplannedStops}</div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Planned <br />DownTime</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="d-flex align-items-center">
                <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{plannedDownTime}</div>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                {/* <DangerDownArrow /> */}
                {/*end::Svg Icon*/}
              </div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0 mt-8">
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col mr-8">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Planned <br />Stops</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{plannedStops}</div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Total <br />Stops</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="d-flex align-items-center">
                <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{totalStops}</div>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                {/* <DangerDownArrow /> */}
                {/*end::Svg Icon*/}
              </div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Row*/}

          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0 mt-8">
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col mr-8">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Wolf 1<br /> DownTime</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{wolfOneDownTime}</div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Wolf 2<br /> DownTime</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="d-flex align-items-center">
                <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{wolfTwoDownTime}</div>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                {/* <DangerDownArrow /> */}
                {/*end::Svg Icon*/}
              </div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0 mt-8">
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col mr-8">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Wolf 3<br /> DonwTime</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{wolfThreeDownTime}</div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Technical <br /> Stops</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="d-flex align-items-center">
                <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{technicalStops}</div>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                {/* <DangerDownArrow /> */}
                {/*end::Svg Icon*/}
              </div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}

          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0 mt-8">
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col mr-8">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Operational <br />Stops</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{operationalStops}</div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Technical<br /> StopsTime</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="d-flex align-items-center">
                <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{technicalStopsTime}</div>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                {/* <DangerDownArrow /> */}
                {/*end::Svg Icon*/}
              </div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}

          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0 mt-8">
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col mr-8">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">Operational<br /> StopsTime</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{operationalStopsTime}</div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col h-100">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">MTTR</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="d-flex align-items-center">
                <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{mttr}</div>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                {/* <DangerDownArrow /> */}
                {/*end::Svg Icon*/}
              </div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}

          </div>
          {/*end::Row*/}
          {/*begin::Row*/}
          <div className="d-flex align-items-center row g-0 mt-8">
            {/*begin::Col*/}
            <div className="bg-light-primary px-2 mx-1 rounded-2 col mr-8 text-center">
              {/*begin::Label*/}
              <div className=" fs-6  fw-bold text-primary text-center d-flex justify-content-center w-100 fw-semibold fs-6">MTBF</div>
              {/*end::Label*/}
              {/*begin::Stat*/}
              <div className="fs-7 pt-1  fw-bolder d-flex justify-content-center w-100">{mtbf}</div>
              {/*end::Stat*/}
            </div>
            {/*end::Col*/}


          </div>
          {/*end::Row*/}
        </div>
        {/*end::Stats*/}
        {/*begin::Chart*/}
        {/*end::Chart*/}
      </div>
      {/*end::Body*/}
    </div>
  );
};

export default WidgetTwo;
