import { userRequest } from "../../api/requestMethods";
import {
  addCategoryStart,
  addCategoryFailure,
  addCategorySuccess,
  updateCategoryFailure,
  updateCategoryStart,
  updateCategorySuccess,
  getCategoryFailure,
  getCategoryStart,
  getCategorySuccess,
  deleteCategoryFailure,
  deleteCategoryStart,
  deleteCategorySuccess,
} from "../reducers/categoryReducer";

export const createCategory = async (dispatch, userData) => {
  dispatch(addCategoryStart());
  try {
    const res = await userRequest.post("category/createCategory", userData);
    console.log("response team api", res);
    // localStorage.getItem("token");
    dispatch(addCategorySuccess(res.data));
  } catch (error) {
    console.log("error from catch block of  add category", error);
    dispatch(addCategoryFailure());
  }
};
export const getCategory = async (dispatch) => {
  dispatch(getCategoryStart());
  try {
    const res = await userRequest.get("category/getCategory");
    console.log("response from get team function in getCAteofory ", res);
    // localStorage.getItem("token");
    dispatch(getCategorySuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(getCategoryFailure());
  }
};

export const deleteCategory = async (id,dispatch) => {
  dispatch(deleteCategoryStart());
  try {
    const res = await userRequest.post("category/deleteCategory", {id});
    console.log("response from delete Category function in Category", res);
    // localStorage.getItem("token");
    dispatch(deleteCategorySuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteCategoryFailure());
  }
};
export const updateCategory = async (userData, dispatch) => {
  dispatch(updateCategoryStart());
  try {
    const res = await userRequest.post("category/updateCategory",  userData );
    console.log("response from update category function in categoryAPi", res);
    // localStorage.getItem("token");
    dispatch(updateCategorySuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateCategoryFailure());
  }
};
