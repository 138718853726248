import { userRequest } from "../../api/requestMethods";
import { addCauseFailure, addCauseStart, addCauseSuccess, deleteCauseFailure, deleteCauseStart, deleteCauseSuccess, getCauseFailure, getCauseStart, getCauseSuccess, updateCauseFailure, updateCauseStart, updateCauseSuccess } from "../reducers/causeReducer";


export const getCauses = async (dispatch) => {
  dispatch(getCauseStart());
  try {
    const res = await userRequest.get("/cause/getCauses");
    console.log("response from get Cause ==>", res);
    dispatch(getCauseSuccess(res.data));
  } catch (error) {
    console.log("error from causeFailure >>>", error);
    dispatch(getCauseFailure());
  }
};

export const createCause = async (dispatch, inputData) => {
  dispatch(addCauseStart());
  try {
    const res = await userRequest.post("/cause/createCause", inputData);
    console.log("response from add Cause ==>", res);
    dispatch(addCauseSuccess(res.data));
  } catch (error) {
    console.log("error from causeFailure >>>", error);
    dispatch(addCauseFailure());
  }
};

export const updateCause = async (inputData, dispatch) => {
  console.log("inputData >>>",inputData);
  dispatch(updateCauseStart());
  try {
    const res = await userRequest.post("/cause/updateCauses", inputData );
    console.log("response from update Cause ==>", res);
    dispatch(updateCauseSuccess(res.data));
  } catch (error) {
    console.log("error from causeFailure >>>", error);
    dispatch(updateCauseFailure());
  }
};

export const deleteCause = async (id, dispatch) => {
  dispatch(deleteCauseStart());
  try {
    const res = await userRequest.post("/cause/deleteCause", {id});
    console.log("response from delete Cause ==>", res);
    dispatch(deleteCauseSuccess(res.data));
  } catch (error) {
    console.log("error from causeFailure >>>", error);
    dispatch(deleteCauseFailure());
  }
};
