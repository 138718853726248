import React from 'react';
import { Gauge } from '@ant-design/plots';

export const OverAllComp = ({ value }) => {
    const config = {
        percent: value / 100,
        range: {
            ticks: [0, 1 / 4, 2 / 3.5, 1],
            color: ['#F4664A', '#FAAD14', '#30BF78'],
            textColor: "#000",
            width: 12,

        },
        indicator: {
            pointer: {
                style: {
                    stroke: '#9a9898',
                    color: '#000'
                },
            },
            pin: {
                style: {
                    stroke: '#9a9898',
                    color: '#000'
                },
            },
            text: {
                style: {
                    color: '#000',
                }
            }
        },
        statistic: {
            content: {
                formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                style: {
                    marginTop: '10px',
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#000',
                    fontWeight: 'bold',

                },
            },
            style: {
                fontSize: 12,
                fontWeight: 300,
                textAlign: 'center',
                textBaseline: 'middle',
                shadowColor: 'black',
                shadowBlur: 10,
            }
        },
        gaugeStyle: {
            lineCap: 'round',
            color: '#000'

        },
        axis: {
            label: {
                formatter(v) {
                    return Number(v) * 100;
                },
                style: {
                    fill: '#000',
                    // opacity: 0.6,
                    fontSize: 10,
                    // fontWeight: "bold",
                }
            },
            subTickLine: {
                count: 3,
            },
        },
    };
    return <Gauge {...config} />;
};

export const GuageComp = ({ value, color }) => {
    const config = {
        percent: value / 100,
        // radius: value / 100,
        range: {
            color: color,
            width: 12,
        },
        indicator: {
            pointer: {
                style: {
                    stroke: '#9a9898',
                    color: '#000'

                },
            },
            pin: {
                style: {
                    stroke: '#9a9898',
                    color: '#000'

                },
            },
        },
        statistic: {
            content: {
                formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                style: {
                    marginTop: '10px',
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#000',
                    fontWeight: 'bold',

                },
            },
            // content: {
            //     formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            // },
            style: {
                fontSize: 12,
                color: '#000'

            },
        },
        gaugeStyle: {
            lineCap: 'round',
            color: '#000'
        },
        axis: {
            label: {
                formatter(v) {
                    return Number(v) * 100;
                },
                style: {
                    fill: '#000',
                    // opacity: 0.6,
                    fontSize: 10,
                    // fontWeight: "bold",
                }
            },
            subTickLine: {
                count: 3,
            },
        },
    };
    return <Gauge {...config} />;
};


export const PerformanceComp = ({ value, color }) => {
    const config = {
        percent: 0.75,
        range: {
            color: 'l(0) 0:#B8E1FF 1:#3D76DD',
        },
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
        indicator: null,
        statistic: {
            title: {
                offsetY: -36,
                style: {
                    fontSize: '36px',
                    color: '#4B535E',
                },
                formatter: () => '70%',
            },
            content: {
                style: {
                    fontSize: '24px',
                    lineHeight: '44px',
                    color: '#4B535E',
                },
                formatter: () => '加载进度',
            },
        },
    };
    return <Gauge {...config} />;
};
