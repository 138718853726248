import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../store/redux-apis/loginApiCalls";

import { toast } from "react-toastify";
import logo from "../../assets/logo/Pakistan-Automation.png";

const Login = () => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    console.log("e.target.name, e.target.value", e.target.name, e.target.value);
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inputs >>", inputs);
    const { email, password } = inputs;
    if (email === "" && password === "") {
      return;
    }
    setIsLoading(true)
    const result = await login(dispatch, { email, password });
    if (result.status === 200) {
      const getToken = result.data?.token;
      console.log("Token: " + getToken)
      localStorage.setItem("token", getToken);
      setIsLoading(false)
      toast.success("Login Successfully")

    } else {
      toast.error(result.response.data.message)
      setIsLoading(false)
    }
  };
  return (
    <div
      className="d-flex flex-column flex-root align-item-center justify-content-center"
      style={{ height: "100vh" }}
    >
      {/*begin::Authentication - Sign-in */}
      <div className="d-flex bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        {/*begin::Content*/}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 ">
          {/*begin::Wrapper*/}
          <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            {/*begin::Form*/}
            <form
              className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
              // noValidate="novalidate"
              onClick={handleSubmit}
            >
              <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                {/*begin::Logo*/}
                <a href className="mb-12">
                  <img alt="Logo" src={logo} className="h-80px" />
                </a>
                {/*end::Logo*/}
              </div>
              {/*begin::Heading*/}
              <div className="text-center mb-10">
                {/*begin::Title*/}
                <h1 className="text-dark mb-3">Sign In to OEE</h1>
                {/*end::Title*/}
              </div>
              {/*begin::Heading*/}
              {/*begin::Input group*/}
              <div className="fv-row mb-10 fv-plugins-icon-container">
                {/*begin::Label*/}
                <label className="form-label fs-6 fw-bolder text-dark">
                  Email
                </label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="email"
                  name="email"
                  autoComplete="off"
                  placeholder="Enter your email address"
                  onChange={handleChange}
                />
                {/*end::Input*/}
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="fv-row mb-10 fv-plugins-icon-container">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-stack mb-2">
                  {/*begin::Label*/}
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    Password
                  </label>
                  {/*end::Label*/}
                  {/*begin::Link*/}
                  <a href className="link-primary fs-6 fw-bolder">
                    Forgot Password ?
                  </a>
                  {/*end::Link*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Input*/}
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="password"
                  name="password"
                  autoComplete="off"
                  placeholder="Enter your password"
                  onChange={handleChange}
                />
                {/*end::Input*/}
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
              {/*end::Input group*/}
              {/*begin::Actions*/}
              <div className="text-center">
                {/*begin::Submit button*/}
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                >
                  {isLoading === false ?
                    <span className="indicator-label">Continue</span>
                    : null}

                  {isLoading ?
                    <span className="indicator-progress" >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                    :
                    null
                  }

                </button>
                {/*end::Submit button*/}
              </div>
              {/*end::Actions*/}
              <div />
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Authentication - Sign-in*/}
    </div>
  );
};

export default Login;
