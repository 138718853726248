import { userRequest } from "../../api/requestMethods";
import {
  addSkuFailure,
  addSkuStart,
  addSkuSuccess,
  getSkuFailure,
  getSkuStart,
  getSkuSuccess,
  updateSkuFailure,
  updateSkuStart,
  updateSkuSuccess,
  deleteSkuFailure,
  deleteSkuStart,
  deleteSkuSuccess,
} from "../reducers/skuReducer";

export const createSku = async (dispatch, userData) => {
  dispatch(addSkuStart());
  try {
    console.log("userData from createSku",userData);
    const res = await userRequest.post("/sku/createSku", userData);
    console.log("response sku api", res);
    // localStorage.getItem("token");
    dispatch(addSkuSuccess(res.data));
  } catch (error) {
    console.log("error from catch block of  add sku", error);
    dispatch(addSkuFailure());
  }
};

export const getSku = async (dispatch) => {
  dispatch(getSkuStart());
  try {
    const res = await userRequest.get("sku/getSku");
    console.log("response from get team function in getSku ", res);
    localStorage.getItem("token");
    dispatch(getSkuSuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(getSkuFailure());
  }
};  

export const deleteSku = async ( id,dispatch) => {
  dispatch(deleteSkuStart());
  try {
    console.log(id);
    const res = await userRequest.post("sku/deleteSku", {id});
    console.log("response from delete sku function in sku", res);
    localStorage.getItem("token");
    dispatch(deleteSkuSuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteSkuFailure());
  }
};
export const updateSku = async (userData, dispatch) => {
  dispatch(updateSkuStart());
  try {
    const res = await userRequest.post("sku/updateSku",  userData);
    console.log("response from update sku function in skuAPi", res);
    localStorage.getItem("token");
    dispatch(updateSkuSuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateSkuFailure());
  }
};
