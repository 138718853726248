import { createSlice } from "@reduxjs/toolkit";

const complainSlice = createSlice({
  name: "complain",
  initialState: {
    complains: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET Complain
    getComplainStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getComplainSuccess: (state, action) => {
      state.isFetching = false;
      state.complains = action.payload;
    },
    getComplainFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE Complain
    deleteComplainStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteComplainSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteComplainFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE Complain
    updateComplainStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateComplainSuccess: (state, action) => {
      state.isFetching = false;
      console.log("action.payload >>>", action.payload);
    },
    updateComplainFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD Complain
    addComplainStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addComplainSuccess: (state, action) => {
      state.isFetching = false;
    },
    addComplainFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getComplainStart,
  getComplainSuccess,
  getComplainFailure,
  deleteComplainStart,
  deleteComplainSuccess,
  deleteComplainFailure,
  updateComplainStart,
  updateComplainSuccess,
  updateComplainFailure,
  addComplainStart,
  addComplainSuccess,
  addComplainFailure,
} = complainSlice.actions;
export default complainSlice.reducer;
