import { userRequest } from "../../api/requestMethods";
import {
  addUserFailure,
  addUserStart,
  addUserSuccess,
  deleteUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  getMyDataFailure,
  getMyDataStart,
  getMyDataSuccess,
  getUserFailure,
  getUserStart,
  getUserSuccess,
  updateUserFailure,
  updateUserStart,
  updateUserSuccess,
} from "../reducers/userReducer";

export const getUsers = async (dispatch) => {
  dispatch(getUserStart());
  try {
    const res = await userRequest.get("/auth/getUsers");
    console.log("response from get users ==>", res);
    dispatch(getUserSuccess(res.data));
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getUserFailure());
  }
};

export const getMyData = async (dispatch, id) => {
  dispatch(getMyDataStart());
  try {
    const res = await userRequest.get(`/auth/getUsers?id=${id}`);
    console.log("response from get MyDatas ==>", res);
    dispatch(getMyDataSuccess(res.data));
  } catch (error) {
    console.log("error from MyDataFailure >>>", error);
    dispatch(getMyDataFailure());
  }
};

export const createUser = async (dispatch, inputData) => {
  dispatch(addUserStart());
  try {
    const res = await userRequest.post("/auth/createUser", inputData);
    console.log("response from add Users ==>", res);
    dispatch(addUserSuccess(res.data));
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(addUserFailure());
  }
};

export const updateUser = async (inputData, dispatch) => {
  console.log("inputData >>>", inputData);
  dispatch(updateUserStart());
  try {
    const res = await userRequest.post("/auth/updateUser", inputData);
    console.log("response from update User ==>", res);
    dispatch(updateUserSuccess(res.data));
    return res;
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(updateUserFailure());
  }
};

export const deleteUser = async (id, dispatch) => {
  dispatch(deleteUserStart());
  try {
    const res = await userRequest.post("/auth/deleteUser", { id });
    console.log("response from delete User ==>", res);
    dispatch(deleteUserSuccess(res.data));
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(deleteUserFailure());
  }
};
