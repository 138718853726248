import { userRequest } from "../../api/requestMethods";
import {
  getCompleteOrderStart,
  getCompleteOrderSuccess,
  getCompleteOrderFailure,
  updateCompleteOrderStart,
  updateCompleteOrderSuccess,
  updateCompleteOrderFailure,
} from "../reducers/completeOrderReducer";

export const getCompleteOrders = async (dispatch) => {
  dispatch(getCompleteOrderStart());
  try {
    const res = await userRequest.get("completeOrders/getCompleteOrders");
    console.log("response from get completeOrders ==>", res);

    var outputArray = [];

    // Count variable is used to add the
    // new unique value only once in the
    // outputArray.
    var count = 0;

    // Start variable is used to set true
    // if a repeated duplicate value is
    // encontered in the output array.
    var start = false;

    for (let j = 0; j < res.data?.data.length; j++) {
      for (let k = 0; k < outputArray.length; k++) {
        if (res.data?.data[j].plannedStart == outputArray[k].plannedStart) {
          start = true;
        }
      }
      count++;
      if (count == 1 && start == false) {
        outputArray.push(res.data?.data[j]);
      }
      start = false;
      count = 0;
    }

    console.log("outputArray >>>", outputArray);
    // dispatch(getCompleteOrderSuccess(res.data));
    dispatch(getCompleteOrderSuccess(outputArray));
  } catch (error) {
    console.log("error from completeOrdersFailure >>>", error);
    dispatch(getCompleteOrderFailure());
  }
};
export const updateCompleteOrders = async (inputData, dispatch) => {
  dispatch(updateCompleteOrderStart());
  try {
    const res = await userRequest.post(
      "/completeOrders/updateCompleteOrders",
      inputData
    );
    // console.log("response from update completeOrders ==>", res);
    dispatch(updateCompleteOrderSuccess(res.data));
  } catch (error) {
    console.log("error from completeOrdersFailure >>>", error);
    dispatch(updateCompleteOrderFailure());
  }
};
export const updateCompleteOrdersByBox = async (inputData, dispatch) => {
  dispatch(updateCompleteOrderStart());
  try {
    const res = await userRequest.post(
      "/completeOrders/updateShippableByBox",
      inputData
    );
    // console.log("response from update completeOrders ==>", res);
    dispatch(updateCompleteOrderSuccess(res.data));
  } catch (error) {
    console.log("error from completeOrdersFailure >>>", error);
    dispatch(updateCompleteOrderFailure());
  }
};

// export const createCompleteOrders = async (dispatch, inputData) => {
//   dispatch(addCompleteOrderStart());
//   try {
//     const res = await userRequest.post("/completeOrders/createCompleteOrders", inputData);
//     console.log("response from add completeOrders ==>", res);
//     dispatch(addCompleteOrderSuccess(res.data));
//   } catch (error) {
//     console.log("error from completeOrdersFailure >>>", error);
//     dispatch(addCompleteOrderFailure());
//   }
// };

// export const deleteCompleteOrders = async (id, dispatch) => {
//   dispatch(deleteCompleteOrderStart());
//   try {
//     const res = await userRequest.post("/completeOrders/deleteCompleteOrders", { id });
//     console.log("response from delete completeOrders ==>", res);
//     dispatch(deleteCompleteOrderSuccess(res.data));
//   } catch (error) {
//     console.log("error from completeOrdersFailure >>>", error);
//     dispatch(deleteCompleteOrderFailure());
//   }
// };
