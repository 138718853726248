import React from "react";
import Chart from "react-apexcharts";
import { Column, Bar } from '@ant-design/plots';


export const OEELossChartComp = ({ data }) => {
  const config = {
    data,
    xField: 'value',
    yField: 'type',
    seriesField: 'type',
    legend: {
      position: 'top-left',
      fontSize: '10px',
    },
  };
  return <Bar {...config} />;
};

const OEELossChart = () => {
  const colors = [
    "#267EC3",
    "#26E7A6",
    "#FF6178",
    "#8B75D7",
    "#FEBC3B",
    "#D830EB",
  ];

  const data = {
    series: [
      {
        data: [21, 22, 10, 28, 16, 21, 13, 30],
      },
    ],
    options: {
      title: {
        text: "OEE Loss Chart",
        align: "center",
        floating: true,
      },
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [
          ["John", "Doe"],
          ["Joe", "Smith"],
          ["Jake", "Williams"],
          "Amber",
          ["Peter", "Brown"],
          ["Mary", "Evans"],
          ["David", "Wilson"],
          ["Lily", "Roberts"],
        ],
        labels: {
          style: {
            colors: colors,
            fontSize: "12px",
          },
        },
      },
    },
  };
  return (
    <div className="card">
      <div className="pt-2">
        <Chart
          options={data.options}
          series={data.series}
          type="bar"
          width="100%"
          // height="100%"
          height={200}
        />
      </div>
    </div>
  );
};

export default OEELossChart;




