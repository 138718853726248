import { userRequest } from "../../api/requestMethods";
import {
  addDistributerFailure,
  addDistributerStart,
  addDistributerSuccess,
  deleteDistributerFailure,
  deleteDistributerStart,
  deleteDistributerSuccess,
  getDistributerFailure,
  getDistributerStart,
  getDistributerSuccess,
  updateDistributerFailure,
  updateDistributerStart,
  updateDistributerSuccess,
} from "../reducers/distributerReducer";

export const getDistributer = async (dispatch) => {
  dispatch(getDistributerStart());
  try {
    const res = await userRequest.get("/distributer/getDistributers");
    console.log("response from get distributer ==>", res);
    dispatch(getDistributerSuccess(res.data));
  } catch (error) {
    console.log("error from distributerFailure >>>", error);
    dispatch(getDistributerFailure());
  }
};

export const createDistributer = async (dispatch, inputData) => {
  dispatch(addDistributerStart());
  try {
    const res = await userRequest.post(
      "/distributer/createdistributers",
      inputData
    );
    console.log("response from add distributer ==>", res);
    dispatch(addDistributerSuccess(res.data));
  } catch (error) {
    console.log("error from distributerFailure >>>", error);
    dispatch(addDistributerFailure());
  }
};

export const updateDistributer = async (inputData, dispatch) => {
  console.log("inputData >>>", inputData);
  dispatch(updateDistributerStart());
  try {
    const res = await userRequest.post(
      "/distributer/updateDistributers",
      inputData
    );
    console.log("response from update distributer ==>", res);
    dispatch(updateDistributerSuccess(res.data));
  } catch (error) {
    console.log("error from distributerFailure >>>", error);
    dispatch(updateDistributerFailure());
  }
};

export const deleteDistributer = async (id, dispatch) => {
  dispatch(deleteDistributerStart());
  try {
    const res = await userRequest.post("/distributer/deleteDistributers", {
      id,
    });
    console.log("response from delete distributer ==>", res);
    dispatch(deleteDistributerSuccess(res.data));
  } catch (error) {
    console.log("error from distributerFailure >>>", error);
    dispatch(deleteDistributerFailure());
  }
};
