import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt from "jsonwebtoken";

import "./App.css";
import Login from "./pages/Auth/Login";
import Home from "./pages/Home/Home";
import { GlobalContext } from "./context/Context";

// import { Redirect } from "react-router-dom";
// toastify
import {
  // toast,
  ToastContainer,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMyData } from "./store/redux-apis/userApiCalls";

export var testToken = "";
function App() {
  const isUser = useSelector((state) => state.auth.curruntAuth);
  // const myData = useSelector((state) => state.user?.getMyData);
  // console.log("myData", myData);
  const [user, setUser] = useState(null);
  // console.log("user >>>>", user);
  const { state, dispatch } = useContext(GlobalContext);
  // console.log("state >>>>", state);
  // const [isLoading, setIsLoading] = useState(false);
  // console.log("isLoading >>>>", isLoading);
  // console.log("testToken >>>", testToken);
  const rDispatch = useDispatch();
  useEffect(() => {
    if (state.user?.id) {
      getMyData(rDispatch, state?.user?.id);
    }
  }, [dispatch, state]);

  let decoded;
  function decoder() {
    let token = localStorage.getItem("token");
    decoded = jwt.decode(token, { complete: true });

    if (decoded == null) {
      console.log("decoded null >>>");
    } else {
      let user = localStorage.getItem("username");
      // console.log("decoded ====>", decoded);
      // console.log("decoded data ==>", decoded.payload);
      localStorage.setItem("username", decoded.payload.username);
      return user;
    }
  }

  useEffect(() => {
    let token = localStorage.getItem("token");
    decoder();
    // console.log("decoded >>>", decoded);
    setUser(token);
    testToken = token;
    dispatch({
      type: "USER_LOGIN",
      payload: {
        id: decoded?.payload?.id,
        userName: decoded?.payload?.username,
        email: decoded?.payload?.email,
        userRole: decoded?.payload?.userRole,
        avatar: decoded?.payload?.avatar,
        token: token,
      },
    });
    // refreshPage();
    // <Redirect to="/" />;
  }, [isUser]);

  return (
    <>
      {/* <Login /> */}
      {user && user ? <Home /> : <Login />}
      {/* {isLoading && isLoading ? <Home /> : <Login />} */}

      {/* ToastContainer */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
  // return <> {user && user ? <Home /> : <Login />} </>;
  // return <> {state.user && state.user?.username ? <Home /> : <Login />} </>;
}

export default App;

// // get user device location
// navigator.geolocation.getCurrentPosition((position) => {
//   console.log(position.coords.latitude);
//   //latitude
//   console.log(position.coords.longitude);
//   //longitude
// });
// GENERATE_SOURCEMAP=false
