import React from "react";
import { forwardRef } from "react";
import "./ReactMaterialTable.css";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const ReactMaterialTable = ({ data, title }) => {
  // const data = filterOrders?.map((d) => ({ ...d }))
  const tableColumns = [
    { title: "Device Name", field: "deviceName" },
    { title: "Product Name", field: "skuName" },
    // { title: "Start Time", field: "startTime", type: "numeric" },
    { title: "Start Time", field: "plannedStart", },
    { title: "End Time", field: "plannedEnd" },
    { title: "Shift", field: "shiftName", lookup: { A: "A", B: "B", C: "C" }, },
  ];

  const dataColumns = [
    { device: "Denist2", skuName: "TANDOORI MASALA EXPORT", plannedStart: "12/15/2021 09:30:09", plannedEnd: "12/15/2021 14:59:52", shiftName: "A" },
    { device: "Denist2", skuName: "TANDOORI MASALA EXPORT", plannedStart: "12/15/2021 09:30:09", plannedEnd: "12/15/2021 14:59:52", shiftName: "A" },
    { device: "Denist2", skuName: "TANDOORI MASALA EXPORT", plannedStart: "12/15/2021 09:30:09", plannedEnd: "12/15/2021 14:59:52", shiftName: "A" },
    { device: "Denist2", skuName: "TANDOORI MASALA EXPORT", plannedStart: "12/15/2021 09:30:09", plannedEnd: "12/15/2021 14:59:52", shiftName: "A" },
    { device: "Denist2", skuName: "TANDOORI MASALA EXPORT", plannedStart: "12/15/2021 09:30:09", plannedEnd: "12/15/2021 14:59:52", shiftName: "A" },
    { device: "Denist2", skuName: "TANDOORI MASALA EXPORT", plannedStart: "12/15/2021 09:30:09", plannedEnd: "12/15/2021 14:59:52", shiftName: "A" },
  ];
  return (
    <div className="material-table__box">
      <MaterialTable
        className="resp-for-mobile card"
        icons={tableIcons}
        title={title ? title : "Orders"}
        // title="Product Details"
        columns={tableColumns}
        data={data ? data : dataColumns}
        options={{
          exportButton: true,
          exportCsv: (columns, data) => {
            alert(
              "You should develop a code to export " + data.length + " rows"
            );
          },
          // showTitle: false,
          filtering: true,
          search: false,
          pageSizeOptions: [5, 10, 20, { value: data ? data.length : dataColumns.length, label: 'All' }],
          // pageSizeOptions: [5, 10, 20],
          headerStyle: {
            // backgroundColor: "#04164C",
            // border: "1px solid ",
            // textAlign: "center",
            color: "#A1A5B7 ",
            borderBottom: "#E4E6EF",
            borderBottomWidth: "1px",
            borderBottomStyle: "dashed",
            fontWeight: '600',
            fontSize: "16px",
          },
        }}
      // actions={[
      //   {
      //     icon: "add",
      //     tooltip: "Add User",
      //     isFreeAction: true,
      //     onClick: (event) => alert("You want to add a new row"),
      //   },
      // ]}
      />
    </div>
  );
};

export default ReactMaterialTable;
