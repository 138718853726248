
import React from 'react';
import { Pie } from '@ant-design/plots';

const MachineStopChartReact = ({ data }) => {
    // const data = [
    //     {
    //         type: '分类一',
    //         value: 27,
    //     },
    //     {
    //         type: '分类二',
    //         value: 25,
    //     },
    //     {
    //         type: '分类三',
    //         value: 18,
    //     },
    //     {
    //         type: '分类四',
    //         value: 15,
    //     },
    //     {
    //         type: '分类五',
    //         value: 10,
    //     },
    //     {
    //         type: '其他',
    //         value: 5,
    //     },
    // ];
    const config = {
        appendPadding: 10,
        data,
        style: {
            textAlign: 'center',
            // fontSize: 12,
            fontSize: 10,
        },
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                // fontSize: 12,
                fontSize: 8,
                disply: "flex"
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '10px',
                    // fontSize: '12px',
                },
                // content: 'Machine Stops Count :' + data?.length,
                content: 'Count :' + data?.length,
            },
        },
    };
    return <Pie {...config} />;
};

export default MachineStopChartReact;

// import React from 'react';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Doughnut } from 'react-chartjs-2';

// ChartJS.register(ArcElement, Tooltip, Legend);

// export const data = {
//     labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//     datasets: [
//         {
//             label: '# of Votes',
//             data: [12, 19, 3, 5, 2, 3],
//             backgroundColor: [
//                 'rgba(255, 99, 132, 0.2)',
//                 'rgba(54, 162, 235, 0.2)',
//                 'rgba(255, 206, 86, 0.2)',
//                 'rgba(75, 192, 192, 0.2)',
//                 'rgba(153, 102, 255, 0.2)',
//                 'rgba(255, 159, 64, 0.2)',
//             ],
//             borderColor: [
//                 'rgba(255, 99, 132, 1)',
//                 'rgba(54, 162, 235, 1)',
//                 'rgba(255, 206, 86, 1)',
//                 'rgba(75, 192, 192, 1)',
//                 'rgba(153, 102, 255, 1)',
//                 'rgba(255, 159, 64, 1)',
//             ],
//             borderWidth: 1,
//         },
//     ],
// };

// export default function MachineStopChartReact() {
//     return (<div style={{ width: "70%", height: "60%" }}>

//         <Doughnut data={data} />
//     </div>)
// }

