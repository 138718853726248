import React from "react";
import { OverAllComp, GuageComp } from "../Charts/OverAllOee/OverAllOee";
import { Gauge } from '@ant-design/plots';

const GuageStyle = {
  // width: "auto",
  // height: "75px",
  width: "140px",
  height: "100px",
  borderRadius: "10px",
  padding: 10,
}
const GuageStyle2 = {
  width: "150px",
  height: "95px",
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 10,
  paddingRight: 10,

}

const GuageStyleParent = {
  background: "#ffffff9b",
  // borderRadius: "10px",
  borderRadius: "60px 60px 15px 15px",
  // padding: "2px 10px",
  color: "#000",
  fontWeight: "bold",
}

export const GuageCompChild = ({ value, color }) => {
  // const config = {
  //   percent: value / 100,
  //   radius: value / 100,
  //   range: {
  //     color: color,
  //     width: 12,
  //   },
  //   indicator: {
  //     pointer: {
  //       style: {
  //         stroke: '#9a9898',
  //         color: '#000'

  //       },
  //     },
  //     pin: {
  //       style: {
  //         stroke: '#9a9898',
  //         color: '#000'

  //       },
  //     },
  //   },
  //   statistic: {
  //     content: {
  //       formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
  //       style: {
  //         marginTop: '10px',
  //         fontSize: '12px',
  //         lineHeight: '15px',
  //         color: '#000'

  //       },
  //     },
  //     style: {
  //       fontSize: 12,
  //       color: '#000'

  //     },
  //   },
  //   gaugeStyle: {
  //     lineCap: 'round',
  //     color: '#000'

  //   },
  // };
  const config = {
    percent: value / 100,
    range: {
      color: color,
      width: 12,
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    axis: {
      label: {
        formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    statistic: {
      content: {
        formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          color: '#000',
          fontSize: 12,
          marginTop: '10px',
        },
      },
    },
    gaugeStyle: {
      lineCap: 'round',
      color: '#000'
    },
  }
  return <div
    className="p-0 m-0 mb-2 my-2"
    style={GuageStyle2}>
    <Gauge {...config} />
  </div>;
};


const WidgetOne = ({ value, bg, icon, title, desc, subTitleOne, subTitleOneValue, subTitleTwo, subTitleTwoValue, subTitleThree, subTitleThreeValue }) => {


  return (
    <a href className={`text-white card hoverable card-xl-stretch mb-xl-8 ${bg}`}>
      {/*begin::Body*/}
      <div className="card-body">
        <div className="d-flex justify-content-between w-100">
          <div className="text-white text-gray-800 fw-bolder fs-2 ">{title}</div>
          <div className="fw-bolder text-white text-gray-800 d-flex justify-content-center align-items-center">{desc || 0}</div>
        </div>

        <div className="d-flex justify-content-between w-100">
          <div className="fw-bolder text-gray-800">{subTitleOne}</div>
          <div className="fw-bolder text-gray-800 text-white text-gray-800">{subTitleOneValue || 0}</div>
        </div>
        <div className="d-flex justify-content-between w-100">
          <div className="fw-bolder text-gray-800">{subTitleTwo}</div>
          <div className="fw-bolder text-gray-800 text-white text-gray-800">{subTitleTwoValue || 0}</div>
        </div>
        {subTitleThree &&
          <div className="d-flex justify-content-between w-100">
            <div className="fw-bolder text-gray-800">{subTitleThree}</div>
            <div className="fw-bolder text-white text-gray-800">{subTitleThreeValue || 0}</div>
          </div>
        }
      </div>
      {/*end::Body*/}
      {/* begin::Svg Icon | path: icons/duotune/ecommerce/ecm002.svg*/}
      <div className="d-flex justify-content-center align-items-center rounded-5 card" style={{ backgroundColor: "#fff" }}>
        {title === "Over All" && value !== 0 ?
          <div className="d-flex justify-content-center align-items-center" style={GuageStyleParent}>
            <div className="p-0 m-0 mb-2 my-2" style={GuageStyle}>
              <OverAllComp value={value} />
            </div>
          </div>
          : null}
        {title === "Performance" && value !== 0 ?
          <div className="d-flex justify-content-center align-items-center" >
            <div className="p-0 m-0 mb-2 my-2" style={GuageStyle}>
              <GuageComp value={value} color='l(0) 0:#f8dea9 1:#FAAD14' />
              {/* <GuageCompChild value={100} color='l(0) 0:#f8dea9 1:#FAAD14' /> */}
            </div>
          </div>
          : null}
        {title === "Availablity" && value !== 0 ?
          <div className="d-flex justify-content-center align-items-center" >
            <div className="p-0 m-0 mb-2 my-2" style={GuageStyle}>
              <GuageComp value={value} color='l(0) 0:#B8E1FF 1:#3D76DD' />
            </div>
          </div>
          : null}
        {title === "Quality" && value !== 0 ?
          <div className="d-flex justify-content-center align-items-center" >
            <div className="p-0 m-0 mb-2 my-2" style={GuageStyle}>
              <GuageComp value={value} color='l(0) 0:#baf7d9 1:#30BF78' />
            </div>
          </div>
          : null}
      </div>

      {/* {/*end::Svg Icon */}
    </a>
  );
};

export default WidgetOne;
