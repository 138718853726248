import { userRequest } from "../../api/requestMethods";
import {
  getCurruntProductionViewFailure,
  getCurruntProductionViewStart,
  getCurruntProductionViewSuccess,
  getcurruntStatusFailure,
  getcurruntStatusStart,
  getcurruntStatusSuccess,
} from "../reducers/curruntStatusReducer";

export const getCurruntStatus = async (dispatch) => {
  dispatch(getcurruntStatusStart());
  try {
    const res = await userRequest.get("curruntStatus/getCurruntStatus");
    // console.log("response from get CurruntStatus ==>", res);
    dispatch(getcurruntStatusSuccess(res.data));
  } catch (error) {
    console.log("error from CurruntStatusFailure >>>", error);
    dispatch(getcurruntStatusFailure());
  }
};

export const handleInsertCompleteOrdersAndStops = async (dispatch) => {
  dispatch(getcurruntStatusStart());
  try {
    const res = await userRequest.get(
      "deviceData/getDeviceDataAndEditCompleteOrdersAndInsertStops"
    );
    console.log("response from get CurruntStatus ==>", res);
    dispatch(getcurruntStatusSuccess());
  } catch (error) {
    console.log("error from CurruntStatusFailure >>>", error);
    dispatch(getcurruntStatusFailure());
  }
};

export const getCurruntProductionView = async (dispatch) => {
  dispatch(getCurruntProductionViewStart());
  try {
    const res = await userRequest.get("completeOrders/getCompleteOrders");
    // console.log("response from get getCurruntProductionView ==>", res);
    dispatch(getCurruntProductionViewSuccess(res.data));
  } catch (error) {
    console.log("error from CurruntProductionViewFailure >>>", error);
    dispatch(getCurruntProductionViewFailure());
  }
};
