import { createSlice } from "@reduxjs/toolkit";

const completeOrderSlice = createSlice({
  name: "completeOrder",
  initialState: {
    completeOrder: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    getCompleteOrderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getCompleteOrderSuccess: (state, action) => {
      state.isFetching = false;
      state.completeOrder = action.payload;
    },
    getCompleteOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE CompleteOrder
    deleteCompleteOrderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteCompleteOrderSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteCompleteOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE CompleteOrder
    updateCompleteOrderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateCompleteOrderSuccess: (state, action) => {
      state.isFetching = false;
      console.log("action.payload >>>", action.payload);
    },
    updateCompleteOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD CompleteOrder
    addCompleteOrderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addCompleteOrderSuccess: (state, action) => {
      state.isFetching = false;
      state.completeOrder.push(action.payload);
    },
    addCompleteOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getCompleteOrderFailure,
  getCompleteOrderStart,
  getCompleteOrderSuccess,
  addCompleteOrderFailure,
  addCompleteOrderStart,
  addCompleteOrderSuccess,
  deleteCompleteOrderFailure,
  deleteCompleteOrderStart,
  deleteCompleteOrderSuccess,
  updateCompleteOrderFailure,
  updateCompleteOrderStart,
  updateCompleteOrderSuccess,
} = completeOrderSlice.actions;

export default completeOrderSlice.reducer;
