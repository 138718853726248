import { createSlice } from "@reduxjs/toolkit";

const curruntStatusSlice = createSlice({
  name: "curruntStatus",
  initialState: {
    curruntData: {
      curruntStatus: [],
      curruntProductionView: [],
    },
    isFetching: false,
    error: false,
  },
  reducers: {
    getcurruntStatusStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getcurruntStatusSuccess: (state, action) => {
      state.isFetching = false;
      state.curruntData.curruntStatus = action.payload;
    },
    getcurruntStatusFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    getCurruntProductionViewStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getCurruntProductionViewSuccess: (state, action) => {
      state.isFetching = false;
      state.curruntData.curruntProductionView = action.payload;
    },
    getCurruntProductionViewFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getcurruntStatusFailure,
  getcurruntStatusStart,
  getcurruntStatusSuccess,
  getCurruntProductionViewStart,
  getCurruntProductionViewSuccess,
  getCurruntProductionViewFailure,
} = curruntStatusSlice.actions;

export default curruntStatusSlice.reducer;
