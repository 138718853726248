import React, { useEffect, useState } from "react";
import {
  Button,
  // Dropdown,
  // Form,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import MaterialTable from "material-table";
import ToolBar from "../../components/ToolBar/ToolBar";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  deleteUser,
  getUsers,
  updateUser,
} from "../../store/redux-apis/userApiCalls";

const Users = () => {
  const allUsers = useSelector((state) => state.user?.users?.data);
  console.log("allUsers >>>", allUsers);
  const allUsersDataForMT = allUsers?.map((d) => ({ ...d }));

  const [refresh, setRefresh] = useState(1);
  const [show, setShow] = useState(false);
  // const [userRole, setUserRole] = useState("user");
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    userRole: "",
    password: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    getUsers(dispatch);
  }, [dispatch, refresh]);

  // console.log(userData);

  // const handleChange = (e) => {
  //   console.log(e.target.name, e.target.value);
  //   setInputs((prev) => {
  //     return { ...prev, [e.target.name]: e.target.value };
  //   });
  // };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const tableColumns = [
    { title: "Name", field: "username" },
    { title: "Email", field: "email" },
    // { title: "Password", field: "password" },
    {
      title: "User Role",
      field: "userRole",
      lookup: { Admin: "Admin", "superUser": "superUser", User: "User" },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handleSubmit running");
    // const data = {
    //   username: userData.username,
    //   email: userData.email,
    //   password: userData.password,
    //   userRole: userRole,
    // };
    if (
      userData &&
      userData.username &&
      userData.email &&
      userData.password &&
      userData.userRole
    ) {
      // console.log("userData >>>", data);
      // if (data.username && data.email && data.password && data.userRole) {
      console.log("userData >>>", userData);
      console.log("condition passed");
      // createUser(dispatch, data);
      createUser(dispatch, userData);
      setRefresh((preValue) => !preValue);
      getUsers(dispatch);
      handleClose();
      setUserData({ username: "", email: "", userRole: "", password: "" });
    }
  };

  return (
    <>
      {/* begin: Toolbar */}
      <ToolBar />
      {/* end: Toolbar */}
      <div className="content d-flex flex-column flex-column-fluid">
        {/*begin::Container*/}
        <div className="container-xxl">
          {/*begin::Row*/}
          <div className="card">
            <div className="card-header border-0 pt-6 d-flex justify-content-between">
              {/*begin::Card title*/}
              <div className="card-title">
                {/*begin::Search*/}
                <div className="d-flex align-items-center position-relative my-1">
                  {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}

                </div>
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Toolbar*/}
                <div className="d-flex justify-content-end">
                  {/*begin::Add user*/}
                  <Button
                    variant="primary"
                    className="btn btn-primary"
                    onClick={handleShow}
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="11.364"
                          y="20.364"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11.364 20.364)"
                          fill="currentColor"
                        />
                        <rect
                          x="4.36396"
                          y="11.364"
                          width={16}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}Add User
                  </Button>

                  {/*end::Add user*/}
                </div>
                {/*end::Toolbar*/}
                {/*begin::Modal - Add task*/}
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  {/*begin::Modal body*/}
                  <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
                    {/*begin::Form*/}
                    <form
                      className="form fv-plugins-bootstrap5 fv-plugins-framework"
                      onSubmit={handleSubmit}
                    >
                      {/*begin::Scroll*/}
                      <div
                        className="d-flex flex-column scroll-y me-n7 pe-7"
                        style={{ maxHeight: "422px" }}
                      >
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Full Name
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="name"
                            name="username"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="Full name"
                            // onChange={(e) => handleChange(e)}
                            value={userData.username}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                username: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Email
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="email"
                            name="email"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="example@domain.com"
                            // onChange={(e) => handleChange(e)}
                            value={userData.email}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                email: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Passowrd
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="password"
                            name="password"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="*******"
                            // onChange={(e) => handleChange(e)}
                            value={userData.password}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                password: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="mb-7">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-5">
                            Role
                          </label>
                          {/*end::Label*/}
                          {/*begin::Roles*/}
                          {/* <Form.Select
                            aria-label="Default select example"
                            value={userData.userRole}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                userRole: e.target.value,
                              })
                            }
                          >
                            <option>User</option>
                            <option>superUser</option>
                            <option>Admin</option>
                          </Form.Select> */}
                          <div className="d-flex fv-row">
                            <ToggleButtonGroup
                              type="radio"
                              name="options"
                              className="w-100"
                            >
                              <ToggleButton
                                className="bg-light-primary text-dark text-active-white text-hover-white btn-active-text-white "
                                id="tbg-radio-1"
                                type="radio"
                                value={1}
                                // onChange={() => setUserRole("user")}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    userRole: "User",
                                  })
                                }
                              >
                                User
                              </ToggleButton>
                              <ToggleButton
                                className="bg-light-primary text-dark text-active-white text-hover-white btn-active-text-white "
                                id="tbg-radio-2"
                                type="radio"
                                value={2}
                                // onChange={() => setUserRole("superUser")}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    userRole: "superUser",
                                  })
                                }
                              >
                                Super User
                              </ToggleButton>
                              <ToggleButton
                                className="bg-light-primary text-dark text-active-white text-hover-white btn-active-text-white "
                                id="tbg-radio-3"
                                type="radio"
                                value={3}
                                // onChange={() => setUserRole("admin")}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    userRole: "Admin",
                                  })
                                }
                              >
                                Admin
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </div>
                          {/*end::Roles*/}
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Scroll*/}
                      {/*begin::Actions*/}
                      <div className="text-center pt-15">
                        <button
                          type="reset"
                          className="btn btn-light me-3"
                          onClick={handleClose}
                        >
                          Discard
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                        // onClick={handleClose}
                        >
                          <span className="indicator-label">Submit</span>
                          {/* <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span> */}
                        </button>
                      </div>
                      {/*end::Actions*/}
                      <div />
                    </form>
                    {/*end::Form*/}
                  </Modal.Body>
                  {/*end::Modal body*/}
                </Modal>
                {/*end::Modal - Add task*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            <div className="material-table__box">
              <MaterialTable
                style={{
                  zIndex: 0
                }}
                // title="User Details"
                columns={tableColumns}
                // data={tableRows}
                data={allUsersDataForMT}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...allUsersDataForMT];
                        const index = oldData.id;
                        console.log("index >>>", index);
                        dataUpdate[index] = newData;
                        updateUser(dataUpdate[index], dispatch);
                        setRefresh((preValue) => !preValue);
                        resolve();
                      }, 1000);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const index = oldData.id;
                        console.log("index >>>", index);
                        // alert("You want to delete " + oldData.deviceName);
                        deleteUser(index, dispatch);
                        setRefresh((preValue) => !preValue);
                        resolve();
                      }, 1000);
                    }),
                }}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: false,
                  exportCsv: (columns, data) => {
                    alert(
                      "You should develop a code to export " +
                      data.length +
                      " rows"
                    );
                  },
                  showTitle: false,
                  filtering: true,
                  search: false,
                  toolbar: false,
                  pageSizeOptions: [
                    5,
                    10,
                    20,
                    { value: allUsersDataForMT?.length, label: "All" },
                  ],
                  // pageSizeOptions: [5, 10, 20],
                  headerStyle: {
                    color: "#A1A5B7 ",
                    borderBottom: "#E4E6EF",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dashed",
                    fontWeight: "600",
                    fontSize: "16px",
                  },
                }}
              />
            </div>
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Container*/}
      </div>
    </>
  );
};

export default Users;
