import React, { useEffect, useState } from "react";
import {
  // Button, 
  Modal
} from "react-bootstrap";
import MaterialTable from "material-table";
// React Redux
import { useDispatch, useSelector } from "react-redux";
//Moment
import moment from "moment";

// DateTimePickerTabs
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getShifts } from "../../store/redux-apis/shiftApiCalls";
import { getLines } from "../../store/redux-apis/lineApiCalls";
import { getStops } from "../../store/redux-apis/stopsApiCalls";
import './StopsDetails.css'

const StopDetails = () => {

  const shiftsData = useSelector((state) => state.shift.shifts.data);
  // console.log("shiftsData >>>", shiftsData);
  const linesData = useSelector((state) => state.line.lines?.data);
  // console.log("lineData >>>", linesData);
  const stopsDataRedux = useSelector((state) => state.stop.stops?.data);
  console.log("stops data  >>>", stopsDataRedux);
  const stopsData = stopsDataRedux?.map((o) => ({ ...o }));

  const dispatch = useDispatch();
  useEffect(() => {
    getShifts(dispatch);
    getLines(dispatch);
    getStops(dispatch)
  }, [dispatch]);

  // For Date FIlter States
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  // For Refresh 
  // const [refresh, setRefresh] = useState(1);
  // For Modals
  const [showShift, setShowShift] = useState(false);
  const [showMachine, setShowMachine] = useState(false);
  // shift state
  const [selectedShift, setSelectedShift] = useState([])
  // Machine/Device state
  const [selectedDevice, setSelectedDevice] = useState([])
  // total stops and downtime
  const [totalStops, setTotalStops] = useState(0);
  const [totalDowntime, setTotalDowntime] = useState(0);

  // Filter Stops
  const [filterStops, setFilterStops] = useState([]);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // For Filter date functions
  const handleChangeOne = (newValue) => setStartDate(newValue);
  const handleChangeTwo = (newValue) => setEndDate(newValue);

  const handleCloseShift = () => setShowShift(false);
  const handleShowShift = () => setShowShift(true);

  const handleCloseMachine = () => setShowMachine(false);
  const handleShowMachine = () => setShowMachine(true);

  const handleSetShiftName = (e) => {
    if (
      e.target.checked &&
      !selectedShift.includes(e.target.value)
    ) {
      setSelectedShift((preValue) => [...preValue, e.target.value]);
    } else if (!e.target.checked) {
      setSelectedShift(selectedShift.filter((device) => device !== e.target.value))
    }
  }
  const handleShift = (e) => {
    e.preventDefault();

    console.log("selectedShift >>>", selectedShift);

    handleCloseShift();
  }

  const handleSetDeviceName = (e) => {
    if (
      e.target.checked &&
      !selectedDevice.includes(e.target.value)
    ) {
      setSelectedDevice((preValue) => [...preValue, e.target.value]);
    } else if (!e.target.checked) {
      setSelectedDevice(selectedDevice.filter((device) => device !== e.target.value))
    }
  }

  const handleMachine = async (e) => {
    e.preventDefault();

    console.log("selectedDevice >>>", selectedDevice);
    let filteredStops =
      moment().format("MM/DD/YYYY") == moment(startDate).format("MM/DD/YYYY") &&
        moment().format("MM/DD/YYYY") == moment(endDate).format("MM/DD/YYYY")
        ? stopsData.filter(
          (stop) =>
            (stop.startTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
              stop.endTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
              stop.startTime.split("T")[0] == moment(endDate).format("YYYY-MM-DD") ||
              stop.endTime.split("T")[0] == moment(endDate).format("MM/DD/YYYY") ||
              (moment(stop.startTime.split("T")[0]).isBefore(moment(moment(endDate).format("YYYY-MM-DD"))) &&
                moment(stop.endTime.split("T")[0]).isAfter(moment(moment(startDate).format("YYYY-MM-DD"))))) &&
            selectedDevice.includes(stop.childDeviceName)
        )
        : stopsData.filter(
          (stop) =>
            (stop.startTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
              stop.endTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
              stop.startTime.split("T")[0] == moment(endDate).format("YYYY-MM-DD") ||
              stop.endTime.split("T")[0] == moment(endDate).format("YYYY-MM-DD") ||
              (moment(stop.startTime.split("T")[0]).isBefore(moment(moment(endDate).format("YYYY-MM-DD"))) &&
                moment(stop.endTime.split("T")[0]).isAfter(moment(moment(startDate).format("YYYY-MM-DD"))))) &&
            selectedDevice.includes(stop.childDeviceName)
        );
    // console.log("filteredStops >>>", filteredStops);
    let downtime = filteredStops.map((f) => f.downTime);
    // console.log("downtime filtered >>>", downtime);
    let totalTime = downtime.reduce((a, b) => a + b, 0);
    // console.log("totalTime filtered >>>", totalTime);
    setTotalStops(filteredStops.length);
    setTotalDowntime(totalTime);
    setFilterStops(filteredStops);

    handleCloseMachine();
  }


  const handleSearch = async () => {
    console.log("handle search >>>");
    let filteredStops =
      moment().format("MM/DD/YYYY") == moment(startDate).format("MM/DD/YYYY") &&
        moment().format("MM/DD/YYYY") == moment(endDate).format("MM/DD/YYYY")
        ? stopsData?.filter(
          (stop) =>
            (stop.startTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
              stop.endTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
              stop.startTime.split("T")[0] == moment(endDate).format("YYYY-MM-DD") ||
              stop.endTime.split("T")[0] == moment(endDate).format("MM/DD/YYYY") ||
              (moment(stop.startTime.split("T")[0]).isBefore(moment(moment(endDate).format("YYYY-MM-DD"))) &&
                moment(stop.endTime.split("T")[0]).isAfter(moment(moment(startDate).format("YYYY-MM-DD"))))) &&
            selectedShift.includes(stop.shiftName)
        )
        : stopsData?.filter(
          (stop) =>
            (stop.startTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
              stop.endTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
              stop.startTime.split("T")[0] == moment(endDate).format("YYYY-MM-DD") ||
              stop.endTime.split("T")[0] == moment(endDate).format("YYYY-MM-DD") ||
              (moment(stop.startTime.split("T")[0]).isBefore(moment(moment(endDate).format("YYYY-MM-DD"))) &&
                moment(stop.endTime.split("T")[0]).isAfter(moment(moment(startDate).format("YYYY-MM-DD"))))) &&
            selectedShift.includes(stop.shiftName)
        );
    // console.log("filteredStops >>>", filteredStops);
    let downtime = filteredStops.map((f) => f.downTime);
    // console.log("downtime filtered >>>", downtime);
    let totalTime = downtime.reduce((a, b) => a + b, 0);
    // console.log("totalTime filtered >>>", totalTime);
    setTotalStops(filteredStops.length);
    setTotalDowntime(totalTime);
    setFilterStops(filteredStops);

  };

  const handleClearFilter = () => {
    console.log("clear filter called");
    setFilterStops([]);
    setStartDate(moment());
    setEndDate(moment());
    setTotalStops(0);
    setTotalDowntime(0);
    setSelectedDevice([])
    setSelectedShift([])
  }

  // useEffect(() => {
  //   if (stopsDataRedux && stopsDataRedux?.length > 0) {
  //     setFilterStops(stopsData)
  //   }
  // }, [stopsDataRedux])


  return (
    <>
      {/* <ToolBar /> */}
      <div className="toolbar py-2">
        {/*begin::Container*/}
        <div className="container-fluid d-flex align-items-center flex-wrap mx-1">
          <div className="row w-100">
            <div className="col-sm-4 px-0">
              <div className="page-title d-flex align-items-center flex-wrap me-3 mb-lg-0 h-100">
                {/*begin::Title*/}
                <div className="h2 d-flex flex-wrap align-items-center text-dark fw-bolder my-1 fs-3 h-100 fs-xs-12">
                  STOP DETAILS
                  {/*begin::Separator*/}
                  <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                  {/*end::Separator*/}
                  {/*begin::Description*/}
                  {/* <small className="text-muted fs-7 fw-bold my-1 ms-1">
                    #Description
                  </small> */}
                  {/*end::Description*/}
                </div>
                {/*end::Title*/}
              </div>
            </div>
            {/* <div className="w-75"> */}
            {/*begin::Action group*/}
            <div className="col-sm-8 px-0">
              {/*begin::Wrapper*/}
              <ul className="nav d-flex align-items-center w-100">
                <div className="row w-100">
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 py-2">
                    <div className="d-flex justify-content-between align-items-center h-100">
                      {/*begin::Daterangepicker*/}
                      <li className="nav-item px-1 w-50">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <DatePicker
                              disableFuture
                              label="Start Date"
                              openTo="day"
                              // views={['year', 'month', 'day']}
                              value={startDate}
                              onChange={handleChangeOne}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </li>
                      <li className="nav-item px-1 w-50">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <DatePicker
                              disableFuture
                              label="End Date"
                              // inputFormat="MM/dd/yyyy"
                              openTo="day"
                              value={endDate}
                              onChange={handleChangeTwo}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </li>
                      {/*end::Daterangepicker*/}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 py-2">
                    <div className="row d-flex align-items-center justify-content-between">

                      <div className="col-lg-8 col-md-8 col-sm-8 col-8 py-2">
                        <div className="d-flex align-items-center justify-content-around w-100">
                          <li className="nav-item px-1">
                            <a
                              className="btn btn-sm btn-primary  cursor-pointer"
                              onClick={handleShowShift}
                            >
                              Select Shift
                            </a>
                            {/*begin::Modal - Add task*/}
                            <Modal show={showShift} onHide={handleCloseShift}>
                              {/*begin::Modal body*/}
                              <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
                                {/*begin::Form*/}
                                <form
                                  className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                  onSubmit={handleShift}
                                >
                                  {/*begin::Scroll*/}
                                  <div
                                    className="d-flex flex-column scroll-y me-n7 pe-7"
                                  // style={{ maxHeight: "422px" }}
                                  >
                                    <div className="row g-9 mb-8">
                                      {/*begin::Col*/}
                                      <div className="col-md-12 fv-row">
                                        <div className="d-flex justify-content-around align-items-center">

                                          <label className="required fs-6 fw-bold mb-2">
                                            Shift Name
                                          </label>
                                          {shiftsData?.map((shift) => (
                                            <div className="form-check form-control-color">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={shift.shiftName}
                                                name={shift.shiftName}
                                                value={shift.shiftName}
                                                onChange={(e) => handleSetShiftName(e)}
                                              />
                                              <label htmlFor={shift.shiftName}>{shift.shiftName}</label>
                                            </div>
                                          ))}

                                        </div>
                                      </div>
                                      {/*end::Col*/}
                                    </div>

                                  </div>
                                  {/*end::Scroll*/}
                                  {/*begin::Actions*/}
                                  <div className="text-center pt-15">
                                    <button
                                      type="reset"
                                      className="btn btn-light me-3"
                                      onClick={handleCloseShift}
                                    >
                                      Discard
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      <span className="indicator-label">Add</span>
                                      {/* <span className="indicator-progress">
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                      </span> */}
                                    </button>
                                  </div>
                                  {/*end::Actions*/}
                                  <div />
                                </form>
                                {/*end::Form*/}
                              </Modal.Body>
                              {/*end::Modal body*/}
                            </Modal>
                            {/*end::Modal - Add task*/}
                          </li>
                          <li className="nav-item px-1">
                            <a
                              className="btn btn-sm btn-primary cursor-pointer"
                              onClick={handleShowMachine}
                            >
                              {/* Select Machine */}
                              View All Stops
                            </a>
                            {/*begin::Modal - Add task*/}
                            <Modal show={showMachine} onHide={handleCloseMachine}>
                              {/*begin::Modal body*/}
                              <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
                                {/*begin::Form*/}
                                <form
                                  className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                  onSubmit={handleMachine}
                                >
                                  {/*begin::Scroll*/}
                                  <div
                                    className="d-flex flex-column scroll-y me-n7 pe-7"
                                  // style={{ maxHeight: "422px" }}
                                  >
                                    <div className="row g-9 mb-8">
                                      {/*begin::Col*/}
                                      <div className="col-md-12 fv-row">
                                        <div className="d-flex justify-content-around align-items-center">

                                          <label className="required fs-6 fw-bold mb-2">
                                            Machine Name
                                          </label>
                                          {linesData?.map((device) => (
                                            <div className="form-check form-control-color">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={device.deviceName}
                                                name={device.deviceName}
                                                value={device.deviceName}
                                                onChange={(e) => handleSetDeviceName(e)}
                                              />
                                              <label htmlFor={device.deviceName}>{device.deviceName}</label>
                                            </div>
                                          ))}

                                        </div>
                                      </div>
                                      {/*end::Col*/}
                                    </div>

                                  </div>
                                  {/*end::Scroll*/}
                                  {/*begin::Actions*/}
                                  <div className="text-center pt-15">
                                    <button
                                      type="reset"
                                      className="btn btn-light me-3"
                                      onClick={handleCloseMachine}
                                    >
                                      Discard
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      <span className="indicator-label">Add</span>
                                      {/* <span className="indicator-progress">
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                      </span> */}
                                    </button>
                                  </div>
                                  {/*end::Actions*/}
                                  <div />
                                </form>
                                {/*end::Form*/}
                              </Modal.Body>
                              {/*end::Modal body*/}
                            </Modal>
                            {/*end::Modal - Add task*/}
                          </li>

                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4 py-2">
                        <div className="d-flex align-items-center justify-content-around w-100">
                          {/*begin::Actions*/}

                          <li className="nav-item px-1">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary cursor-pointer mx-1"
                              onClick={handleSearch}
                            >
                              <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                          </li>
                          <li className="nav-item px-1">
                            <button
                              type="reset"
                              className="btn btn-sm btn-danger cursor-pointer mx-1"
                              onClick={handleClearFilter}
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </button>
                          </li>

                          {/*end::Actions*/}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </ul>
              {/*end::Wrapper*/}

            </div>
            {/*end::Action group*/}
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/* end: Toolbar */}
      {
        filterStops && filterStops.length > 0 ?
          <>
            <div className="content d-flex flex-column flex-column-fluid">
              {/*begin::Container*/}
              <div className="container-xxl">
                {/*begin::Row*/}
                <div className="card">
                  <div className="card-header border-0 pt-6 d-flex justify-content-evenly">
                    {/*begin::Card title*/}
                    <div className="card-title ">
                      {/*begin::Total Stops*/}
                      <div className="d-flex align-items-center justify-content-center my-1">
                        <div className="card total-cards text-center">
                          <div className="p-0 m-0 fw-bolder">
                            Total Stops:
                          </div>
                          <div className="p-0 m-0">
                            {totalStops}
                          </div>
                        </div>
                      </div>
                      {/*end::Total Stops*/}
                    </div>
                    {/*begin::Card title*/}
                    {/*begin::Card title*/}
                    <div className="card-title">
                      {/*begin::Total DownTime*/}
                      <div className="d-flex align-items-center justify-content-center my-1">
                        <div className="card total-cards text-center">
                          <div className="p-0 m-0 fw-bolder">
                            Total DownTime:
                          </div>
                          <div className="p-0 m-0">
                            {(totalDowntime / 60).toFixed(0)} Mins
                          </div>
                        </div>
                      </div>
                      {/*end::Total DownTime*/}
                    </div>
                    {/*begin::Card title*/}
                  </div>
                  <div className="material-table__box">
                    <MaterialTable
                      style={{
                        zIndex: 0
                      }}
                      // title="StopDetails Details"
                      columns={[
                        // { title: "Device Name", field: "deviceName", editable: "never" },
                        { title: "Device Name", field: "childDeviceName", editable: "never" },
                        { title: "Start Time", field: "startTime", editable: "never" },
                        { title: "End Time", field: "endTime", editable: "never" },
                        { title: "DownTime", field: "downTime", },
                        { title: "Category", field: "categoryName", },
                        { title: "Sub Category", field: "subCategoryName", },
                        { title: "Cause One", field: "causeOne", },
                        { title: "Cause Two", field: "causeTwo", },
                        { title: "Cause Three", field: "causeThree", },
                        { title: "SKU", field: "skuName", editable: "never" },
                        { title: "Shift", field: "shiftName", editable: "never" },
                        { title: "Stops Update Time", field: "stopUpdatedTime", },
                        { title: "Stops Update By", field: "stopUpdatedBy", },
                      ]}
                      data={filterStops ? filterStops : []}
                      // data={[]}
                      options={{
                        // actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                        exportButton: true,
                        exportCsv: (columns, data) => {
                          alert(
                            "You should develop a code to export " +
                            data.length +
                            " rows"
                          );
                        },
                        showTitle: false,
                        filtering: true,
                        pageSizeOptions: [
                          5,
                          10,
                          20,
                          // { value: filterStops?.length, label: "All" },
                        ],
                        // pageSizeOptions: [5, 10, 20],
                        headerStyle: {
                          color: "#A1A5B7 ",
                          borderBottom: "#E4E6EF",
                          borderBottomWidth: "1px",
                          borderBottomStyle: "dashed",
                          fontWeight: "600",
                          fontSize: "16px",
                        },
                      }}
                    />
                  </div>
                </div>
                {/*end::Row*/}
              </div>
              {/*end::Container*/}
            </div>
          </>
          :
          // <div className="d-flex align-items-center justify-content-center" style={{ height: '90vh' }}>
          //   <h1>
          //     Please select Filter
          //   </h1>
          // </div>
          <div className="content d-flex flex-column flex-column-fluid">
            {/*begin::Container*/}
            <div className="container-xxl">
              {/*begin::Row*/}
              <div className="card">
                <div className="material-table__box">
                  <MaterialTable
                    style={{
                      zIndex: 0
                    }}
                    // title="StopDetails Details"
                    columns={[
                      // { title: "Device Name", field: "deviceName", editable: "never" },
                      { title: "Device Name", field: "childDeviceName", editable: "never" },
                      { title: "Start Time", field: "startTime", editable: "never" },
                      { title: "End Time", field: "endTime", editable: "never" },
                      { title: "DownTime", field: "downTime", },
                      { title: "Category", field: "categoryName", },
                      { title: "Sub Category", field: "subCategoryName", },
                      { title: "Cause One", field: "causeOne", },
                      { title: "Cause Two", field: "causeTwo", },
                      { title: "Cause Three", field: "causeThree", },
                      { title: "SKU", field: "skuName", editable: "never" },
                      { title: "Shift", field: "shiftName", editable: "never" },
                      { title: "Stops Update Time", field: "stopUpdatedTime", },
                      { title: "Stops Update By", field: "stopUpdatedBy", },
                    ]}
                    data={stopsData ? stopsData : []}
                    options={{
                      search: false,
                      toolbar: false,
                      exportButton: true,
                      exportCsv: (columns, data) => {
                        alert(
                          "You should develop a code to export " +
                          data.length +
                          " rows"
                        );
                      },
                      showTitle: false,
                      filtering: true,
                      pageSizeOptions: [
                        5,
                        10,
                        20,
                        { value: stopsData?.length, label: "All" },
                      ],
                      headerStyle: {
                        color: "#A1A5B7 ",
                        borderBottom: "#E4E6EF",
                        borderBottomWidth: "1px",
                        borderBottomStyle: "dashed",
                        fontWeight: "600",
                        fontSize: "16px",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default StopDetails;
