import { userRequest } from "../../api/requestMethods";
import {
  addLineFailure,
  addLineStart,
  addLineSuccess,
  deleteLineFailure,
  deleteLineStart,
  deleteLineSuccess,
  getLineFailure,
  getLineStart,
  getLineSuccess,
  updateLineFailure,
  updateLineStart,
  updateLineSuccess,
} from "../reducers/lineReducer";

export const getLines = async (dispatch) => {
  dispatch(getLineStart());
  try {
    const res = await userRequest.get("/line/getLine");
    // console.log("response from get Lines ==>", res);
    dispatch(getLineSuccess(res.data));
  } catch (error) {
    console.log("error from lineFailure >>>", error);
    dispatch(getLineFailure());
  }
};

export const createLines = async (dispatch, inputData) => {
  dispatch(addLineStart());
  try {
    const res = await userRequest.post("line/createLines", inputData);
    // console.log("response from add Lines ==>", res);
    dispatch(addLineSuccess(res.data));
  } catch (error) {
    console.log("error from lineFailure >>>", error);
    dispatch(addLineFailure());
  }
};

export const updateLines = async (inputData, dispatch) => {
  console.log("inputData >>>", inputData);
  dispatch(updateLineStart());
  try {
    const res = await userRequest.post("/line/updateLine", inputData);
    // console.log("response from update Lines ==>", res);
    dispatch(updateLineSuccess(res.data));
  } catch (error) {
    console.log("error from lineFailure >>>", error);
    dispatch(updateLineFailure());
  }
};

export const deleteLines = async (id, dispatch) => {
  dispatch(deleteLineStart());
  try {
    const res = await userRequest.post("/line/deleteLine", { id });
    // console.log("response from delete Lines ==>", res);
    dispatch(deleteLineSuccess(res.data));
  } catch (error) {
    console.log("error from lineFailure >>>", error);
    dispatch(deleteLineFailure());
  }
};
