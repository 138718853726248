import React, { useEffect, useState } from "react";
import MachineStopChart from "../../components/Charts/MachineStopChart";
import WidgetOne from "../../components/WidgetOne/WidgetOne";
// import WidgetThree from "../../components/WidgetThree/WidgetThree";
// import OEEByShift from "../../components/Charts/OEEByShift";
import WidgetTwo from "../../components/WidgetTwo/WidgetTwo";
import TopThreeStops, { TopThreeStopsComp } from "../../components/Charts/TopThreeStops";
import OEELossChart, { OEELossChartComp } from "../../components/Charts/OEELossChart";
import StopsBreakdown from "../../components/Charts/StopsBreakdown";
import ReactMaterialTable from "../../components/ReactMaterialTable/ReactMaterialTable";
// import ToolBar from "../../components/ToolBar/ToolBar";
import './dashboard.css'
import uniqueId from "../../utils/uniqueId";

import { Modal } from "react-bootstrap";
// import MaterialTable from "material-table";
// React Redux
import { useDispatch, useSelector } from "react-redux";
import { getCompleteOrders } from "../../store/redux-apis/completeOrderApiCalls";

// Loading Spinner
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"

//Moment
import moment from "moment";
// Lodash
import _ from "lodash"
// DateTimePickerTabs
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getShifts } from "../../store/redux-apis/shiftApiCalls";
import { getLines } from "../../store/redux-apis/lineApiCalls";
import { getFilterStops, getStops } from "../../store/redux-apis/stopsApiCalls";
import { getCauses } from "../../store/redux-apis/causeApiCalls";
import MachineStopChartReact from "../../components/Charts/MachineStopChartReact";
import { OverAllComp } from "../../components/Charts/OverAllOee/OverAllOee";
import TopFrequentStops from "../../components/Charts/TopFrequentStops";
// import { handleSorting } from "../../utils/dataSorting";



const OVERALL_ICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z"
      fill="currentColor"
    />
  </svg>
);

const AVAILABLITY_ICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.3"
      d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z"
      fill="currentColor"
    />
    <path
      d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z"
      fill="currentColor"
    />
    <path
      d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z"
      fill="currentColor"
    />
  </svg>
);

const PERFORMANCE_ICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.3"
      d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
      fill="currentColor"
    />
    <path
      d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
      fill="currentColor"
    />
  </svg>
);

const QUALITY_ICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.3"
      d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
      fill="currentColor"
    />
    <path
      d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
      fill="currentColor"
    />
  </svg>
);



const Dashboard = () => {
  // console.log("unique id >>", uniqueId());

  // <=================================== Begin Get Data from Redux using useSelector ==============================>
  // <=================================== Begin Get Data from Redux using useSelector ==============================>
  // const completeOrders = useSelector((state) => state.completeOrder.completeOrder?.data);
  const completeOrders = useSelector((state) => state.completeOrder.completeOrder);
  // console.log("completeOrders >>>", completeOrders);
  const shiftsData = useSelector((state) => state.shift.shifts.data);
  // console.log("shiftsData >>>", shiftsData);
  // const linesData = useSelector((state) => state.line.lines?.data);
  // console.log("lineData >>>", linesData);
  // const stopsDataRedux = useSelector((state) => state.stop.stops?.data);
  // console.log("stopsdataRedux  >>>", stopsDataRedux);
  // const filterStopsData = useSelector((state) => state.stop.filterStops?.data);
  // console.log("filterStopsdata  >>>", filterStopsData);
  // const causesData = useSelector((state) => state.cause.causes?.data);
  // console.log("causesData  >>>", causesData);
  // <================================ End Get Data from Redux using useSelector =================================>
  // <================================ End Get Data from Redux using useSelector =================================>

  // <========================================== Bigin Dispatch And Call apis =========================================>
  // <========================================== Bigin Dispatch And Call apis =========================================>
  const dispatch = useDispatch();
  useEffect(() => {
    getShifts(dispatch);
    // getLines(dispatch);
    getStops(dispatch)
    getCompleteOrders(dispatch);
    // getCauses(dispatch);
  }, [dispatch]);
  // <======================================== End Dispatch And Call apis ===========================================>
  // <======================================== End Dispatch And Call apis ===========================================>


  // // // <=============================================== Bigin States  ==============================================>
  // // // <=============================================== Bigin States  ==============================================>
  // // For Loading Spinner 
  const [isLoading, setIsLoading] = useState(false);
  // For FIlter Stops States
  const [stopsData, setStopsData] = useState([])
  // useEffect(() => {
  //   setStopsData(stopsDataRedux)
  // }, [stopsDataRedux]);
  // console.log("stopsData >>>", stopsData);
  // For Date FIlter States
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  // For Refresh 
  const [refresh, setRefresh] = useState(1);
  // For Modals
  const [showShift, setShowShift] = useState(false);
  const [showMachine, setShowMachine] = useState(false);
  // shift state
  const [selectedShift, setSelectedShift] = useState([])
  // Machine/Device state
  const [selectedDevice, setSelectedDevice] = useState([])
  // totalstops and downtime
  const [totalStops, setTotalStops] = useState(0);
  const [totalDowntime, setTotalDowntime] = useState(0);
  // Filter Stops
  const [filterStops, setFilterStops] = useState([]);
  // Filter Orders
  const [filterOrders, setFilterOrders] = useState([]);
  // Line/Device/Machine data
  const [machineDeviceData, setMachineDeviceData] = useState([]);
  // // // State Variables Start =================================================================================================>
  // // // State Variables Start =================================================================================================>
  const [shippableProducts, setshippableProducts] = useState(0)
  // console.log("shippableProducts", shippableProducts);
  const [wolf1Downtime, setwolf1Downtime] = useState(0)
  // console.log("wolf1Downtime", wolf1Downtime);
  const [wolf2Downtime, setwolf2Downtime] = useState(0)
  // console.log("wolf2Downtime", wolf2Downtime);
  const [wolf3Downtime, setwolf3Downtime] = useState(0)
  // console.log("wolf3Downtime", wolf3Downtime);
  const [deinst2Downtime, setdeinst2Downtime] = useState(0)
  // console.log("deinst2Downtime", deinst2Downtime);
  const [unplannedDownTime, setunplannedDownTime] = useState(0)
  // console.log("unplannedDownTime", unplannedDownTime);
  const [plannedDownTime, setplannedDownTime] = useState(0)
  // console.log("plannedDownTime", plannedDownTime);
  const [unplannedStopsCount, setunplannedStopsCount] = useState(0)
  // console.log("unplannedStopsCount", unplannedStopsCount);
  const [plannedStopsCount, setplannedStopsCount] = useState(0)
  // console.log("plannedStopsCount", plannedStopsCount);
  const [plannedHours, setplannedHours] = useState(0)
  // console.log("plannedHours", plannedHours);
  const [totalTechnicalStops, settotalTechnicalStops] = useState(0)
  // console.log("totalTechnicalStops", totalTechnicalStops);
  const [totalTechnicalStopsTime, settotalTechnicalStopsTime] = useState(0)
  // console.log("totalTechnicalStopsTime", totalTechnicalStopsTime);
  const [totalOperationalStops, settotalOperationalStops] = useState(0)
  // console.log("totalOperationalStops", totalOperationalStops);
  const [totalOperationalStopsTime, settotalOperationalStopsTime] = useState(0)
  // console.log("totalOperationalStopsTime", totalOperationalStopsTime);
  const [MTTR, setMTTR] = useState(0)
  // console.log("MTTR", MTTR);
  const [unplannedMTTR, setunplannedMTTR] = useState(0)
  // console.log("unplannedMTTR", unplannedMTTR);
  const [totalUpTime, settotalUpTime] = useState(0)
  // console.log("totalUpTime", totalUpTime);
  const [MTBF, setMTBF] = useState(0)
  // console.log("MTBF", MTBF);
  const [unplannedMTBF, setunplannedMTBF] = useState(0)
  // console.log("unplannedMTBF", unplannedMTBF);
  const [allStops, setallStops] = useState([])
  // console.log("allStops", allStops);
  const [topThreeStops, settopThreeStops] = useState([])
  // console.log("topThreeStops", topThreeStops);
  const [filterStopsForExcel, setfilterStopsForExcel] = useState([])
  // console.log("filterStopsForExcel", filterStopsForExcel);
  const [filterStopsForGraph4, setfilterStopsForGraph4] = useState([])
  // console.log("filterStopsForGraph4", filterStopsForGraph4);
  const [stopGraphArray, setstopGraphArray] = useState([])
  // console.log("stopGraphArray", stopGraphArray);
  const [stopsReasonTime, setstopsReasonTime] = useState([])
  const [stopsGraphDataFilter, setstopsGraphDataFilter] = useState([])
  const [idealRunRate, setidealRunRate] = useState(0)
  const [availableHours, setavailableHours] = useState(0)
  const [productCountTotal, setproductCountTotal] = useState(0)
  const [waste, setwaste] = useState(0)
  const [scrapPercent, setscrapPercent] = useState(0)
  const [plannedProductionTime, setplannedProductionTime] = useState(0)
  const [operationTime, setoperationTime] = useState(0)
  const [grossProduct, setgrossProduct] = useState(0)
  const [availablity, setavailablity] = useState(0)
  const [performance, setperformance] = useState(0)
  const [performanceGauge, setperformanceGauge] = useState(0)
  const [quality, setquality] = useState(0)
  const [oee, setoee] = useState(0)
  // // // <================================================= End States  ============================================>
  // // // <================================================= End States  ============================================>


  // // // <================================================= start useEffect  ============================================>
  // // // <================================================= start useEffect  ============================================>
  useEffect(() => {
    let getDeviceName = []
    let singleLine = completeOrders?.map((n) => n.deviceName);
    singleLine = Array.from(new Set(singleLine));
    for (let i = 0; i < singleLine.length; i++) {
      getDeviceName.push({ deviceName: singleLine[i] });
    }
    console.log("getDeviceName >>>>", getDeviceName);
    // console.log("singleLine >>>", singleLine);
    setMachineDeviceData(getDeviceName);
  }, [completeOrders])
  // // // <================================================= End useEffect  ============================================>
  // // // <================================================= End useEffect  ============================================>





  // For Filter date functions
  const handleChangeOne = (newValue) => setStartDate(newValue);
  const handleChangeTwo = async (newValue) => {
    // console.log("new Value >>>", newValue);
    setEndDate(newValue)
    const filterStopsFD = await getFilterStops(dispatch, {
      startDate: moment(startDate).format("MM/DD/YYYY"),
      endDate: moment(newValue).format("MM/DD/YYYY"),
    })
    // console.log("filterStopsFD >>>", filterStopsFD);
    setStopsData(filterStopsFD.data)
  };

  const handleCloseShift = () => setShowShift(false);
  const handleShowShift = () => setShowShift(true);

  const handleCloseMachine = () => setShowMachine(false);
  const handleShowMachine = () => setShowMachine(true);

  const handleSetShiftName = (e) => {
    if (
      e.target.checked &&
      !selectedShift.includes(e.target.value)
    ) {
      setSelectedShift((preValue) => [...preValue, e.target.value]);
    } else if (!e.target.checked) {
      setSelectedShift(selectedShift.filter((shift) => shift !== e.target.value))
    }
  }
  const handleShift = (e) => {
    e.preventDefault();
    console.log("selectedShift >>>", selectedShift);
    handleCloseShift();
  }

  const handleSetDeviceName = (e) => {
    if (
      e.target.checked &&
      !selectedDevice.includes(e.target.value)
    ) {
      setSelectedDevice((preValue) => [...preValue, e.target.value]);
    } else if (!e.target.checked) {
      setSelectedDevice(selectedDevice.filter((device) => device !== e.target.value))
    }
  }

  const handleMachine = async (e) => {
    e.preventDefault();

    console.log("selectedDevice >>>", selectedDevice);

    // let filteredStops =
    //   moment().format("MM/DD/YYYY") == moment(startDate).format("MM/DD/YYYY") &&
    //     moment().format("MM/DD/YYYY") == moment(endDate).format("MM/DD/YYYY")
    //     ? stopsData.filter(
    //       (stop) =>
    //         (stop.startTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
    //           stop.endTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
    //           stop.startTime.split("T")[0] == moment(endDate).format("YYYY-MM-DD") ||
    //           stop.endTime.split("T")[0] == moment(endDate).format("MM/DD/YYYY") ||
    //           (moment(stop.startTime.split("T")[0]).isBefore(moment(moment(endDate).format("YYYY-MM-DD"))) &&
    //             moment(stop.endTime.split("T")[0]).isAfter(moment(moment(startDate).format("YYYY-MM-DD"))))) &&
    //         selectedDevice.includes(stop.childDeviceName)
    //     )
    //     : stopsData.filter(
    //       (stop) =>
    //         (stop.startTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
    //           stop.endTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
    //           stop.startTime.split("T")[0] == moment(endDate).format("YYYY-MM-DD") ||
    //           stop.endTime.split("T")[0] == moment(endDate).format("YYYY-MM-DD") ||
    //           (moment(stop.startTime.split("T")[0]).isBefore(moment(moment(endDate).format("YYYY-MM-DD"))) &&
    //             moment(stop.endTime.split("T")[0]).isAfter(moment(moment(startDate).format("YYYY-MM-DD"))))) &&
    //         selectedDevice.includes(stop.childDeviceName)
    //     );
    // // console.log("filteredStops >>>", filteredStops);
    // let downtime = filteredStops.map((f) => f.downTime);
    // // console.log("downtime filtered >>>", downtime);
    // let totalTime = downtime.reduce((a, b) => a + b, 0);
    // // console.log("totalTime filtered >>>", totalTime);
    // setTotalStops(filteredStops.length);
    // setTotalDowntime(totalTime);
    // setFilterStops(filteredStops);

    handleCloseMachine();
  }

  // const handleSearch = async () => {}


  // shift lenghth 
  let shiftLength = 0;
  let getOEE = 0;


  const handleSearch = async () => {
    setIsLoading(true);
    //  console.log(selectedDte);
    // stopG(startDate, endDate);
    // getcauseOne();

    // console.log("completeOrders >>>>", completeOrders);
    // console.log(moment().format("DD-MM-YYYY"));
    const date1 = moment(startDate, "MM/DD/YYYY");
    const date2 = moment(endDate, "MM/DD/YYYY");

    // // console.log(stopGData);

    var shift1 =
      moment().format("MM/DD/YYYY") == moment(startDate).format("MM/DD/YYYY") &&
        moment().format("MM/DD/YYYY") == moment(endDate).format("MM/DD/YYYYY")
        ? completeOrders?.filter(
          (x) =>
            (x.timestamp.split(" ")[0] == moment(startDate).format("MM/DD/YYYY") ||
              x.timestamp.split(" ")[0] == moment(endDate).format("MM/DD/YYYY")) &&
            (selectedDevice.includes(x.deviceName)) &&
            selectedShift.includes(x.shiftName) &&
            x.deviceStatus !== 0
        )
        : completeOrders?.filter(
          (x) =>
            x.timestamp.split(" ")[0] == moment(startDate).format("MM/DD/YYYY") ||
            x.timestamp.split(" ")[0] == moment(endDate).format("MM/DD/YYYY") ||
            (moment(x.timestamp.split(" ")[0]).isBefore(moment(moment(endDate).format("MM/DD/YYYY"))) &&
              moment(x.timestamp.split(" ")[0]).isAfter(moment(moment(startDate).format("MM/DD/YYYY"))))
        );

    shift1 = shift1.filter(
      (x) =>
        (selectedDevice.includes(x.deviceName)) &&
        selectedShift.includes(x.shiftName) &&
        x.deviceStatus !== 0
    );
    // console.log("shift1 console.one >>>>", shift1);

    if (selectedShift.includes("C")) {
      shift1 = shift1.filter(
        (x) =>
          x.timestamp.split(" ")[1].split(":")[0] == "23" ||
          (x.timestamp.split(" ")[1].split(":")[0] == "00" &&
            x.timestamp.split(" ")[0] == moment(endDate).format("MM/DD/YYYY")) ||
          (x.timestamp.split(" ")[1].split(":")[0] < "07" &&
            x.timestamp.split(" ")[0] == moment(endDate).format("MM/DD/YYYY")) ||
          (moment(x.timestamp.split(" ")[0]).isBefore(moment(moment(endDate).format("MM/DD/YYYY"))) &&
            moment(x.timestamp.split(" ")[0]).isAfter(moment(moment(startDate).format("MM/DD/YYYY")))) ||
          x.shiftName == "A" ||
          x.shiftName == "B"
      );
      // console.log("shift1 console.two >>>>", shift1);
    }


    if (
      (selectedShift.includes("A") || selectedShift.includes("B")) &&
      moment(startDate).format("MM/DD/YYYY") == moment(endDate).subtract(1, "days").format("MM/DD/YYYY")
    ) {
      shift1 = shift1.filter(
        (x) =>
          (x.shiftName === "A" && x.timestamp.split(" ")[0] == moment(startDate).format("MM/DD/YYYY")) ||
          (x.shiftName === "B" && x.timestamp.split(" ")[0] == moment(startDate).format("MM/DD/YYYY")) ||
          x.shiftName == "C"
      );
      // console.log("if condition for Get Shift  >>>>>");
      // console.log("shift1 console.three >>>>", shift1);

    }


    setFilterOrders(shift1);

    var id = shift1.map((x) => x.orderId);
    // console.log("orderId >>>", id);

    var plannedStart = shift1.map((x) => x.plannedStart);
    // console.log("plannedStart >>>>", plannedStart);
    // console.log("shift1 data >>>", shift1);
    // console.log("moment data >>>>", moment(startDate).subtract(1, "days").format("MM/DD/YYYY"));
    // console.log("selected Machine >>>>", selectedDevice);

    if (shift1.length) {
      // console.log("stops data with filter date", stopsData);
      if (!stopsData) {
        return
      }
      else {
        var filteredStops =
          moment().format("MM/DD/YYYY") == moment(startDate).format("MM/DD/YYYY") &&
            moment().format("MM/DD/YYYY") == moment(endDate).format("MM/DD/YYYY")
            ? stopsData?.filter(
              (s) =>
                plannedStart.includes(s.orderStart) &&
                (selectedDevice.includes(s.deviceName)) &&
                selectedShift.includes(s.shiftName) &&
                s.downTime !== 0
            )
            : stopsData?.filter(
              (s) =>
                (s.startTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
                  s.endTime.split("T")[0] == moment(startDate).format("YYYY-MM-DD") ||
                  s.startTime.split("T")[0] == moment(endDate).format("YYYY-MM-DD") ||
                  s.endTime.split("T")[0] ==
                  moment(endDate).format("YYYY-MM-DD") ||
                  (moment(s.startTime.split("T")[0]).isBefore(moment(moment(endDate).format("YYYY-MM-DD"))) &&
                    moment(s.endTime.split("T")[0]).isAfter(moment(moment(startDate).format("YYYY-MM-DD"))))) &&
                (selectedDevice.includes(s.deviceName)) &&
                selectedShift.includes(s.shiftName) &&
                s.downTime !== 0
            );
      }
      console.log("filteredStops one >>>", filteredStops)
      // Temporary wilf1,wolf2 and wolf3 downtime
      let wolf1Downtime = filteredStops.filter(
        (item) => item.childDeviceName == "Wolf1"
      );
      // console.log("wolf1Downtime >>>", wolf1Downtime);
      let w1Reduce = wolf1Downtime.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.downTime;
      }, 0);
      let w1DTMIN = w1Reduce / 60;
      // console.log("wolf1 Downtime with minutes >>>", w1DTMIN);
      // // // // =============================================== setWolfOneDownTime() ===================================>>>
      // setW1Down(w1DTMIN);

      setwolf1Downtime(w1DTMIN);
      // // // // =============================================== setWolfOneDownTime() ===================================>>>
      let wolf2Downtime = filteredStops.filter(
        (item) => item.childDeviceName == "Wolf2"
      );
      // console.log("wolf2Downtime >>>>", wolf2Downtime);
      let w2Reduce = wolf2Downtime.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.downTime;
      }, 0);
      let w2DTMIN = w2Reduce / 60;
      // console.log("wolf2 Downtime with minutes >>>", w2DTMIN);
      // // // // =============================================== setWolfTwoDownTime() ===================================>>>
      // setW2Down(w2DTMIN);
      setwolf2Downtime(w2DTMIN)
      // // // // =============================================== setWolfTwoDownTime() ===================================>>>
      let wolf3Downtime = filteredStops.filter(
        (item) => item.childDeviceName == "Wolf3"
      );
      // console.log("wolf3Downtime >>>", wolf3Downtime);
      let w3Reduce = wolf3Downtime.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.downTime;
      }, 0);
      let w3DTMIN = w3Reduce / 60;
      // console.log("wolf3 Downtime with minutes >>>", w3DTMIN);
      // // // // =============================================== setWolfThreeDownTime() ===================================>>>
      // setW3Down(w3DTMIN);
      setwolf3Downtime(w3DTMIN)
      // // // // =============================================== setWolfThreeDownTime() ===================================>>>

      // filteredStops = filteredStops.filter(
      //   (f) =>
      //     selectedDevice.includes(f.childDeviceName)
      // );
      // console.log(filteredStops)
      // filteredStops = filteredStops.filter(
      //   (f) =>
      //     id.includes(f.productOrderId)
      // );
      filteredStops = filteredStops.filter((f) =>
        plannedStart.includes(f.orderStart)
      );
      // console.log("filteredStops two >>>", filteredStops);

      let filteredByUnplannedCat = [];
      filteredStops.filter((stop) =>
        stop.categoryName === "Unplanned" ? filteredByUnplannedCat.push(stop.downTime) : 0
      );
      // console.log("filteredByUnplannedCat one >>>", filteredByUnplannedCat);
      let downtime = filteredByUnplannedCat.reduce((a, b) => a + b, 0) / 60;
      // console.log("donwtime >>>>>", downtime);
      // breaks = breaks.reduce((a, b) => a + b, 0);

      // // // // =============================================== setUnplannedDowntime() ===================================>>>
      // setUnplannedDowntime(downtime);
      setunplannedDownTime(downtime)
      // // // // =============================================== setUnplannedDowntime() ===================================>>>


      /*For planned downtime of shift */
      let filteredByPlannedCat = [];
      filteredStops.filter((stop) =>
        stop.categoryName === "Planned" ? filteredByPlannedCat.push(stop.downTime) : 0
      );
      // console.log("filteredByPlannedCat one >>>", filteredByPlannedCat);
      let breaks = filteredByPlannedCat.reduce((a, b) => a + b, 0) / 60;
      // console.log("breaks >>>>", breaks);
      // // // // =============================================== planned, unplanned , downtime states() ===================================>>>
      // setPlannedDowntime(breaks);
      // setUnplannedStopsCount(filteredByUnplannedCat.length);
      // setPlannedStopsCount(filteredByPlannedCat.length);
      setplannedDownTime(breaks);
      setunplannedStopsCount(filteredByUnplannedCat.length);
      setplannedStopsCount(filteredByPlannedCat.length);
      // // // // =============================================== planned, unplanned , downtime states() ===================================>>>

      //  // New Changes for Planned Dowtime start==================================

      var processTimes = shift1.map((p) => ({
        plannedStart: p.plannedStart,
        plannedEnd: p.plannedEnd,
      }));
      // console.log("processTimes >>>>", processTimes)
      let TotalOrderMinutes = [];
      processTimes.map((item) => {
        let totalTime =
          Math.abs(new Date(item.plannedEnd) - new Date(item.plannedStart)) / 60000;
        TotalOrderMinutes.push(totalTime);
      });
      let OrderMinutes = TotalOrderMinutes.reduce((a, b) => a + b, 0);
      // console.log("TotalOrderMinutes >>>", TotalOrderMinutes)
      // console.log("OrderMinutes >>>", OrderMinutes);
      setplannedHours(OrderMinutes);


      // // kpi cards work technial stops,operational stops, and their downtime start

      // console.log(filterStopsForGraph)
      // TECHNICAL
      // console.log("filteredStops >>>", filteredStops);
      let technicalStopsData = filteredStops.filter(item => item.subCategoryName === 'Technical')
      // console.log("technicalStopsData >>>", technicalStopsData)
      let TotalTS = technicalStopsData.length
      // console.log("TotalTS technical Stops.length >>>", TotalTS)
      // setTotalts(TotalTS)
      settotalTechnicalStops(TotalTS)

      let TotalTSMinutes = [];
      let tsmin = technicalStopsData.map((item) => {
        let totalTime =
          Math.abs(new Date(item.endTime) - new Date(item.startTime)) / 60000;
        TotalTSMinutes.push(totalTime);
      });
      var MinutesTS = TotalTSMinutes.reduce((a, b) => a + b, 0);
      // console.log("TotalTSMinutes >>>", TotalTSMinutes)
      // console.log("MinutesTS >>>>", MinutesTS);
      // setTotaltsTime(MinutesTS)
      settotalTechnicalStopsTime(MinutesTS)


      // // OPERATIONAL
      let OS = filteredStops.filter(item => item.subCategoryName === 'Operational')
      // console.log('operational Stops >>>>', OS)

      let TotalOS = OS.length
      // setTotalos(TotalOS)
      settotalOperationalStops(TotalOS)
      // setOperationalStops(OS.length)
      let TotalOSMinutes = [];
      let osmin = OS.map((item) => {
        let totalTime =
          Math.abs(new Date(item.endTime) - new Date(item.startTime)) / 60000;
        TotalOSMinutes.push(totalTime);
      });
      let MinutesOS = TotalOSMinutes.reduce((a, b) => a + b, 0);
      // console.log("TotalOSMinutes >>>>", TotalOSMinutes)
      // console.log("MinutesOS >>>", MinutesOS);
      // setTotalosTime(MinutesOS)
      settotalOperationalStopsTime(MinutesOS)



      // // kpi cards work MTTR, MTBF and their downtime end

      // console.log("mttr data >>>>", MinutesTS, TotalTS)
      let MTTR = MinutesTS / TotalTS;
      // console.log(`MTTR:${MTTR} Mins`);
      // setMttr(MTTR);
      setMTTR(MTTR);

      // // MTTR UNPLANNED CALCULATION(filter)
      let unplannedMTTR = downtime / filteredByUnplannedCat.length;
      // console.log("UnplannedMTTR: >>>", unplannedMTTR);
      // setUnPlannedMTTR(unplannedMTTR);
      setunplannedMTTR(unplannedMTTR);

      let downtimeArray = filteredStops.map((x) => x.downTime);
      let downTimeTotal = downtimeArray.reduce((a, b) => a + b, 0) / 60;
      let totalUpTime = OrderMinutes - downTimeTotal;
      // console.log("totalUpTime >>>", totalUpTime);
      // setUptime(totalUpTime);
      settotalUpTime(totalUpTime);

      // // MTBF CALCULATION(filter)
      let MTBF = totalUpTime / TotalTS;
      // console.log("MTBF: >>", MTBF);
      // setMtbf(MTBF);
      setMTBF(MTBF);
      // // MTBF UNPLANNED CALCULATION(filter)
      let unplannedMTBF = totalUpTime / filteredByUnplannedCat.length;
      // console.log("MTBF UNPALNNED >>>", unplannedMTBF);
      // setUnPlannedMTBF(unplannedMTBF);
      setunplannedMTBF(unplannedMTBF);


      // // // //  denist2Downtime ===================================================================================
      // // // //  denist2Downtime ===================================================================================

      // console.log("selectedDevice >>>", selectedDevice);
      let denist2Downtime = filteredStops.filter(
        // (item) => item.childDeviceName == selectedDevice
        (item) => selectedDevice.includes(item.childDeviceName)
      );
      // console.log("denist2Downtime >>", denist2Downtime);
      // setAllStops(denist2Downtime)
      setallStops(denist2Downtime)
      let d2Stops = denist2Downtime.sort((x, y) => y.downTime - x.downTime);
      // console.log("d2Stops >>>", d2Stops)
      var top3Stops = d2Stops.splice(0, 3)
      // console.log("top3Stops >>>", top3Stops)
      // setTopThreeStops(top3Stops)
      settopThreeStops(top3Stops)
      // setFilterStops(denist2Downtime)
      let totalSec = denist2Downtime.reduce((a, b) => a + b.downTime, 0);
      // console.log("totalSec >>>", totalSec);

      // // // //   denist2Downtime  =================================================================
      // // // //   denist2Downtime  =================================================================

      // // // Cuases work start ===================================================================>
      // // // Cuases work start ===================================================================>

      let causeArray = [];
      let stopGraphObj = {};

      // console.log("filteredStops >>>>", filteredStops);
      // setFilterStopsForExcel(filteredStops)
      // setFilterStopsForGraph4(filteredStops)
      setfilterStopsForExcel(filteredStops)
      setfilterStopsForGraph4(filteredStops)

      let filteredCauseData = []
      let filteredUnCauseData = []
      // filteredStops.map((item) => {
      filteredStops.forEach((item) => {
        // console.log("item >>>", item);
        if (item.causeOne === undefined || item.causeOne === "") {
          // console.log("causeOne is undefined or '' >>>>>");
          filteredUnCauseData.push(item)
        } else {
          // console.log("item.causeOne >>>>", item.causeOne)
          filteredCauseData.push(item)

          // causeArray.push(causesData.filter(item2 => (item2.causeOne === item.causeOne)).map(item3 => {
          //   return item.causeOne = item3.causeOne
          // }));
          // console.log("causeArray >>>>", causeArray);
          // causeArray.push(causesData.filter((item2) => item2.causeOne == item.causeOne).map((item3) => {
          //   console.log("item3  >>>", item3);
          //   return (item.causeOne = item3.causeOne);
          // })
          // );
          // console.log("causeArray >>>>", causeArray);

          // var counts = {};
          // causeArray.forEach(function (x) {
          //   counts[x] = (counts[x] || 0) + 1;
          // });
          // console.log("counts before action >>>>", counts);

          // const entries = Object.entries(counts);
          // console.log("entries >>>>", entries);

          // let causeOneUncategorizedData = [];
          // let causeOneUncatLength = filteredStops.filter(
          //   (item) => item.causeOne == "" || item.causeOne == undefined
          // );
          // console.log("causeOneUncatLength >>>", causeOneUncatLength);
          // console.log("filteredStops.length >>>",filteredStops.length)
          // // working for uncategorized
          // causeOneUncategorizedData.push({
          //   total: causeOneUncatLength.length,
          //   name: "Uncategorized",
          // });
          // // checkhere
          // for (const [fruit, count] of entries) {
          //   stopGraphObj["total"] = count;
          //   causeOneUncategorizedData.push({
          //     total: count,
          //     name: fruit
          //   });
          //   console.log("count >>>>", count);

          //   // setStopGraphArr(causeOneUncategorizedData);
          //   setstopGraphArray(causeOneUncategorizedData)
          // }
          // console.log("causeOneUncategorizedData >>>", causeOneUncategorizedData);
          // function dataSorted() {
          //   let dept = causeOneUncategorizedData.sort(function (a, b) {
          //     let deptA = a.total;
          //     let deptB = b.total;
          //     if (deptA > deptB) {
          //       return -1;
          //     } else if (deptA < deptB) {
          //       return 1;
          //     }
          //     return 0;
          //   });

          //   console.log(dept);
          // }
          // dataSorted();
        }
      });
      // console.log("causesData >>>", causesData);
      // console.log("causeArray >>>>", causeArray);
      // console.log("stopGraphObj >>>", stopGraphObj);

      // console.log("filteredCauseData >>>>", filteredCauseData);
      if (filteredCauseData) {
        let causeOneCategorizedData = _.groupBy(filteredCauseData, "causeOne");
        // console.log("causeOneCategorizedData >>>", causeOneCategorizedData);
        let causeOneCategorizedDataEntries = Object.entries(causeOneCategorizedData);
        // console.log("causeOneCategorizedDataEntries >>>", causeOneCategorizedDataEntries);
        let causeOneCatData = []
        causeOneCategorizedDataEntries.map(item => {
          causeOneCatData.push({
            name: item[0],
            total: item[1].length
          })
        })
        // console.log("causeOneCatData >>>", causeOneCatData);
        // let causeOneUncategorizedData = [];
        // causeOneUncategorizedData.push({
        //   total: filteredUnCauseData?.length,
        //   name: "Uncategorized",
        // })

        causeOneCatData.push({
          total: filteredUnCauseData?.length,
          name: "Uncategorized",
        })

        // console.log("causeOneCatData >>>", causeOneCatData);
        function dataSorted() {
          let causeSort = causeOneCatData.sort(function (a, b) {
            let sortA = a.total;
            let sortB = b.total;
            if (sortA > sortB) {
              return -1;
            } else if (sortA < sortB) {
              return 1;
            }
            return 0;
          });

          // console.log(causeSort);
        }
        dataSorted();

        //   // setStopGraphArr(causeOneUncategorizedData);
        setstopGraphArray(causeOneCatData)
      }

      // // // Cuases work end ===================================================================>
      // // // Cuases work end ===================================================================>


      // // // Machine stop graph work with resons total downtime =============================>
      // // // Machine stop graph work with resons total downtime =============================>

      let machineStopReason = filteredStops.reduce((c, v) => {
        c[v.causeOne] = (c[v.causeOne] || 0) + v.downTime;
        return c;
      }, {});
      // console.log("machineStopReason >>>", machineStopReason)

      let entries = Object.entries(machineStopReason);
      let sortedReason = entries.sort((a, b) => b[1] - a[1]);

      let uncatMR = sortedReason.map(item => {
        // console.log("itemitem", item[0])
        // console.log("typeof", typeof (item[0]))
        // console.log("stringLength", item[0].length)
        if (item[0].length == 0) {
          // console.log('emtyfound')
          item[0] = "Uncategorized"
        }
      })
      // console.log("sortedReason >>>", sortedReason)

      // setStopsReasonTime(sortedReason)
      setstopsReasonTime(sortedReason)

      // // // Machine stop graph work with resons total downtime =============================>
      // // // Machine stop graph work with resons total downtime =============================>



      // // Stop Graph =================================================================================================================>>>
      // // Stop Graph =================================================================================================================>>>

      const filteredStopsGraph =
        moment().format("MM/DD/YYYY") == moment(date1).format("MM/DD/YYYY") &&
          moment().format("MM/DD/YYYY") == moment(date2).format("MM/DD/YYYY")
          // ? stopGData.filter(
          ? stopsData.filter(
            (s) =>
              (s.startTime.split("T")[0] == moment(date1).format("YYYY-MM-DD") ||
                s.endTime.split("T")[0] == moment(date1).format("YYYY-MM-DD") ||
                s.startTime.split("T")[0] == moment(date2).format("YYYY-MM-DD") ||
                s.endTime.split("T")[0] == moment(date2).format("YYYY-MM-DD") ||
                (moment(s.startTime.split("T")[0]).isBefore(moment(moment(date2).format("YYYY-MM-DD"))) &&
                  moment(s.endTime.split("T")[0]).isAfter(moment(moment(date1).format("YYYY-MM-DD"))))) &&
              (selectedDevice.includes(s.deviceName)) &&
              selectedShift.includes(s.shiftName)
          )
          // : stopGData.filter(
          : stopsData.filter(
            (s) =>
              (s.startTime.split("T")[0] == moment(date1).format("YYYY-MM-DD") ||
                s.endTime.split("T")[0] == moment(date1).format("YYYY-MM-DD") ||
                s.startTime.split("T")[0] == moment(date2).format("YYYY-MM-DD") ||
                s.endTime.split("T")[0] == moment(date2).format("YYYY-MM-DD") ||
                (moment(s.startTime.split("T")[0]).isBefore(moment(moment(date2).format("YYYY-MM-DD"))) &&
                  moment(s.endTime.split("T")[0]).isAfter(moment(moment(date1).format("YYYY-MM-DD"))))) &&
              (selectedDevice.includes(s.deviceName)) &&
              selectedShift.includes(s.shiftName)
          );
      // console.log("filteredStopsGraph >>>", filteredStopsGraph);

      // setStopGDataFILTER(filteredStopsGraph);
      setstopsGraphDataFilter(filteredStopsGraph);

      // console.log(processTimes);

      const rate = shift1.map((skuVal) => Number(skuVal.skuTarget));
      // console.log("rate >>>>>", rate);
      const totalRate = rate.reduce((a, b) => a + b, 0) / rate.length;
      // console.log("totalRate >>>>>", totalRate);
      // setIdealRunRate(totalRate);
      setidealRunRate(totalRate);

      let time = Array.from(processTimes, (processTime) => ({
        plannedStartHours: moment(processTime.plannedStart, "MM/DD/YYYY HH:mm:ss").hours(),
        plannedStartMinutes: moment(processTime.plannedStart, "MM/DD/YYYY HH:mm:ss").minutes(),
        plannedStartSeconds: moment(processTime.plannedStart, "MM/DD/YYYY HH:mm:ss").seconds(),
        plannedEndHours: moment(processTime.plannedEnd, "MM/DD/YYYY HH:mm:ss").hours(),
        plannedEndMinutes: moment(processTime.plannedEnd, "MM/DD/YYYY HH:mm:ss").minutes(),
        plannedEndSeconds: moment(processTime.plannedEnd, "MM/DD/YYYY HH:mm:ss").seconds(),
      }));
      time[0].plannedStartHours = time[0].plannedStartHours > 12 && time[0].plannedEndHours < time[0].plannedStartHours ? time[0].plannedStartHours - 12 : time[0].plannedStartHours;

      // console.log("time 1st >>>", time);

      const workTime = time.map(
        (t) => t.plannedEndMinutes - t.plannedStartMinutes + Math.abs(t.plannedEndHours - t.plannedStartHours) * 60 + (t.plannedEndSeconds - t.plannedStartSeconds) / 60
      );
      // console.log("workTime >>>", workTime);
      // console.log("time 2nd >>>", time);

      let workingHours = workTime.reduce((a, b) => a + b, 0);
      // console.log("workingHours >>>", workingHours);

      // New Changes for Planned Dowtime End===================================
      let days = moment.duration(date2.diff(date1)).asDays();
      // console.log("days 1st time >>>", days);

      let length = [];

      for (let s = 1; s <= selectedShift.length; s++) {
        days > 1
          ? selectedShift[s - 1].includes("A") || selectedShift[s - 1].includes("C")
            ? length.push((days + 1) * 8 * 60)
            : length.push((days + 1) * 8 * 60)
          : selectedShift[s - 1].includes("A") || selectedShift[s - 1].includes("C")
            ? length.push(8 * 60)
            : length.push(8 * 60);
      }
      // console.log("days 2nd time >>>", days);
      shiftLength = length.reduce((a, b) => a + b, 0);
      // console.log("shiftLength >>>>", shiftLength);

      // setAvailableHours(shiftLength);
      setavailableHours(shiftLength);

      // // For total products in a shift  ===================================================>>
      const productsData = shift1.map((ar) => ar.productCount);
      const totalPieces = productsData.reduce((a, b) => a + b, 0);
      // console.log("totalPieces >>>>", totalPieces);
      // setProductCountTotal(totalPieces);
      setproductCountTotal(totalPieces);

      let productsToShip = shift1.map((shipProd) => shipProd.shippableProduct);
      let shippableProducts = productsToShip.reduce((a, b) => a + b, 0);
      // console.log("shippableProducts >>>", shippableProducts);
      // setGoodOutput(shippableProducts);
      setshippableProducts(shippableProducts);

      const scrap = totalPieces - shippableProducts;
      // console.log("scrap >>>", scrap);
      // setWaste(scrap);
      setwaste(scrap);

      // // Scrap percentage
      let scrapPercent = (scrap / totalPieces) * 100;
      // console.log("Scrap percent:", scrapPercent);
      // setScrapPercet(scrapPercent);
      setscrapPercent(scrapPercent);
      // console.log("shiftLength", shiftLength, "PlannedMinutes", OrderMinutes)

      // // Shutdown minutes in calculatiton
      let plannedProductionTime = Math.abs(OrderMinutes - breaks)
      // console.log("plannedProductionTime >>>", plannedProductionTime);

      // setPpt(plannedProductionTime);
      setplannedProductionTime(plannedProductionTime);

      let operatingTime = Math.abs(plannedProductionTime - downtime);
      // console.log("operatingTime >>", operatingTime);
      // setOpt(operatingTime);
      // setOpt(operatingTime);
      setoperationTime(operatingTime);

      // // // For Gross production  ========================================== >
      let grossProduce = totalPieces / operatingTime;
      grossProduce = grossProduce < 1 ? 1 : grossProduce;
      // console.log("grossProduct >>>", grossProduct);
      // setGrossProduct(grossProduce);
      setgrossProduct(grossProduce);

      let gross = Math.abs(workingHours - breaks);
      // console.log("gross >>>", gross);
      let onProductTime = gross - downtime;
      // console.log("onProductTime >>>", onProductTime);
      let actualSpeed = totalPieces / onProductTime;
      // console.log("actualSpeed >>>", actualSpeed);
      let grossProduction = actualSpeed * onProductTime;
      // console.log("grossProduction >>>", grossProduction);
      // setGrossProduct(grossProduction);
      setgrossProduct(grossProduction);

      let availability = (operatingTime / plannedProductionTime) * 100;
      availability = availability > 100 ? 100 : availability;
      // console.log("availability >>>", availability);
      // setAvail(availability);
      setavailablity(availability);

      let perfomance = (totalPieces / operatingTime / totalRate) * 100;
      // console.log("checking performance paramets", totalPieces);
      // console.log("checking performance paramets", operatingTime);
      // console.log("checking performance paramets", totalRate);
      // console.log("perfomance 1st time >>>", perfomance);
      let perfomanceGauge = perfomance > 100 ? 100 : perfomance;
      // console.log("perfomanceGauge >>>>", perfomanceGauge);
      // setPerform(perfomance);
      setperformance(perfomance);
      // setPerformGauge(perfomanceGauge);
      setperformanceGauge(perfomanceGauge);

      let quality = (shippableProducts / totalPieces) * 100;
      // console.log("quality >>>", quality);
      // setQty(quality);
      setquality(quality);

      // getOEE = (availability / 100) * (perfomance / 100) * (quality / 100) * 100;
      getOEE = (availability / 100) * (perfomanceGauge / 100) * (quality / 100) * 100;
      // console.log("getOee >>>", getOEE);
      setoee(getOEE);

    }

    setTimeout(() => {
      setIsLoading(false)   // Hide loading screen 
    }, 5000);
  };

  const handleClearFilter = () => {
    console.log("clear filter called");
    setIsLoading(false)
    setFilterStops([]);
    setStartDate(moment());
    setEndDate(moment());
    setTotalStops(0);
    setTotalDowntime(0);
    setSelectedDevice([])
    setSelectedShift([])
  }



  // graph Machine Stops Start =====================>
  // graph Machine Stops Start =====================>

  let DMStopsArr = [];
  let DMStops = stopGraphArray?.map((item) => DMStopsArr.push(item.total));
  // console.log("DMStops >>>", DMStops);
  // console.log("DMStopsArr >>>", DMStopsArr);

  let pieChartForStopsReasonTime = []
  stopsReasonTime && stopsReasonTime?.forEach((item, index) => {
    // console.log("stopsReasonTime item >>>", item);
    pieChartForStopsReasonTime.push({
      type: item[0],
      value: item[1]
    })
  })
  // console.log("pieChartForStopsReasonTime >>>", pieChartForStopsReasonTime);
  const stateStopsReasonTime = {
    series: [
      {
        data: stopsReasonTime?.map((item) => Number((item[1] / 60).toFixed(2))),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["black"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: stopsReasonTime?.map((item) => item[0]),
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: `Machine Stop Count: ${DMStopsArr?.reduce(
          (a, b) => a + b,
          0
        )} | Time: ${stopsReasonTime?.map((item) => item[1] / 60).reduce((a, b) => a + b, 0).toFixed(2)} Mins`,
        align: "center",
        floating: true,
      },
      //   subtitle: {
      //       text: 'Category Names as DataLabels inside bars',
      //       align: 'center',
      //   },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  };

  // graph Machine Stops end =====================>
  // graph Machine Stops end =====================>


  // graph Top Three Stops Start =====================>
  // graph Top Three Stops Start =====================>
  let top3Cat = [];
  let top3Time = [];
  // console.log("topThreeStops >>>>", topThreeStops);

  let nameOfCause = topThreeStops.map((item) => {
    // console.log(item.causeOne, item.downTime);
    return [item.causeOne, Number((item.downTime / 60).toFixed(2))];
  });
  // console.log(nameOfCause);
  let top3Categories = nameOfCause.map((item) => {
    top3Cat.push(item[0]);
    top3Time.push(item[1]);
  });

  let topThreeStopsChart = []
  // nameOfCause && nameOfCause?.forEach((item, index) => {
  pieChartForStopsReasonTime && pieChartForStopsReasonTime?.forEach((item, index) => {
    topThreeStopsChart.push({
      type: item.type,
      time: item.value,
    })
  })
  topThreeStopsChart = topThreeStopsChart?.splice(0, 3)
  // console.log("topThreeStopsChart >>>", topThreeStopsChart);

  // graph Top Three Stops end =====================>
  // graph Top Three Stops end =====================>

  // graph All stops breakdown start ==============================>
  // graph All stops breakdown start ==============================>

  let topAllCat = [];
  let topAllTime = [];

  let nameOfCauseAll = filterStopsForGraph4.map((item) => {
    // console.log("nameOfCauseAll item >>>", item);
    return [item.causeOne, Number((item.downTime / 60).toFixed(2))];
  });
  // console.log(nameOfCauseAll);
  let topALLCategories = nameOfCauseAll.map((item) => {
    // console.log("item topALLCategories >>>>", item);
    topAllCat.push(item[0]);
    topAllTime.push(item[1]);
  });

  const stopsBreakDownChart = nameOfCauseAll.map(item => {
    return {
      type: item[0],
      value: item[1]
    }
  })
  console.log("stopsBreakDownChart >>", stopsBreakDownChart);

  const stateStopsBreakDown = {
    series: [
      {
        name: "Stops",
        data: topAllTime,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          // enabled: false,
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
        // enabled: true,
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          // columnWidth: "50%",
        },
      },
      stroke: {
        curve: "straight",
        show: true,
        curve: 'smooth',
        colors: undefined,
        width: 0.5,
        dashArray: 0,
        // curve: 'smooth',
        // // OR provide an array
        // curve: ['smooth', 'straight', 'stepline']
      },
      title: {
        // text: "Stops Breakdown with Reasons",
        text: `Stops Breakdown with Reasons: ${topAllCat.length}`,
        align: "center",
        style: {
          fontSize: "24px",
          padding: "10px",
          fontWeight: "bold",
          paddingTop: "20px",
        }
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
          fontSize: "10px",
          fontWeight: "200",
        },
      },
      xaxis: {
        categories: topAllCat,
      },
      style: {
        fontSize: "12px",
        fontWeight: "light",
      }
    },
  };
  // graph All stops breakdown End =================================>
  // graph All stops breakdown End =================================>

  // console.log("filterOrders >>>", filterOrders);


  // For filter Orders
  const filterOrdersData = filterOrders?.map((d) => ({ ...d }))

  const filterUniqueOrdersData = [];
  let filterCount = 0;
  let startFilterCount = false;

  for (let j = 0; j < filterOrdersData?.length; j++) {
    for (let k = 0; k < filterUniqueOrdersData.length; k++) {
      if (filterOrdersData[j].plannedStart == filterUniqueOrdersData[k].plannedStart) {
        startFilterCount = true;
      }
    }
    filterCount++;
    if (filterCount == 1 && startFilterCount == false) {
      filterUniqueOrdersData.push(filterOrdersData[j]);
    }
    startFilterCount = false;
    filterCount = 0;
  }


  const oeeGraph = [
    {
      type: "OEE",
      value: oee
    },
    {
      type: "Performance",
      // value: performance
      value: performanceGauge
    },
    {
      type: "Availability",
      value: availablity
    },
    {
      type: "Quality",
      value: quality
    },
  ];



  // // // //   Frquent Stops start  =================================================================
  // // // //   Frquent Stops start  =================================================================
  const frequentStopsData = _.groupBy(filterStopsForGraph4, "causeOne")
  console.log("frequentStopsData >>>", frequentStopsData);
  const frequentStopsEntries = Object.entries(frequentStopsData);
  console.log("frequentStopsEntries >>>", frequentStopsEntries);
  const frequentStops = frequentStopsEntries.map(item => {
    return {
      type: item[0],
      value: item[1].length,
    }
  })
  console.log("frequentStops >>>", frequentStops);

  function handleSorting() {
    //frequentStops Sortion
    let sort = frequentStops.sort(function (a, b) {
      let valueA = a.value;
      let valueB = b.value;
      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
  }
  handleSorting()

  // // // //   Frquent Stops end  =================================================================
  // // // //   Frquent Stops end  =================================================================



  return (
    <>
      {/* <ToolBar /> */}
      <div className="toolbar py-2">
        {/*begin::Container*/}
        <div className="container-fluid d-flex align-items-center flex-wrap mx-1">
          <div className="row w-100">
            <div className="col-sm-4 px-0">
              <div className="page-title d-flex align-items-center flex-wrap me-3 mb-lg-0 h-100">
                {/*begin::Title*/}
                <div className="h2 d-flex flex-wrap align-items-center text-dark fw-bolder my-1 fs-3 h-100 fs-xs-12">
                  DASHBOARD
                  {/*begin::Separator*/}
                  <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                  {/*end::Separator*/}
                  {/*begin::Description*/}
                  <small className="text-muted fs-7 fw-bold my-1 ms-1">
                    {/* #Description */}
                  </small>
                  {/*end::Description*/}
                </div>
                {/*end::Title*/}
              </div>
            </div>
            {/* <div className="w-75"> */}
            {/*begin::Action group*/}
            <div className="col-sm-8 px-0">
              {/*begin::Wrapper*/}
              <ul className="nav d-flex align-items-center w-100">
                <div className="row w-100">
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 py-2">
                    <div className="d-flex justify-content-between align-items-center h-100">
                      {/*begin::Daterangepicker*/}
                      <li className="nav-item px-1 w-50">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <DatePicker
                              disableFuture
                              label="Start Date"
                              openTo="day"
                              // views={['year', 'month', 'day']}
                              value={startDate}
                              onChange={handleChangeOne}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </li>
                      <li className="nav-item px-1 w-50">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <DatePicker
                              disableFuture
                              label="End Date"
                              // inputFormat="MM/dd/yyyy"
                              openTo="day"
                              value={endDate}
                              onChange={handleChangeTwo}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </li>
                      {/*end::Daterangepicker*/}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 py-2">
                    <div className="row d-flex align-items-center justify-content-between">

                      <div className="col-lg-8 col-md-8 col-sm-8 col-8 py-2">
                        <div className="d-flex align-items-center justify-content-around w-100">
                          <li className="nav-item px-1">
                            <a
                              className="btn btn-sm btn-primary  cursor-pointer"
                              onClick={handleShowShift}
                            >
                              Select Shift
                            </a>
                            {/*begin::Modal - Add task*/}
                            <Modal show={showShift} onHide={handleCloseShift}>
                              {/*begin::Modal body*/}
                              <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
                                {/*begin::Form*/}
                                <form
                                  className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                  onSubmit={handleShift}
                                >
                                  {/*begin::Scroll*/}
                                  <div
                                    className="d-flex flex-column scroll-y me-n7 pe-7"
                                  // style={{ maxHeight: "422px" }}
                                  >
                                    <div className="row g-9 mb-8">
                                      {/*begin::Col*/}
                                      <div className="col-md-12 fv-row">
                                        <div className="d-flex justify-content-around align-items-center">

                                          <label className="required fs-6 fw-bold mb-2">
                                            Shift Name
                                          </label>
                                          {shiftsData?.map((shift) => (
                                            <div className="form-check form-control-color">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={shift.shiftName}
                                                name={shift.shiftName}
                                                value={shift.shiftName}
                                                onChange={(e) => handleSetShiftName(e)}
                                              />
                                              <label htmlFor={shift.shiftName}>{shift.shiftName}</label>
                                            </div>
                                          ))}

                                        </div>
                                      </div>
                                      {/*end::Col*/}
                                    </div>

                                  </div>
                                  {/*end::Scroll*/}
                                  {/*begin::Actions*/}
                                  <div className="text-center pt-15">
                                    <button
                                      type="reset"
                                      className="btn btn-light me-3"
                                      onClick={handleCloseShift}
                                    >
                                      Discard
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      <span className="indicator-label">Add</span>
                                      {/* <span className="indicator-progress">
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                      </span> */}
                                    </button>
                                  </div>
                                  {/*end::Actions*/}
                                  <div />
                                </form>
                                {/*end::Form*/}
                              </Modal.Body>
                              {/*end::Modal body*/}
                            </Modal>
                            {/*end::Modal - Add task*/}
                          </li>
                          <li className="nav-item px-1">
                            <a
                              className="btn btn-sm btn-primary cursor-pointer"
                              onClick={handleShowMachine}
                            >
                              {/* Select Machine */}
                              Select Machine
                            </a>
                            {/*begin::Modal - Add task*/}
                            <Modal show={showMachine} onHide={handleCloseMachine}>
                              {/*begin::Modal body*/}
                              <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
                                {/*begin::Form*/}
                                <form
                                  className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                  onSubmit={handleMachine}
                                >
                                  {/*begin::Scroll*/}
                                  <div
                                    className="d-flex flex-column scroll-y me-n7 pe-7"
                                  // style={{ maxHeight: "422px" }}
                                  >
                                    <div className="row g-9 mb-8">
                                      {/*begin::Col*/}
                                      <div className="col-md-12 fv-row">
                                        <div className="d-flex justify-content-around align-items-center">

                                          <label className="required fs-6 fw-bold mb-2">
                                            Machine Name
                                          </label>
                                          {machineDeviceData?.map((device) => (
                                            <div className="form-check form-control-color">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={device.deviceName}
                                                name={device.deviceName}
                                                value={device.deviceName}
                                                onChange={(e) => handleSetDeviceName(e)}
                                              />
                                              <label htmlFor={device.deviceName}>{device.deviceName}</label>
                                            </div>
                                          ))}

                                        </div>
                                      </div>
                                      {/*end::Col*/}
                                    </div>

                                  </div>
                                  {/*end::Scroll*/}
                                  {/*begin::Actions*/}
                                  <div className="text-center pt-15">
                                    <button
                                      type="reset"
                                      className="btn btn-light me-3"
                                      onClick={handleCloseMachine}
                                    >
                                      Discard
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      <span className="indicator-label">Add</span>
                                      {/* <span className="indicator-progress">
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                      </span> */}
                                    </button>
                                  </div>
                                  {/*end::Actions*/}
                                  <div />
                                </form>
                                {/*end::Form*/}
                              </Modal.Body>
                              {/*end::Modal body*/}
                            </Modal>
                            {/*end::Modal - Add task*/}
                          </li>

                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4 py-2">
                        <div className="d-flex align-items-center justify-content-around w-100">
                          {/*begin::Actions*/}

                          <li className="nav-item px-1">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary cursor-pointer mx-1"
                              onClick={handleSearch}
                              disabled={isLoading}
                            >
                              <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                          </li>
                          <li className="nav-item px-1">
                            <button
                              type="reset"
                              className="btn btn-sm btn-danger cursor-pointer mx-1"
                              onClick={handleClearFilter}
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </button>
                          </li>

                          {/*end::Actions*/}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </ul>
              {/*end::Wrapper*/}

            </div>
            {/*end::Action group*/}
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/* end: Toolbar */}
      {isLoading ? <LoadingSpinner /> :
        (
          <div className="content d-flex flex-column flex-column-fluid">
            {/*begin::Container*/}
            <div className="container-xxl">
              {/*begin::Row*/}
              <div className="row g-5 g-xl-8">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  {/*begin::Overall Widget 1*/}
                  <WidgetOne
                    value={oee ? oee.toFixed(2) : 0}
                    title={"Over All"}
                    icon={OVERALL_ICON}
                    desc={oee ? (oee).toFixed(2) + " %" : 0 + " %"}
                    bg={"bg-danger"}
                    subTitleOne={"Availablity"}
                    subTitleOneValue={availablity ? (availablity).toFixed(2) + " %" : 0 + " %"}
                    subTitleTwo={"Performance"}
                    // subTitleTwoValue={performance ? (performance).toFixed(2) + " %" : 0 + " %"}
                    subTitleTwoValue={performanceGauge ? (performanceGauge).toFixed(2) + " %" : 0 + " %"}
                    subTitleThree={"Quality"}
                    subTitleThreeValue={quality ? (quality).toFixed(2) + " %" : 0 + " %"}
                  />
                  {/*end::Overall Widget 1*/}
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  {/*begin::Performance Widget 1*/}
                  <WidgetOne
                    value={performanceGauge ? performanceGauge.toFixed(2) : 0}
                    title={"Performance"}
                    icon={PERFORMANCE_ICON}
                    desc={performanceGauge ? (performanceGauge).toFixed(2) + " %" : 0 + " %"}
                    bg={"bg-warning"}
                    // bg={"bg-purple"}
                    subTitleOne={"Total Pieces"}
                    subTitleOneValue={productCountTotal ? (productCountTotal).toFixed(2) + " Pcs" : 0 + " Pcs"}
                    subTitleTwo={"Operating Time"}
                    subTitleTwoValue={plannedHours ? (plannedHours - plannedDownTime - unplannedDownTime).toFixed(2) + " Min" : 0 + " Min"}
                    subTitleThree={"Ideal Run Rate"}
                    subTitleThreeValue={idealRunRate ? (idealRunRate).toFixed(2) + " Pcs/Min" : 0 + " Pcs/Min"}
                  />
                  {/*end::Performance Widget 1*/}
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  {/*begin::Availablity Widget 1*/}
                  <WidgetOne
                    value={availablity ? availablity.toFixed(2) : 0}

                    title={"Availablity"}
                    icon={AVAILABLITY_ICON}
                    desc={availablity ? (availablity).toFixed(2) + " %" : 0 + " %"}
                    bg={"bg-primary"}
                    subTitleOne={"Operating Time"}
                    subTitleOneValue={plannedHours ? (plannedHours - plannedDownTime - unplannedDownTime).toFixed(2) + " Min" : 0 + " Min"}
                    subTitleTwo={"Planned Production Time"}
                    subTitleTwoValue={plannedHours ? (plannedHours - plannedDownTime).toFixed(2) + " Min" : 0 + " Min"}
                  />
                  {/*end::Availablity Widget 1*/}
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  {/*begin::Quality Widget 1*/}
                  <WidgetOne
                    value={quality ? quality.toFixed(2) : 0}

                    title={"Quality"}
                    icon={QUALITY_ICON}
                    desc={quality ? (quality).toFixed(2) + " %" : 0 + " %"}
                    bg={"bg-success"}
                    subTitleOne={"Total Pieces"}
                    subTitleOneValue={productCountTotal ? (productCountTotal).toFixed(2) + " Pcs" : 0 + " Pcs"}
                    subTitleTwo={"Scrap"}
                    subTitleTwoValue={waste ? (waste).toFixed(2) + " Pcs" : 0 + " Pcs"}
                  />
                  {/*end::Quality Widget 1*/}
                </div>
              </div>
              {/*end::Row*/}
              {/*begin::Row*/}
              <div className="row gy-5 py-2 g-xl-8">
                {/*begin::Col*/}
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                  {/*begin::OEE by Shift Widget*/}
                  {/* <div className="my-2">
                    <OEEByShift />
                  </div> */}
                  {/*end::OEE by Shift Widget*/}

                  {/*begin::Mixed Widget 2*/}
                  <WidgetTwo
                    shippableValue={shippableProducts ? shippableProducts.toFixed(2) : 0}
                    availbleMinutes={availableHours}
                    plannedMinutes={plannedHours ? plannedHours.toFixed(2) : 0}
                    shutdownMinutes={availableHours ? (availableHours - plannedHours).toFixed(2) : 0}
                    upTime={totalUpTime ? totalUpTime.toFixed(2) : 0}
                    unplannedDownTime={unplannedDownTime ? unplannedDownTime.toFixed(2) : 0}
                    unplannedStops={unplannedStopsCount ? unplannedStopsCount.toFixed(2) : 0}
                    plannedDownTime={plannedDownTime ? plannedDownTime.toFixed(2) : 0}
                    plannedStops={plannedStopsCount ? plannedStopsCount.toFixed(2) : 0}
                    totalStops={plannedStopsCount ? (plannedStopsCount + unplannedStopsCount).toFixed(2) : 0}
                    totalDownTime={unplannedDownTime ? (unplannedDownTime + plannedDownTime).toFixed(2) : 0}
                    totalWaste={scrapPercent ? scrapPercent.toFixed(2) : 0}
                    wolfOneDownTime={wolf1Downtime ? wolf1Downtime.toFixed(2) : 0}
                    wolfTwoDownTime={wolf2Downtime ? wolf2Downtime.toFixed(2) : 0}
                    wolfThreeDownTime={wolf3Downtime ? wolf3Downtime.toFixed(2) : 0}
                    technicalStops={totalTechnicalStops ? totalTechnicalStops.toFixed(2) : 0}
                    operationalStops={totalOperationalStops ? totalOperationalStops.toFixed(2) : 0}
                    technicalStopsTime={totalTechnicalStopsTime ? totalTechnicalStopsTime.toFixed(2) : 0}
                    operationalStopsTime={totalOperationalStopsTime ? totalOperationalStopsTime.toFixed(2) : 0}
                    mttr={MTTR ? MTTR.toFixed(2) : 0}
                    mtbf={MTBF ? MTBF.toFixed(2) : 0}
                  />
                  {/*end::Mixed Widget 2*/}

                  {/*begin::Mixed Widget 3*/}
                  {/* <WidgetThree /> */}
                  {/*end::Mixed Widget 3*/}
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                  {/*begin::Charts Widget*/}
                  <div className="h-100">

                    <div className="h-50">
                      {/* <TopThreeStops data={stateTop3} /> */}
                      <div className="card">
                        <h3 className="text-center pt-4">
                          Top Three Stops
                        </h3>
                        <div className="p-5 top-three-stops-chart">
                          <TopThreeStopsComp data={topThreeStopsChart} />
                        </div>
                      </div>
                    </div>
                    <div className="h-50">
                      <div className="my-1">
                        {" "}
                        {/* <OEELossChart
                    data={oeeGraph}
                    /> */}

                        <div className="card">
                          <h3 className="text-center pt-4">
                            OEE Loss Chart
                          </h3>
                          <div className="p-5 oee-loss-chart">
                            <OEELossChartComp data={oeeGraph} />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  {/*end::Charts Widget*/}
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                  {/* begin::Machine Stop Chart Widget*/}
                  {/* <MachineStopChart data={stateStopsReasonTime} /> */}
                  {/*end::Machine Stop Chart Widget */}
                  <div className="h-100">

                    <div className="h-50">
                      {/* <TopThreeStops data={stateTop3} /> */}
                      <div className="card">
                        <h3 className="text-center pt-3">
                          {/* Machine Stops With Cause One */}
                          {stopsReasonTime &&
                            `Machine Stop Count: ${DMStopsArr.reduce((a, b) => a + b, 0)} | Time: ${(stopsReasonTime.map(item => item[1] / 60).reduce((a, b) => a + b, 0)).toFixed(2)} Mins`
                          }
                        </h3>
                        <div className="px-5 machine-stops-chart">
                          <MachineStopChartReact data={pieChartForStopsReasonTime} />
                        </div>
                      </div>
                    </div>
                    <div className="h-50">
                      <div className="my-1">
                        <div className="card">
                          <h3 className="text-center pt-4">
                            Top Frquent Stops
                          </h3>
                          <div className="p-5 top-three-stops-chart">
                            <TopFrequentStops data={frequentStops} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="card card-xl-stretch mb-xl-8">
                        <h1 className="text-center pt-3">
                          {stopsReasonTime &&
                            `Machine Stop Count: ${DMStopsArr.reduce((a, b) => a + b, 0)} | Time: ${(stopsReasonTime.map(item => item[1] / 60).reduce((a, b) => a + b, 0)).toFixed(2)} Mins`
                          }
                        </h1>
                        <div className="d-flex align-content-center justify-content-center ">
                          <div className="pt-2 w-75">
                            <MachineStopChartReact data={pieChartForStopsReasonTime} />
                          </div>
                        </div>
                      </div> */}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row gy-5 py-2 g-xl-8">
                {/*begin::Col*/}
                <div className="col-xl-12 ">
                  <StopsBreakdown data={stateStopsBreakDown} />
                  {/* <StopsBreakdown data={stopsBreakDownChart} /> */}
                </div>
              </div>
              {/*end::Row*/}
              {/*begin::Row*/}
              {filterOrdersData.length >= 1 &&
                <div className="row gy-5 py-2 g-xl-8">
                  {/*begin::Col*/}
                  <div className="col-xl-12 ">
                    {/* <ReactMaterialTable data={filterOrdersData} title={"Complete Orders"} /> */}
                    <ReactMaterialTable data={filterUniqueOrdersData} title={"Complete Orders"} />
                  </div>
                  {/*end::Col*/}

                </div>
              }
              {/*end::Row*/}
            </div>
            {/*end::Container*/}
          </div>
        )
      }


    </>
  );
};

export default Dashboard;
