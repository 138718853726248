import React from "react";
import Chart from "react-apexcharts";

import { Column, Bar, Pie } from '@ant-design/plots';

export const TopThreeStopsComp1 = ({ data }) => {
  const config = {
    data,
    xField: 'type',
    yField: 'time',
    label: {
      // label 
      position: 'middle',
      // 'top', 'bottom', 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: 'type',
      },
      time: {
        alias: 'time',
      },
    },
  };
  return <Column {...config} />;
};


export const TopThreeStopsComp = ({ data }) => {
  const config = {
    // appendPadding: 5,
    data,
    angleField: 'time',
    colorField: 'type',
    radius: 1,
    startAngle: Math.PI,
    endAngle: Math.PI * 1.5,
    xAxis: false,
    yAxis: false,
    legend: false,
    label: {
      position: 'bottom',
      // 'top', 'bottom', 'middle',
      type: 'inner',
      offset: '-8%',
      content: '{name}',
      style: {
        fontSize: 10,
      },
    },
    // label: false,
    interactions: [
      {
        type: 'element-active',
      },
    ],
    pieStyle: {
      lineWidth: 2,
    },
    pattern: {
      type: '',
      cfg: {
        size: 2,
        padding: 4,
        rotation: 0,
        fill: '#FFF',
        isStagger: true,
      },
    },
  };
  return <Pie {...config} />;
};

const TopThreeStops = ({ data }) => {
  const dummyData = {
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: [1991, 1992, 1993],
      },
      title: {
        text: "Top Three Stops",
        align: "center",
        floating: true,
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 35],
      },
    ],
  };
  return (
    <div className="card">
      <div className="pt-2">
        <Chart
          options={data ? data.options : dummyData.options}
          series={data ? data.series : dummyData.series}
          type="bar"
          width="100%"
          height={200}
        />
      </div>
    </div>
  );
};

export default TopThreeStops;



