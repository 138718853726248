import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import MaterialTable from "material-table";
import ToolBar from "../../components/ToolBar/ToolBar";
import { useDispatch, useSelector } from "react-redux";
import {
  // getCurruntProductionView, 
  getCurruntStatus,
  // handleInsertCompleteOrdersAndStops 
} from "../../store/redux-apis/curruntStatusApiCalls";
import './CurruntStatus.css'
// import { baseURL } from "../../core";
// import axios from "axios";
// import _ from 'lodash'

// const headerStyleForMT = {
//   color: "#A1A5B7 ",
//   borderBottom: "#E4E6EF",
//   borderBottomWidth: "1px",
//   borderBottomStyle: "dashed",
//   fontWeight: "600",
//   fontSize: "16px",
// }

const CurruntStatus = () => {

  //Get Current Status Data
  const curruntStatusData = useSelector((state) => state.curruntStatus.curruntData.curruntStatus?.data);
  // console.log("curruntStatusData >>>", curruntStatusData);
  const curruntStatusDataForMT = curruntStatusData?.map((d) => ({ ...d }));
  const lastFourRows = curruntStatusDataForMT?.slice(0, 4)
  // console.log("lastFourRows", lastFourRows);

  //states
  const intervalRefCurruntData = useRef(null);
  const intervalRef = useRef(null);
  const ref = useRef(null)
  console.log("ref >>>>", ref);
  const [deinst2Status, setDeinst2Status] = useState(false);
  const [wolf3Status, setwolf3Status] = useState(false);
  const [wolf2Status, setwolf2Status] = useState(false);
  const [wolf1Status, setwolf1Status] = useState(false);
  console.log("deinst2Status", deinst2Status);
  console.log("wolf3Status", wolf3Status);
  console.log("wolf2Status", wolf2Status);
  console.log("wolf1Status", wolf1Status);



  // useEffect(() => {
  //   const handleInserStopsAndCompleteOrders = async () => {
  //     const inserStopsAndCompleteOrders = await axios.get(
  //       `${baseURL}/deviceData/getDeviceDataAndEditCompleteOrdersAndInsertStops`
  //     );
  //     console.log("inserStopsAndCompleteOrders >>>", inserStopsAndCompleteOrders)
  //   }
  //   // setTimeout(() => {
  //   console.log("inserStopsAndCompleteOrders >>>");
  //   handleInserStopsAndCompleteOrders()
  //   // }, 1000000);
  // }, [])

  const dispatch = useDispatch();
  useEffect(() => {
    clearInterval(intervalRefCurruntData.current)
    intervalRefCurruntData.current = setInterval(() => {
      getCurruntStatus(dispatch);
    }, 30000);
    return () => {
      clearInterval(intervalRefCurruntData.current)
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("top");
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      console.log("setInterval", intervalRef.current);
      if (lastFourRows?.length > 0) {
        console.log("lastFourRows?.length >>>", lastFourRows?.length);
        const deinst2Row = lastFourRows && lastFourRows[0]
        const wolf3Row = lastFourRows && lastFourRows[1]
        const wolf2Row = lastFourRows && lastFourRows[2]
        const wolf1Row = lastFourRows && lastFourRows[3]
        if (deinst2Row.deviceName === "Denist2" && deinst2Row.deviceStatus === 0) {
          console.log("not active");
          setDeinst2Status(true)
        } else if (deinst2Row.deviceName === "Denist2" && deinst2Row.deviceStatus === 1) {
          console.log("active");
          setDeinst2Status(false)
        }
        if (wolf3Row.deviceName === "Wolf3" && wolf3Row.deviceStatus === 0) {
          setwolf3Status(true)
        } else if (wolf3Row.deviceName === "Wolf3" && wolf3Row.deviceStatus === 1) {
          setwolf3Status(false)
        }
        if (wolf2Row.deviceName === "Wolf2" && wolf2Row.deviceStatus === 0) {
          setwolf2Status(true)
        } else if (wolf2Row.deviceName === "Wolf2" && wolf2Row.deviceStatus === 1) {
          setwolf2Status(false)
        }
        if (wolf1Row.deviceName === "Wolf1" && wolf1Row.deviceStatus === 0) {
          setwolf1Status(true)
        } else if (wolf1Row.deviceName === "Wolf1" && wolf1Row.deviceStatus === 1) {
          setwolf1Status(false)
        }
      }
    }, 3000);
    console.log("intervalRef.current >>>", intervalRef.current);
    console.log("outside");
    return () => {
      console.log("clearInterval");
      clearInterval(intervalRef.current)
    }
    // }, [deinst2Status, wolf1Status, wolf2Status, wolf3Status, lastFourRows])
  }, [lastFourRows, dispatch])


  return (
    <>
      {/* begin: Toolbar */}
      <ToolBar />
      {/* end: Toolbar */}
      {/* Currunt Status Table ======================================> */}
      <div className="content d-flex flex-column flex-column-fluid">
        {/*begin::Container*/}
        <div className="container-xxl">
          {/*begin::Row*/}
          <div className="card">
            {lastFourRows?.length > 0 ?
              <div className="card-header border-0 pt-6 d-flex justify-content-center w-100">
                {/*begin::Card title*/}
                <div className="card-title d-flex justify-content-around">
                  <Button
                    className="btn btn-success"
                    variant={wolf1Status ? "danger" : "success"}
                  >
                    WOLF1
                  </Button>
                </div>
                {/*begin::Card title*/}
                {/*begin::Card title*/}
                <div className="card-title d-flex justify-content-around">
                  <Button
                    className="btn btn-success"
                    variant={wolf2Status ? "danger" : "success"}
                  >
                    WOLF2
                  </Button>
                </div>
                {/*begin::Card title*/}
                {/*begin::Card title*/}
                <div className="card-title d-flex justify-content-around">
                  <Button
                    className="btn btn-success"
                    variant={wolf3Status ? "danger" : "success"}
                  >
                    WOLF3
                  </Button>
                </div>
                {/*begin::Card title*/}
                {/*begin::Card title*/}
                <div className="card-title d-flex justify-content-around">
                  <Button
                    className="btn btn-success"
                    variant={deinst2Status ? "danger" : "success"}
                  >
                    DEINST2
                  </Button>
                </div>
                {/*begin::Card title*/}

              </div>
              :
              null
            }

            <div className="material-table__box">
              <MaterialTable
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <h1
                        style={{
                          height: "60vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {/* <CircularProgress /> */}
                        <span className="indicator-progress" >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </h1>
                    ),
                  },
                }}
                style={{
                  zIndex: 0,
                }}
                title="Currunt Machine Status"
                columns={[
                  { title: "Device Name", field: "deviceName", editable: "never" },
                  {
                    title: "Device Status", field: "deviceStatus", editable: "never"
                    ,
                    render: (rowData) => {
                      // console.log("curruntStatusData >> ", rowData);
                      return (
                        <span style={{ display: 'flex' }} >
                          {rowData.deviceStatus === 0 ? <div className="badge badge-light-danger px-2 py-1 rounded" >Off</div> : <div className="badge badge-light-success px-2 py-1 rounded" >On</div>}
                        </span>
                      );
                    }
                  },
                  { title: "PO Number", field: "poNumber", editable: "never" },
                  { title: "SKU", field: "skuName", editable: "never", },
                  { title: "Team", field: "teamName", editable: "never" },
                  { title: "Timestamp", field: "timestamp", editable: "never" },
                ]}
                data={curruntStatusDataForMT}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: false,
                  showTitle: false,
                  filtering: false,
                  search: false,
                  toolbar: false,
                  pageSize: 10,
                  pageSizeOptions: [
                    5,
                    10,
                    20,
                    { value: curruntStatusDataForMT?.length, label: "All" },
                  ],
                  headerStyle: {
                    color: "#FFF ",
                    borderBottom: "#E4E6EF",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dashed",
                    backgroundColor: "#14a6f5a4",
                    fontWeight: "600",
                    fontSize: "16px",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                    borderRadius: "5px",
                  },
                }}
              />
            </div>
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Container*/}
      </div>
    </>
  );
};

export default CurruntStatus;
