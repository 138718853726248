import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import MaterialTable from "material-table";
import ToolBar from "../../components/ToolBar/ToolBar";

import {
  createOrder,
  updateOrder,
  deleteOrder,
  getOrder,
} from "../../store/redux-apis/orderApi";

// import Form from "react-bootstrap/Form";

// import DateTimePicker from "react-datetime-picker";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getLines } from "../../store/redux-apis/lineApiCalls";
import { getSku } from "../../store/redux-apis/skuApi";
import { getTeam } from "../../store/redux-apis/teamApiCalls";
import { getDistributer } from "../../store/redux-apis/distributerApiCalls";
import DatePicker from "react-datepicker";
// import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import { getCurruntStatus } from "../../store/redux-apis/curruntStatusApiCalls";


const Orders = () => {


  //Get Current Status Data
  const curruntStatusData = useSelector((state) => state.curruntStatus.curruntData.curruntStatus?.data);
  // console.log("curruntStatusData >>>", curruntStatusData);
  const curruntStatusDataForMT = curruntStatusData?.map((d) => ({ ...d }));
  const curruntPO = curruntStatusDataForMT && curruntStatusDataForMT[0]?.poNumber;
  // console.log("curruntPO >>>", curruntPO);
  //getting all data for dropdowns
  const linesData = useSelector((state) => state.line.lines?.data);
  // console.log("lineData >>>", linesData);
  const skuData = useSelector((state) => state.sku.skus?.data);
  const orderData = useSelector((state) => state.order.orders?.data);
  // console.log("orderData", orderData);
  const orderForMT = orderData?.map((o) => ({ ...o }));

  const filterOrdersDataByActiveOrder = []
  orderForMT?.forEach(element => {
    if (curruntPO == element.poNumber) {
      // console.log("true");
      filterOrdersDataByActiveOrder.push({
        id: element.id,
        poNumber: element.poNumber,
        status: element.status,
        expectedCount: element.expectedCount,
        plannedStart: element.plannedStart,
        plannedEnd: element.plannedEnd,
        deviceName: element.deviceName,
        skuName: element.skuName,
        skuTarget: element.skuTarget,
        distributerName: element.distributerName,
        teamName: element.teamName,
        createdBy: element.createdBy,
        timestamp: element.timestamp,
        active: true,
      });
    } else {
      filterOrdersDataByActiveOrder.push(element);
    }
  });
  // console.log("filterOrdersDataByActiveOrder >>>", filterOrdersDataByActiveOrder);

  const allDistributer = useSelector(
    (state) => state.distributer.distributers?.data
  );
  const teamsData = useSelector((state) => state.team.teams?.data);

  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({
    poNumber: "",
    deviceName: "",
    status: "1",
    expectedCount: "",
    plannedStart: new Date(),
    skuName: "BOMBAY BIRYANI MASALA EXPORT",
    teamName: "A",
    distributerName: "Nuavo Foods # 160",
    createdBy: "Alpha",
    timestamp: new Date()
  })
  const [selectedDevice, setSelectedDevice] = useState([])
  // console.log("selectedDevice >>>", selectedDevice);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  // for (let i = 0; i < userData.length; i++) {
  //   userData[i].plannedStart = moment(userData[i].plannedStart).format("DD MMM YYYY hh:mm a")
  // }

  const handleSetDeviceName = (e) => {
    if (
      e.target.checked &&
      // !deviceNameList.includes(e.target.value) &&
      !selectedDevice.includes(e.target.value)
    ) {
      // deviceNameList.push(e.target.value);
      setSelectedDevice((preValue) => [...preValue, e.target.value]);
    } else if (!e.target.checked) {
      // deviceNameList = deviceNameList.filter(
      //   (device) => device !== e.target.value
      // );
      setSelectedDevice(selectedDevice.filter((device) => device !== e.target.value))
    }
    // console.log("deviceNameList >>>", deviceNameList);
    // console.log(e.target.checked);
    // console.log(typeof e.target.value);
  }




  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("userData", userData);
    let submitUserData = {
      poNumber: userData.poNumber,
      deviceNameList: selectedDevice,
      status: userData.status,
      expectedCount: userData.expectedCount,
      plannedStart: userData.plannedStart,
      skuName: userData.skuName,
      teamName: userData.teamName,
      distributerName: userData.distributerName,
      createdBy: userData.createdBy,
      timestamp: userData.timestamp
    }
    // console.log("submitUserData before >>>", submitUserData);
    // console.log("condition >>>", submitUserData.poNumber && submitUserData.deviceNameList && submitUserData.expectedCount);
    if (submitUserData.poNumber && submitUserData.deviceNameList && submitUserData.expectedCount) {
      // console.log("inside submitUserData");
      createOrder(dispatch, submitUserData)
      submitUserData = {}
      // console.log("submitUserData after >>>", submitUserData);

      setTimeout(() => {
        getOrder(dispatch);
      }, 1000);
      handleClose();
      setUserData({
        poNumber: "",
        deviceName: "",
        status: "1",
        expectedCount: "",
        plannedStart: new Date(),
        skuName: "BOMBAY BIRYANI MASALA EXPORT",
        teamName: "A",
        distributerName: "Nuavo Foods # 160",
        createdBy: "Alpha",
        timestamp: new Date()
      })
    }
  };


  const tableColumns = [
    { title: "DeviceName", field: "deviceName", editable: "never" },
    { title: "PONumber", field: "poNumber", type: "numeric", editable: "never" },
    {
      title: "Status",
      field: "status",
      render: (row) => {
        // console.log("row >>>", row);
        return (
          <div>
            {row.active === true ? <div className="badge badge-light-success text-center text-success rounded-1 fw-bolder">
              Active
            </div> : <div className="status">
              {row.status}
            </div>}
          </div>
        )
      }
    },
    { title: "ExpectedCount", field: "expectedCount", type: "numeric" },
    { title: "PlannedStart", field: "plannedStart" },
    { title: "PlannedEnd", field: "plannedEnd" },
    { title: "SkuName", field: "skuName", },
    { title: "TeamName", field: "teamName", },
    { title: "DistributerName", field: "distributerName", },
    { title: "CreatedTime", field: "timestamp", editable: "never" },
    { title: "CreatedBy", field: "createdBy" },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    getLines(dispatch);
    getSku(dispatch);
    getTeam(dispatch);
    getDistributer(dispatch);
    getOrder(dispatch)
    getCurruntStatus(dispatch);
  }, [dispatch]);

  return (
    <>
      {/* begin: Toolbar */}
      <ToolBar />
      {/* end: Toolbar */}
      <div className="content d-flex flex-column flex-column-fluid">
        {/*begin::Container*/}
        <div className="container-xxl">
          {/*begin::Row*/}
          <div className="card">
            <div className="card-header border-0 pt-6 d-flex justify-content-between">
              {/*begin::Card title*/}
              <div className="card-title">
                {/*begin::Search*/}
                <div className="d-flex align-items-center position-relative my-1">
                  {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}

                </div>
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Toolbar*/}
                <div className="d-flex justify-content-end">
                  {/*begin::Add Order*/}
                  <Button
                    variant="primary"
                    className="btn btn-primary"
                    onClick={handleShow}
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="11.364"
                          y="20.364"
                          width={16}
                          height={2}
                          rx={1}
                          transform="rotate(-90 11.364 20.364)"
                          fill="currentColor"
                        />
                        <rect
                          x="4.36396"
                          y="11.364"
                          width={16}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}Add Order
                  </Button>

                  {/*end::Add Order*/}
                </div>
                {/*end::Toolbar*/}
                {/*begin::Modal - Add task*/}
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  {/*begin::Modal body*/}
                  <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
                    {/*begin::Form*/}
                    <form
                      className="form fv-plugins-bootstrap5 fv-plugins-framework"
                      onSubmit={handleSubmit}
                    >
                      {/*begin::Scroll*/}
                      <div
                        className="d-flex flex-column scroll-y me-n7 pe-7"
                        style={{ maxHeight: "422px" }}
                      >
                        <div className="row g-9 mb-8">
                          {/*begin::Col*/}
                          <div className="col-md-12 fv-row">
                            <label className="required fs-6 fw-bold mb-2">
                              Line Name
                            </label>
                            {linesData?.map((d) => (
                              <div className="form-check form-control-color">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={d.deviceName}
                                  name={d.deviceName}
                                  value={d.deviceName}
                                  onChange={(e) => handleSetDeviceName(e)}
                                // onChange={(e) => {
                                //   if (
                                //     e.target.checked &&
                                //     !deviceNameList.includes(e.target.value)
                                //   ) {
                                //     deviceNameList.push(e.target.value);
                                //   } else if (!e.target.checked) {
                                //     deviceNameList = deviceNameList.filter(
                                //       (device) => device !== e.target.value
                                //     );
                                //   }
                                //   // setSelectedDevice((preData) => [...preData, deviceNameList])
                                //   // setSelectedDevice((preData) => [...preData, deviceNameList])
                                //   console.log("deviceNameList >>>", deviceNameList);
                                //   console.log(e.target.checked);
                                //   console.log(typeof e.target.value);
                                // }}
                                />
                                <label htmlFor={d.deviceName}>{d.deviceName}</label>
                              </div>
                            ))}

                          </div>
                          {/*end::Col*/}
                        </div>
                        {/*begin::Input group*/}
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            PO Number
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="number"
                            name="poNumber"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="PO Number"
                            defaultValue=""
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                poNumber: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Expected Count
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="number"
                            name="expectedCount"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="Expected Count"
                            defaultValue=""
                            value={userData.expectedCount}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                expectedCount: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Planned Start
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <div>
                            <DatePicker
                              selected={userData.plannedStart}
                              onChange={(date) => setUserData({ ...userData, plannedStart: date })}
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          </div>
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        <div className="col-md-12 fv-row">
                          <label className="required fs-6 fw-bold mb-2">
                            Sku Name
                          </label>
                          <select
                            className="form-select form-select-solid"
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                skuName: e.target.value,
                              })
                            }
                            value={userData.skuName}
                          >
                            {skuData?.map((d) => {
                              return (
                                <option value={d.skuName}>{d.skuName}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-12 fv-row">
                          <label className="required fs-6 fw-bold mb-2">
                            Team Name
                          </label>
                          <select
                            className="form-select form-select-solid"
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                teamName: e.target.value,
                              })
                            }
                            value={userData.teamName}
                          >
                            {teamsData?.map((d) => {
                              return (
                                <option value={d.teamName}>{d.teamName}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-12 fv-row">
                          <label className="required fs-6 fw-bold mb-2">
                            Distributer Name
                          </label>
                          <select
                            className="form-select form-select-solid"
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                distributerName: e.target.value,
                              })
                            }
                            value={userData.distributerName}
                          >
                            {allDistributer?.map((d) => {
                              return (
                                <option value={d.distributerName}>
                                  {d.distributerName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fw-bold fs-6 mb-2">
                            Created By
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            name="poNumber"
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            placeholder="Created By"
                            defaultValue=""
                            value={userData.createdBy}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                createdBy: e.target.value,
                              })
                            }
                          />
                          {/*end::Input*/}
                          <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}

                        {/*end::Input group*/}
                      </div>
                      {/*end::Scroll*/}
                      {/*begin::Actions*/}
                      <div className="text-center pt-15">
                        <button
                          type="reset"
                          className="btn btn-light me-3"
                          onClick={handleClose}
                        >
                          Discard
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                        // onClick={handleClose}
                        // onClick={handleSubmit}
                        >
                          <span className="indicator-label" >Submit</span>
                          {/* <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span> */}

                        </button>
                      </div>
                      {/*end::Actions*/}
                      <div />
                    </form>
                    {/*end::Form*/}
                  </Modal.Body>
                  {/*end::Modal body*/}
                </Modal>
                {/*end::Modal - Add task*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            <div className="material-table__box">
              <MaterialTable
                style={{
                  zIndex: 0
                }}
                // title="Order Details"
                columns={tableColumns}
                // data={orderForMT}
                data={filterOrdersDataByActiveOrder}

                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        // const dataUpdate = [...orderForMT];
                        const dataUpdate = [...filterOrdersDataByActiveOrder];
                        const index = oldData.id;
                        // console.log("index >>>", index);
                        dataUpdate[index] = newData;
                        updateOrder(dataUpdate[index], dispatch);
                        getOrder(dispatch);
                        setShow(false);
                        resolve();
                      }, 1000);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const index = oldData.id;
                        // console.log("index >>>", index);
                        // alert("You want to delete " + oldData.deviceName);
                        deleteOrder(index, dispatch);
                        setShow(false);
                        getOrder(dispatch);
                        resolve();
                      }, 1000);
                    }),
                }}
                options={{
                  // actionsColumnIndex: -1,
                  exportButton: false,
                  exportCsv: (columns, data) => {
                    alert(
                      "You should develop a code to export " +
                      data.length +
                      " rows"
                    );
                  },
                  showTitle: false,
                  filtering: true,
                  search: false,
                  toolbar: false,
                  pageSizeOptions: [
                    5,
                    10,
                    20,
                    { value: filterOrdersDataByActiveOrder?.length, label: "All" },
                  ],
                  // pageSizeOptions: [5, 10, 20],
                  headerStyle: {
                    color: "#A1A5B7 ",
                    borderBottom: "#E4E6EF",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dashed",
                    fontWeight: "600",
                    fontSize: "16px",
                  },
                }}
              />{" "}
            </div>
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Container*/}
      </div>
    </>
  );
};

export default Orders;
