import { userRequest } from "../../api/requestMethods";
import {
  addTeamStart,
  addTeamSuccess,
  addTeamFailure,
  getTeamStart,
  getTeamFailure,
  getTeamSuccess,
  deleteTeamStart,
  deleteTeamSuccess,
  deleteTeamFailure,
  updateTeamFailure,
  updateTeamSuccess,
  updateTeamStart,
} from "../reducers/teamReducer";

export const createTeam = async (dispatch, user) => {
  dispatch(addTeamStart());
  try {
    console.log("user from team api", user);
    const res = await userRequest.post("team/createTeam", user);
    console.log("response team api", res);
    // localStorage.getItem("token");
    console.log("from team api", res.data);
    console.log("from team api", user);
    dispatch(addTeamSuccess(user));
  } catch (error) {
    console.log("error from catch block of add team", error);
    dispatch(addTeamFailure());
  }
};

export const getTeam = async (dispatch) => {
  dispatch(getTeamStart());
  try {
    const res = await userRequest.get("team/getTeam");
    console.log("response from get team function in teamAPi", res);
    localStorage.getItem("token");
    dispatch(getTeamSuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(getTeamFailure());
  }
};

export const deleteItem = async (id, dispatch) => {
  dispatch(deleteTeamStart());
  console.log("id from delete Item function", id);
  try {
    console.log(id);
    const res = await userRequest.post("team/deleteTeam", { id });
    console.log("response from delete team function in teamAPi", res);
    localStorage.getItem("token");
    dispatch(deleteTeamSuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteTeamFailure());
  }
};
export const updateTeam = async (userData, dispatch, id) => {
  console.log("userData", userData);
  dispatch(updateTeamStart());
  try {
    // const res = await userRequest.post("team/updateTeam", { userData, id });
    const res = await userRequest.post("team/updateTeam", userData);
    console.log("response from update team function in teamAPi", res);
    localStorage.getItem("token");
    dispatch(updateTeamSuccess(res.data));
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateTeamFailure());
  }
};
