import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';

import Chart from "react-apexcharts";


// const StopsBreakdown = ({ data }) => {
//   console.log("data >>>", data);

//   const config = {
//     data,
//     xField: 'type',
//     yField: 'value',
//     xAxis: {
//       label: {
//         autoRotate: true,
//       },
//     },
//     // slider: {
//     //   start: 0.1,
//     //   end: 0.2,
//     // },
//   };

//   return <Column {...config} />;
// };

// export default StopsBreakdown

const StopsBreakdown = ({ data }) => {
  const dummyData = {
    series: [
      {
        name: "Servings",
        data: [
          44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65, 35, 67, 22, 43, 21,
          33, 45, 31,
        ],
      },
    ],
    options: {
      title: {
        text: "All Stops Breakdown",
        align: "center",
        floating: true,
      },
      //   annotations: {
      //     points: [
      //       {
      //         x: "Bananas",
      //         seriesIndex: 0,
      //         label: {
      //           borderColor: "#775DD0",
      //           offsetY: 0,
      //           style: {
      //             color: "#fff",
      //             background: "#775DD0",
      //           },
      //           text: "Bananas are good",
      //         },
      //       },
      //     ],
      //   },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          "Apples",
          "Oranges",
          "Strawberries",
          "Pineapples",
          "Mangoes",
          "Bananas",
          "Blackberries",
          "Pears",
          "Watermelons",
          "Cherries",
          "Pomegranates",
          "Tangerines",
          "Papayas",
          "Mangoes",
          "Bananas",
          "Blackberries",
          "Pears",
          "Watermelons",
          "Cherries",
          "Pomegranates",
        ],
        tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "Servings",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100],
        },
      },
    },
  };
  return (
    <div className="card">
      <Chart
        options={data ? data.options : dummyData.options}
        series={data ? data.series : dummyData.series}
        type="bar"
        width="100%"
        height={400}
      />
    </div>
  );
};

export default StopsBreakdown;


