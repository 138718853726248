import { userRequest } from "../../api/requestMethods";
import {
  addShiftFailure,
  addShiftStart,
  addShiftSuccess,
  deleteShiftFailure,
  deleteShiftStart,
  deleteShiftSuccess,
  getShiftFailure,
  getShiftStart,
  getShiftSuccess,
  updateShiftFailure,
  updateShiftStart,
  updateShiftSuccess,
} from "../reducers/shiftReducer";

export const getShifts = async (dispatch) => {
  dispatch(getShiftStart());
  try {
    const res = await userRequest.get("/shift/getShift");
    // console.log("response from get Shift ==>", res);
    dispatch(getShiftSuccess(res.data));
  } catch (error) {
    console.log("error from shiftFailure >>>", error);
    dispatch(getShiftFailure());
  }
};

export const createShift = async (dispatch, inputData) => {
  dispatch(addShiftStart());
  try {
    const res = await userRequest.post("/shift/createShift", inputData);
    dispatch(addShiftSuccess(res.data));
  } catch (error) {
    console.log("error from shiftFailure >>>", error);
    dispatch(addShiftFailure());
  }
};

export const updateShift = async (inputData, dispatch) => {
  dispatch(updateShiftStart());
  try {
    const res = await userRequest.post("/shift/updateShift", inputData);
    dispatch(updateShiftSuccess(res.data));
  } catch (error) {
    console.log("error from shiftFailure >>>", error);
    dispatch(updateShiftFailure());
  }
};

export const deleteShift = async (id, dispatch) => {
  dispatch(deleteShiftStart());
  try {
    const res = await userRequest.post("/shift/deleteShift", { id });
    dispatch(deleteShiftSuccess(res.data));
  } catch (error) {
    console.log("error from shiftFailure >>>", error);
    dispatch(deleteShiftFailure());
  }
};
