import React, { useContext } from 'react'
import { GlobalContext } from '../../context/Context';

const Overview = () => {
    const { state } = useContext(GlobalContext);

    return (
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            {/*begin::Card header*/}
            <div className="card-header cursor-pointer">
                {/*begin::Card title*/}
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Profile Details</h3>
                </div>
                {/*end::Card title*/}
                {/*begin::Action*/}
                {/* <a href="javascript:void(0)" className="btn btn-primary align-self-center">Edit Profile</a> */}
                {/*end::Action*/}
            </div>
            {/*begin::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body p-9">
                {/*begin::Row*/}
                <div className="row mb-7">
                    {/*begin::Label*/}
                    <label className="col-lg-4 fw-semibold text-muted">Full Name</label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8">
                        <span className="fw-bold fs-6 text-gray-800">{state?.user?.userName}</span>
                    </div>
                    {/*end::Col*/}
                </div>
                {/*end::Row*/}
                {/*begin::Row*/}
                <div className="row mb-7">
                    {/*begin::Label*/}
                    <label className="col-lg-4 fw-semibold text-muted">Email</label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8">
                        <span className="fw-bold fs-6 text-gray-800">{state?.user?.email}</span>
                    </div>
                    {/*end::Col*/}
                </div>
                {/*end::Row*/}
                {/*begin::Input group*/}
                <div className="row mb-7">
                    {/*begin::Label*/}
                    <label className="col-lg-4 fw-semibold text-muted">User Role
                        <i className="fas fa-exclamation-circle ms-1 fs-7" /></label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 d-flex align-items-center">
                        <span className="fw-bold fs-6 text-gray-800 me-2">{state?.user?.userRole}</span>
                        <span className="badge badge-success">Verified</span>
                    </div>
                    {/*end::Col*/}
                </div>
                {/*end::Input group*/}
            </div>
            {/*end::Card body*/}
        </div>
    )
}

export default Overview