import { createSlice } from "@reduxjs/toolkit";

const lineSlice = createSlice({
  name: "line",
  initialState: {
    lines: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET LINE
    getLineStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getLineSuccess: (state, action) => {
      state.isFetching = false;
      state.lines = action.payload;
    },
    getLineFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE LINE
    deleteLineStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteLineSuccess: (state, action) => {
      state.isFetching = false;
      // state.lines.splice(
      //   // state.lines.data?.findIndex(
      //   state.lines.findIndex(
      //     //   (item) => item.id === action.payload.id
      //     (item) => item.id === action.payload
      //   ),
      //   1
      // );
    },
    deleteLineFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE LINE
    updateLineStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateLineSuccess: (state, action) => {
      state.isFetching = false;
      console.log("action.payload >>>",action.payload);
      // state.lines[
      //   state.lines.findIndex((item) => item.id === action.payload.id)
      // ] = action.payload.line;
    },
    updatelineFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD LINE
    addLineStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addLineSuccess: (state, action) => {
      state.isFetching = false;

      console.log("action.payload of line reducer",action.payload);
      state.lines.push(action.payload);
    },
    addLineFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getLineStart,
  getLineSuccess,
  getLineFailure,
  deleteLineStart,
  deleteLineSuccess,
  deleteLineFailure,
  updateLineStart,
  updateLineSuccess,
  updateLineFailure,
  addLineStart,
  addLineSuccess,
  addLineFailure,
} = lineSlice.actions;
export default lineSlice.reducer;
