import axios from "axios";
import { baseURL } from "../core/index";

export const TOKEN = localStorage.getItem("token");
// console.log("token >>>", TOKEN);
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyOGEwZGViYmUyMmQ0MTRmNTNlMzMzNiIsImlzQWRtaW4iOnRydWUsImVtYWlsIjoiYWRtaW5AdGVzdC5jb20iLCJpYXQiOjE2NTM1NDEzNDMsImV4cCI6MTY1MzgwMDU0M30.dM-vXivaWeC7ZcwYziEnJJ72WcN7LZrFJgbnj7ivF_E";
// export const TOKEN = JSON.parse(JSON.parse(localStorage.getItem("persist:root"))?.user)?.curruntUser?.accessToken;
// console.log("localstorage >>>", localStorage.getItem("persist:root"));

export const publicRequest = axios.create({
  baseURL: baseURL,
});

export const userRequest = axios.create({
  baseURL: baseURL,
  headers: {
    token: `Bearer ${TOKEN}`,
  },
});
