import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GlobalContext } from '../../context/Context';
import { getUsers, updateUser } from '../../store/redux-apis/userApiCalls';
import './Profile.css'
import { refreshPage } from '../../reloader/reloader'

const Profile = () => {
    const myData = useSelector((state) => state.user?.getMyData?.data);
    const avatar = myData && myData[0].avatar;
    console.log("myData: " + myData);
    const { state } = useContext(GlobalContext);
    const [picture, setPicture] = useState([]);
    const [userName, setUserName] = useState(state?.user?.userName);
    const [imgData, setImgData] = useState(null);
    // console.log("imgData >>>>", imgData);
    const [isLoading, setIsLoading] = useState(false);

    const onChangePicture = e => {
        if (e.target.files[0]) {
            console.log("picture:  >>>", e.target.files);
            // setPicture(e.target.files[0]);
            setPicture(e.target.files);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        // console.log("handleSubmit called");
        // console.log("user name is: " + userName);
        // console.log("user profile is: " + picture);
        let userData = {
            username: userName,
            avatar: imgData,
            id: state?.user?.id
        }
        console.log("user profile is: " + userData);
        const response = await updateUser(userData, dispatch)

        console.log('response: ' + response);
        if (response.status === 200) {
            // const getToken = response.data?.token;
            // localStorage.setItem("token", getToken);
            setIsLoading(false)
            getUsers(dispatch)
            toast.success("Update Successfully")
            setTimeout(() => {
                refreshPage()
            }, 100);

        } else {
            // toast.error(response.response.data.message)
            setIsLoading(false)
        }
    }

    return (
        <>
            <div className="card mb-5 mb-xl-10">
                {/*begin::Card header*/}
                <div className="card-header border-0 cursor-pointer" >
                    {/*begin::Card title*/}
                    <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Profile Details</h3>
                    </div>
                    {/*end::Card title*/}
                </div>
                {/*begin::Card header*/}
                {/*begin::Content*/}
                <div className="collapse show">
                    {/*begin::Form*/}
                    <form
                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                        noValidate="novalidate"
                        onSubmit={handleSubmit}
                    >
                        {/*begin::Card body*/}
                        <div className="card-body border-top p-9">
                            {/*begin::Input group*/}
                            <div className="row mb-6">
                                {/*begin::Label*/}
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">Avatar</label>
                                {/*end::Label*/}
                                {/*begin::Col*/}
                                <div className="col-lg-8">
                                    {/*begin::Image input*/}
                                    <div className="image-input image-input-outline"
                                        style={{ backgroundImage: imgData ? `url(${imgData})` : avatar ? `url(${avatar})` : 'url(/icons/dummyuser.jpg)' }}
                                    >
                                        {/*begin::Preview existing avatar*/}
                                        <div className="image-input-wrapper w-125px h-125px"
                                            style={{ backgroundImage: imgData ? `url(${imgData})` : avatar ? `url(${avatar})` : 'url(/icons/dummyuser.jpg)' }}
                                        />
                                        {/*end::Preview existing avatar*/}
                                        {/*begin::Label*/}
                                        <label
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                            data-kt-image-input-action="change"
                                            data-bs-toggle="tooltip"
                                            aria-label="Change avatar"
                                            data-bs-original-title="Change avatar"
                                            data-kt-initialized={1}
                                        >
                                            {/* <i className="bi bi-pencil-fill fs-7" /> */}
                                            <i className="fa-solid fa-pen"></i>

                                            {/*begin::Inputs*/}
                                            <input
                                                type="file"
                                                name="avatar"
                                                accept=".png, .jpg, .jpeg"
                                                onChange={(e) => onChangePicture(e)}
                                            />
                                            <input type="hidden" name="avatar_remove" />
                                            {/*end::Inputs*/}
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Cancel*/}
                                        <span
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                            data-kt-image-input-action="cancel"
                                            data-bs-toggle="tooltip"
                                            aria-label="Cancel avatar"
                                            data-bs-original-title="Cancel avatar"
                                            data-kt-initialized={1}
                                        // onClick={onChangeRemove}
                                        >
                                            <i className="fa-solid fa-xmark"></i>
                                        </span>
                                        {/*end::Cancel*/}
                                        {/*begin::Remove*/}
                                        <span
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                            data-kt-image-input-action="remove"
                                            data-bs-toggle="tooltip"
                                            aria-label="Remove avatar"
                                            data-bs-original-title="Remove avatar"
                                            data-kt-initialized={1}
                                        // onClick={onChangeRemove}
                                        >
                                            {/* <i className="bi bi-x fs-2" /> */}
                                            <i className="fa-solid fa-xmark"></i>
                                        </span>
                                        {/*end::Remove*/}
                                    </div>
                                    {/*end::Image input*/}
                                    {/*begin::Hint*/}
                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
                                    <div className="form-text">Allowed file size: Max. 300kb.</div>
                                    {/*end::Hint*/}
                                </div>
                                {/*end::Col*/}
                            </div>
                            {/*end::Input group*/}


                        </div>
                        {/*end::Card body*/}
                        {/*begin::Actions*/}
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button
                                type="reset"
                                className="btn btn-light btn-active-light-primary me-2"
                            >
                                Discard
                            </button>

                            <button
                                type="submit"
                                // className="btn btn-lg btn-primary w-100 mb-5"
                                className="btn btn-primary"

                            >
                                {isLoading === false ?
                                    <span className="indicator-label">Save Changes</span>
                                    : null}

                                {isLoading ?
                                    <span className="indicator-progress" >
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                    :
                                    null
                                }

                            </button>
                        </div>
                        {/*end::Actions*/}
                        <input type="hidden" /></form>
                    {/*end::Form*/}
                </div>
                {/*end::Content*/}
            </div>
        </>
    )
}

export default Profile


                            // {/*begin::Input group*/}
                            // <div className="row mb-6">
                            //     {/*begin::Label*/}
                            //     <label className="col-lg-4 col-form-label required fw-semibold fs-6">Full Name</label>
                            //     {/*end::Label*/}
                            //     {/*begin::Col*/}
                            //     <div className="col-lg-8">
                            //         {/*begin::Row*/}
                            //         <div className="row">
                            //             {/*begin::Col*/}
                            //             {/* <div className="col-lg-6 fv-row fv-plugins-icon-container"> */}
                            //             <div className="col-lg-12 fv-row fv-plugins-icon-container">
                            //                 <input
                            //                     type="text"
                            //                     name="fname"
                            //                     className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            //                     placeholder="First name"
                            //                     // defaultValue={state?.user?.userName}
                            //                     value={userName}
                            //                     // onChange={(e) => setUserName(e.target.value)}
                            //                     disabled
                            //                 />
                            //                 <div className="fv-plugins-message-container invalid-feedback" />
                            //             </div>
                            //             {/*end::Col*/}
                            //             {/*begin::Col*/}
                            //             {/* <div className="col-lg-6 fv-row fv-plugins-icon-container">
                            //                 <input type="text" name="lname" className="form-control form-control-lg form-control-solid" placeholder="Last name" defaultValue="" />
                            //                 <div className="fv-plugins-message-container invalid-feedback" />
                            //             </div> */}
                            //             {/*end::Col*/}
                            //         </div>
                            //         {/*end::Row*/}
                            //     </div>
                            //     {/*end::Col*/}
                            // </div>
                            // {/*end::Input group*/}
                            // {/*begin::Input group*/}
                            // <div className="row mb-6">
                            //     {/*begin::Label*/}
                            //     <label className="col-lg-4 col-form-label required fw-semibold fs-6">Email</label>
                            //     {/*end::Label*/}
                            //     {/*begin::Col*/}
                            //     <div className="col-lg-8 fv-row fv-plugins-icon-container">
                            //         <input
                            //             type="text"
                            //             name="email"
                            //             className="form-control form-control-lg form-control-solid"
                            //             placeholder="email"
                            //             defaultValue={state?.user?.email}
                            //             disabled
                            //         />
                            //         <div className="fv-plugins-message-container invalid-feedback" /></div>
                            //     {/*end::Col*/}
                            // </div>
                            // {/*end::Input group*/}