import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../store/redux-apis/loginApiCalls";
// import "./Header.css";
import { GlobalContext } from '../../context/Context';
import { refreshPage } from "../../reloader/reloader";

const Header = () => {
  const isUser = useSelector((state) => state.auth.curruntAuth);
  const myData = useSelector((state) => state.user?.getMyData?.data);
  const avatar = myData && myData[0].avatar;

  // console.log("isUser >>", isUser);
  const reduxDispatch = useDispatch()

  const [view, setView] = useState(false);
  // const [isLogout, setIsLogout] = useState(false);

  const navigate = useNavigate()

  const hanldeView = () => {
    setView((preValue) => !preValue);
  };

  const handleLogout = () => {
    console.log("logout");
    localStorage.removeItem("token");
    // setIsLogout((preValue) => !preValue)
    // navigate("../", { replace: true });
    logout(reduxDispatch)
    refreshPage()
  }

  const { state, dispatch } = useContext(GlobalContext);
  console.log("state >>>>", state)
  const handleToggleMenu = () => {
    console.log("toggle menu");
    dispatch({
      type: "TOGGLE_MOBILE"
    })
  }


  return (
    <div className="header align-items-stretch">
      {/*begin::Container*/}
      <div className="container-fluid d-flex align-items-stretch justify-content-between">
        {/*begin::Aside mobile toggle*/}
        <div
          className="d-flex align-items-center d-lg-none ms-n1 me-2"
          title="Show aside menu"
        >
          <div className="btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px" onClick={() => handleToggleMenu()}>
            {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
            <span className="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                  fill="currentColor"
                />
                <path
                  opacity="0.3"
                  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </div>
        </div>
        {/*end::Aside mobile toggle*/}
        {/*begin::Mobile logo*/}
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          {/* <a href className="d-lg-none">
            <img alt="Logo" src="" className="h-30px" />
          </a> */}
        </div>
        {/*end::Mobile logo*/}
        {/*begin::Wrapper*/}
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          {/*begin::Navbar*/}
          <div className="d-flex align-items-stretch">
            {/*begin::Menu wrapper*/}
            <div className="header-menu align-items-stretch">
              {/*begin::Menu*/}
              <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
                <div className="menu-item here show menu-lg-down-accordion me-lg-1">
                  <span className="menu-link py-3">
                    {/* <span className="menu-title">Dashboard</span> */}
                    <span className="menu-title fw-bolder">OVERALL EQUIPMENT EFFECTIVENESS</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </div>
                {/* <div className="menu-item menu-lg-down-accordion me-lg-1">
                  <span className="menu-link py-3">
                    <span className="menu-title">Administration</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </div> */}
                {/* <div className="menu-item menu-lg-down-accordion me-lg-1">
                  <span className="menu-link py-3">
                    <span className="menu-title">Users</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </div> */}
                {/* <div className="menu-item menu-lg-down-accordion me-lg-1">
                  <span className="menu-link py-3">
                    <span className="menu-title">System</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </div>
                <div className="menu-item menu-lg-down-accordion me-lg-1">
                  <span className="menu-link py-3">
                    <span className="menu-title">Mega Menu</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </div> */}
              </div>
              {/*end::Menu*/}
            </div>
            {/*end::Menu wrapper*/}
          </div>
          {/*end::Navbar*/}

          <div className="d-flex align-items-stretch flex-shrink-0">
            {/*begin::User menu*/}
            <div className="d-flex align-items-center ms-1 ms-lg-3">
              {/*begin::Menu wrapper*/}
              <div
                className="cursor-pointer symbol symbol-30px symbol-md-40px"
                onClick={hanldeView}
              >
                <div className="menu-item px-3">
                  <div className="menu-content d-flex align-items-center px-3">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-50px me-5">
                      <img
                        alt="Logo"
                        src={avatar ? avatar : "/icons/dummyuser.jpg"}

                      />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Username*/}
                    <div className="d-flex flex-column">
                      <div className="fw-bolder d-flex align-items-center fs-5">
                        Alpha
                        <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                          Admin
                        </span>
                      </div>
                      <a
                        href
                        className="fw-bold text-muted text-hover-primary fs-7"
                      >
                        alpha@pa.com
                      </a>
                    </div>
                    {/*end::Username*/}
                  </div>
                </div>
                {/* <img
                  src="/icons/dummyuser.jpg"
                  alt="profile"
                /> */}
              </div>
              {/*begin::User account menu*/}
              <div
                className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px ${view ? "d-flex" : ""
                  }`}
                style={
                  view
                    ? {
                      zIndex: 105,
                      position: "absolute",
                      inset: "0px 0px auto auto",
                      margin: "0px",
                      transform: "translate3d(-30px, 65px, 0px)",
                    }
                    : {}
                }
              >
                {/*begin::Menu item*/}
                <div className="menu-item px-3">
                  <div className="menu-content d-flex align-items-center px-3">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-50px me-5">
                      <img
                        alt="Logo"
                        src={avatar ? avatar : "/icons/dummyuser.jpg"}
                      />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Username*/}
                    <div className="d-flex flex-column">
                      <div className="fw-bolder d-flex align-items-center fs-5">
                        Alpha
                        <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                          Admin
                        </span>
                      </div>
                      <a
                        href
                        className="fw-bold text-muted text-hover-primary fs-7"
                      >
                        alpha@pa.com
                      </a>
                    </div>
                    {/*end::Username*/}
                  </div>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu separator*/}
                <div className="separator my-2" />
                {/*end::Menu separator*/}
                {/*begin::Menu item*/}
                <div className="menu-item px-5">
                  <Link to="/account" className="menu-link px-5">
                    My Profile
                  </Link>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu item*/}
                {/* <div className="menu-item px-5">
                  <a href className="menu-link px-5">
                    My Statements
                  </a>
                </div> */}
                {/*end::Menu item*/}
                {/*begin::Menu separator*/}
                <div className="separator my-2" />
                {/*end::Menu separator*/}
                {/*begin::Menu item*/}
                <div className="menu-item px-5">
                  <a href className="menu-link px-5" onClick={handleLogout}>
                    Sign Out
                  </a>
                </div>
                {/*end::Menu item*/}
                {/*begin::Menu separator*/}
                {/* <div className="separator my-2" /> */}
                {/*end::Menu separator*/}
                {/*begin::Menu item*/}
                {/* <div className="menu-item px-5">
                  <div className="menu-content px-5">
                    <label
                      className="form-check form-switch form-check-custom form-check-solid pulse pulse-success"
                      htmlFor="user_menu_dark_mode_toggle"
                    >
                      <input
                        className="form-check-input w-30px h-20px"
                        type="checkbox"
                        defaultValue={1}
                        name="mode"
                        id="user_menu_dark_mode_toggle"
                      />
                      <span className="pulse-ring ms-n1" />
                      <span className="form-check-label text-gray-600 fs-7">
                        Dark Mode
                      </span>
                    </label>
                  </div>
                </div> */}
                {/*end::Menu item*/}
              </div>
              {/*end::User account menu*/}
              {/*end::Menu wrapper*/}
            </div>
            {/*end::User menu*/}

          </div>
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::Container*/}
    </div>
  );
};

export default Header;



// {/*begin::Menu item*/}
// <div className="menu-item px-5">
//   <a href className="menu-link px-5">
//     <span className="menu-title position-relative">
//       Language
//       <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
//         English
//         <img
//           className="w-15px h-15px rounded-1 ms-2"
//           src=""
//           alt=""
//         />
//       </span>
//     </span>
//   </a>
//   {/*begin::Menu sub*/}
//   <div className="menu-sub menu-sub-dropdown w-175px py-4">
//     {/*begin::Menu item*/}
//     <div className="menu-item px-3">
//       <a href className="menu-link d-flex px-5 active">
//         <span className="symbol symbol-20px me-4">
//           <img
//             className="rounded-1"
//             src="assets/media/flags/united-states.svg"
//             alt=""
//           />
//         </span>
//         English
//       </a>
//     </div>
//     {/*end::Menu item*/}
//     {/*begin::Menu item*/}
//     <div className="menu-item px-3">
//       <a href className="menu-link d-flex px-5">
//         <span className="symbol symbol-20px me-4">
//           <img
//             className="rounded-1"
//             src="assets/media/flags/spain.svg"
//             alt=""
//           />
//         </span>
//         Spanish
//       </a>
//     </div>
//     {/*end::Menu item*/}
//     {/*begin::Menu item*/}
//     <div className="menu-item px-3">
//       <a href className="menu-link d-flex px-5">
//         <span className="symbol symbol-20px me-4">
//           <img
//             className="rounded-1"
//             src="assets/media/flags/germany.svg"
//             alt=""
//           />
//         </span>
//         German
//       </a>
//     </div>
//     {/*end::Menu item*/}
//     {/*begin::Menu item*/}
//     <div className="menu-item px-3">
//       <a href className="menu-link d-flex px-5">
//         <span className="symbol symbol-20px me-4">
//           <img
//             className="rounded-1"
//             src="assets/media/flags/japan.svg"
//             alt=""
//           />
//         </span>
//         Japanese
//       </a>
//     </div>
//     {/*end::Menu item*/}
//     {/*begin::Menu item*/}
//     <div className="menu-item px-3">
//       <a href className="menu-link d-flex px-5">
//         <span className="symbol symbol-20px me-4">
//           <img
//             className="rounded-1"
//             src="assets/media/flags/france.svg"
//             alt=""
//           />
//         </span>
//         French
//       </a>
//     </div>
//     {/*end::Menu item*/}
//   </div>
//   {/*end::Menu sub*/}
// </div>
// {/*end::Menu item*/}
// {/*begin::Menu item*/}
// <div className="menu-item px-5 my-1">
//   <a href className="menu-link px-5">
//     Account Settings
//   </a>
// </div>
// {/*end::Menu item*/}



// // //  Icons Menue

            // {/*begin::Search*/}
            // <div className="d-flex align-items-stretch ms-1 ms-lg-3">
            //   {/*begin::Search*/}
            //   <div className="header-search d-flex align-items-stretch">
            //     {/*begin::Search toggle*/}
            //     <div className="d-flex align-items-center">
            //       <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px">
            //         {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
            //         <span className="svg-icon svg-icon-1">
            //           <svg
            //             xmlns="http://www.w3.org/2000/svg"
            //             width={24}
            //             height={24}
            //             viewBox="0 0 24 24"
            //             fill="none"
            //           >
            //             <rect
            //               opacity="0.5"
            //               x="17.0365"
            //               y="15.1223"
            //               width="8.15546"
            //               height={2}
            //               rx={1}
            //               transform="rotate(45 17.0365 15.1223)"
            //               fill="currentColor"
            //             />
            //             <path
            //               d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
            //               fill="currentColor"
            //             />
            //           </svg>
            //         </span>
            //         {/*end::Svg Icon*/}
            //       </div>
            //     </div>
            //     {/*end::Search toggle*/}
            //     {/*begin::Menu*/}
            //     {/*end::Menu*/}
            //   </div>
            //   {/*end::Search*/}
            // </div>
            // {/*end::Search*/}
            // {/*begin::Activities*/}
            // <div className="d-flex align-items-center ms-1 ms-lg-3">
            //   {/*begin::Drawer toggle*/}
            //   <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px">
            //     <i
            //       className="fa-regular fa-bell fs-2"
            //       style={{ color: "#A1A5B7" }}
            //     />
            //   </div>
            //   {/*end::Drawer toggle*/}
            // </div>
            // {/*end::Activities*/}
            // {/*begin::Quick links*/}
            // <div className="d-flex align-items-center ms-1 ms-lg-3">
            //   {/*begin::Menu wrapper*/}
            //   <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px">
            //     <i
            //       className="fa-regular fa-clipboard fs-2"
            //       style={{ color: "#A1A5B7" }}
            //     />
            //   </div>
            //   {/*begin::Menu*/}
            //   {/*end::Menu*/}
            //   {/*end::Menu wrapper*/}
            // </div>
            // {/*end::Quick links*/}
            // {/*begin::Notifications*/}
            // <div className="d-flex align-items-center ms-1 ms-lg-3">
            //   {/*begin::Menu wrapper*/}
            //   <div className="btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px pulse pulse-success">
            //     <i
            //       className="fa-solid fa-border-all fs-2"
            //       style={{ color: "#A1A5B7" }}
            //     />
            //     <span className="pulse-ring w-45px h-45px" />
            //   </div>
            //   {/*end::Menu wrapper*/}
            // </div>
            // {/*end::Notifications*/}
            // {/*begin::Theme mode*/}
            // <div className="d-flex align-items-center ms-1 ms-lg-3">
            // {/*begin::Menu toggle*/}
            // <a
            //     href
            //     className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            //   >
            //     <i className="fonticon-sun fs-2" />
            //     <i className="fonticon-moon fs-2 d-none" />
            //   </a>
            // {/*begin::Menu toggle*/}
            // {/*begin::Menu*/}
            // {/*end::Menu*/}
            // </div>
            // {/*end::Theme mode*/}


                      //   {/*begin::Heaeder menu toggle*/}
                      //   <div
                      //   className="d-flex align-items-center d-lg-none ms-2"
                      //   title="Show header menu"
                      // >
                      //   <div className="btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px">
                      //     {/*begin::Svg Icon | path: icons/duotune/text/txt001.svg*/}
                      //     <span className="svg-icon svg-icon-1">
                      //       <svg
                      //         xmlns="http://www.w3.org/2000/svg"
                      //         width={24}
                      //         height={24}
                      //         viewBox="0 0 24 24"
                      //         fill="none"
                      //       >
                      //         <path
                      //           d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                      //           fill="currentColor"
                      //         />
                      //         <path
                      //           opacity="0.3"
                      //           d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                      //           fill="currentColor"
                      //         />
                      //       </svg>
                      //     </span>
                      //     {/*end::Svg Icon*/}
                      //   </div>
                      // </div>
// {/*end::Heaeder menu toggle*/}