import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import moment from 'moment'
// React bootstrap
import { Modal } from "react-bootstrap";
// React Redux
import { useDispatch, useSelector } from "react-redux";
// import ToolBar from "../../components/ToolBar/ToolBar";
import {
  // createStops, 
  getStops, updateStops
} from "../../store/redux-apis/stopsApiCalls";
import { getCompleteOrders, updateCompleteOrders, updateCompleteOrdersByBox } from "../../store/redux-apis/completeOrderApiCalls";
import { getCauses } from "../../store/redux-apis/causeApiCalls";
// DateTimePickerTabs
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { publicRequest } from "../../api/requestMethods";
import { baseURL } from "../../core";
import { getCurruntStatus } from "../../store/redux-apis/curruntStatusApiCalls";
import DatePickerComp from "../../components/DatePicker/DatePicker"
// import axios from "axios";
import { toast } from "react-toastify";

const ProductionView = () => {

  const curruntProductionViewData = useSelector((state) => state.completeOrder.completeOrder);
  // console.log("curruntProductionViewData >>>", curruntProductionViewData);
  const curruntProductionViewDataForMT = curruntProductionViewData?.map((d) => ({ ...d }));

  function dataSorted() {
    let sortOrders = curruntProductionViewDataForMT.sort(function (a, b) {
      let deptA = a.plannedStart;
      let deptB = b.plannedStart;
      if (deptA > deptB) {
        return -1;
      } else if (deptA < deptB) {
        return 1;
      }
      return 0;
    });

    console.log("sortOrders >>>", sortOrders);
  }
  dataSorted();

  //Get Current Status Data
  const curruntStatusData = useSelector((state) => state.curruntStatus.curruntData.curruntStatus?.data);
  // console.log("curruntStatusData >>>", curruntStatusData);
  const curruntStatusDataForMT = curruntStatusData?.map((d) => ({ ...d }));

  // const stopsViewData = useSelector((state) => state.stop.stops?.data);
  // console.log("stopsViewData >>>", stopsViewData);
  // const stopsViewDataForMT = stopsViewData?.map((d) => ({ ...d }));

  const causeData = useSelector((state) => state.cause.causes?.data);
  // console.log("causeData >>>", causeData);
  // const causesDataForMT = causeData?.map((d) => ({ ...d }));
  // // States
  // For Modal states
  const [showShip, setShowShip] = useState(false);
  const [showLive, setShowLive] = useState(false);
  const [showStopModal, setShowStopModal] = useState(false);

  // For Date FIlter States
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [isFilter, setIsFilter] = useState(false);
  const [filterCompleteOrderData, setFilterCompleteOrderData] = useState([]);
  // For Refresh 
  const [refresh, setRefresh] = useState(1);
  // For condition
  const [isFetching, setIsFetching] = useState(false)

  //  For Array states
  const [curruntOrderStopsData, setCurruntOrderStopsData] = useState([])
  const [shipObj, setShipObj] = useState({})
  // Fro Shipable states
  const [shippableValue, setShippableValue] = useState(0);
  const [boxType, setBoxType] = useState('packets');
  // For Insert Extra stops states
  const [categoryName, setCategoryName] = useState("")
  const [subCategoryName, setSubCategoryName] = useState("")
  const [causeOne, setCauseOne] = useState("")
  const [causeTwo, setCauseTwo] = useState("")
  const [causeThree, setCauseThree] = useState("")
  const [stopsUpdateData, setStopsUpdateData] = useState([])
  const [startStopDate, setStartStopDate] = useState(new Date())
  const [endStopDate, setEndStopDate] = useState(new Date())
  // lookups
  const [causeOneLookupObj, setCauseOneLookupObj] = useState({})
  const [causeTwoLookupObj, setCauseTwoLookupObj] = useState({})
  const [causeThreeLookupObj, setCauseThreeLookupObj] = useState({})
  // const [causeOneLookupData, setCauseOneLookupData] = useState([])
  // const [causeTwoLookupData, setCauseTwoLookupData] = useState([])
  // const [causeThreeLookupData, setCauseThreeLookupData] = useState([])

  // Funtion for radio button
  const handleChangeRadio = (event) => setBoxType(event.target.value);

  // For Open and Close Modal Shipable
  const handleCloseShip = () => setShowShip(false);
  const handleShowShip = () => setShowShip(true);

  // For Open and Close Modal Live Preview
  const handleCloseLive = () => setShowLive(false);
  const handleShowLive = () => setShowLive(true);

  // For Insert Extra Stops
  const handleStopModalClose = () => setShowStopModal(false);
  const handleStopModalShow = () => setShowStopModal(true);

  // For Filter date functions
  const handleChangeOne = (newValue) => setStartDate(newValue);
  const handleChangeTwo = (newValue) => setEndDate(newValue);

  const dispatch = useDispatch();
  useEffect(() => {
    getCompleteOrders(dispatch);
    getStops(dispatch)
    getCurruntStatus(dispatch);
    getCauses(dispatch);
    setTimeout(() => {
      getCompleteOrders(dispatch);
    }, 70000);
  }, [dispatch, refresh]);



  const getFilterStops = (deviceName, plannedStart) => {
    setIsFetching(false)

    publicRequest.get(
      `${baseURL}/stops/getStopsByMachineAndStartTime?deviceName=${deviceName}&plannedStart=${plannedStart}`
    )
      .then((res) => {
        // console.log("response", res);
        if (res.status === 200) {
          let curruntOrderStops = res.data.data?.map(item => {
            if (item.downTime) {
              item.downTime = (new Date(item.downTime * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0]
            }
            return item
          })
          // console.log("curruntOrderStops >>>", curruntOrderStops);
          setCurruntOrderStopsData(curruntOrderStops);
          setRefresh((preValue) => !preValue);
          setIsFetching(true)

        }
      });
  };


  const handleShipable = (e) => {
    e.preventDefault()
    // console.log("boxType: >>>", boxType);
    // console.log("shippableValue: >>>", shippableValue);

    var ship = 0
    if (boxType === "large") {
      ship = shippableValue * 144
    }
    else if (boxType === "small") {
      ship = shippableValue * 48
    }
    else {
      ship = shippableValue
    }
    // console.log("ship: ", ship);

    if (boxType) {
      updateCompleteOrdersByBox({ id: shipObj.id, shippableProduct: ship }, dispatch)
      handleCloseShip()
      setRefresh((preValue) => !preValue);
      // let sendData = axios.post('http://localhost:3000/api/orders/updateShippableByBox', { id: rowDataObjectShip.id, shippableProducts: ship })
      // if (sendData.status === 200) {
      //   setShipOpen(true)
      // }
      // else {
      //   console.log(selectMachine3);
      //   if (selectMachine3.length) {
      //     console.log("true");
      //     submitHandler3();
      //   }
      //   console.log(selectMachine3);
      //   if (selectMachine3.length) {
      //     console.log("true");
      //     submitHandler3();
      //   }
      //   setShipOpen(false)
      //   console.log("err")
      // }
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let startT = moment.utc(startStopDate, "MM/DD/YYYY HH:mm:ss").toISOString();
    let endT = moment.utc(endStopDate, "MM/DD/YYYY HH:mm:ss").toISOString();
    let downTimeTemp = Date.parse(endT) - Date.parse(startT);
    downTimeTemp = Math.ceil(downTimeTemp / 1000);
    // console.log("downTimeTemp: >>>", downTimeTemp);
    // var hms = stopsUpdateData.downTime;   // your input string
    // console.log("hms: >>>", hms);
    // var a = hms.split(':'); // split it at the colons
    // console.log("a: >>>", a);

    // var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    // console.log("seconds: ", seconds);

    const data = {
      // startTime: moment(startStopDate).format("MM/DD/YYYY hh:mm:ss a"),
      // endTime: moment(endStopDate).format("MM/DD/YYYY hh:mm:ss a"),
      startTime: startT,
      endTime: endT,
      categoryName: categoryName,
      subCategoryName: subCategoryName,
      causeOne: causeOne,
      causeTwo: causeTwo,
      causeThree: causeThree,
      deviceName: stopsUpdateData.deviceName,
      downTime: downTimeTemp,
      skuName: stopsUpdateData.skuName,
      shiftName: stopsUpdateData.shiftName,
      stopUpdatedTime: stopsUpdateData.stopUpdatedTime,
      stopUpdatedBy: stopsUpdateData.stopUpdatedBy,
    }
    // console.log("data >>>", data)

    // //  insertExtraStops
    // createStops(dispatch, data)
    setRefresh((preValue) => !preValue);

    getFilterStops(data.deviceName, data.startTime);
    handleStopModalClose()
  }

  const handleSearch = async () => {
    setIsFilter(true)
    console.log("search functionality added");
    let startDateMoment = moment(startDate).format("YYYY-MM-DD")
    let endDateMoment = moment(endDate).format("YYYY-MM-DD")
    // console.log("startDateMoment: " + startDateMoment);
    // console.log("endDateMoment: " + endDateMoment);
    publicRequest.get(
      `${baseURL}/completeOrders/getFilterCompleteOrders?startDate=${startDateMoment}&endDate=${endDateMoment}`
    )
      .then((res) => {
        if (res.status === 200) {
          // console.log("response >>>", res);
          // setIsFilter(false)

          var outputArray = [];
          var count = 0;
          var start = false;

          for (let j = 0; j < res.data?.data.length; j++) {
            for (let k = 0; k < outputArray.length; k++) {
              if (res.data?.data[j].plannedStart == outputArray[k].plannedStart) {
                start = true;
              }
            }
            count++;
            if (count == 1 && start == false) {
              outputArray.push(res.data?.data[j]);
            }
            start = false;
            count = 0;
          }
          setFilterCompleteOrderData(outputArray)
          // setFilterCompleteOrderData(res.data.data)
          // setRefresh((preValue) => !preValue);
        }
      }).catch((err) => {
        console.log("erro from get completeOrder filter data", err);
        // setIsFilter(false)

      })
  }

  const handleRemoveFilter = () => {
    setIsFilter(false)
    setFilterCompleteOrderData([])
  }

  useEffect(() => {
    const causeOneLookup = causeData?.reduce(function (acc, cur, i) {
      // console.log("acc >>>", acc);
      // acc[cur.id] = cur.causeOne;
      acc[cur.causeOne] = cur.causeOne;
      return acc;
    }, {});

    setCauseOneLookupObj(causeOneLookup)
    const causeTwoLookup = causeData?.reduce(function (acc, cur, i) {
      acc[cur.causeTwo] = cur.causeTwo;
      return acc;
    }, {});

    setCauseTwoLookupObj(causeTwoLookup)
    // console.log("causeTwoLookup >>>>", causeTwoLookup);
    const causeThreeLookup = causeData?.reduce(function (acc, cur, i) {
      // console.log("acc >>>", acc);
      acc[cur.causeThree] = cur.causeThree;
      return acc;
    }, {});

    setCauseThreeLookupObj(causeThreeLookup)
    // console.log("causeThreeLookup >>>>", causeThreeLookup);
    // const causeOneData = causeData?.map(item => (
    //   {
    //     name: item.causeOne,
    //     value: item.causeOne

    //   }
    // ))
    // console.log("causeOneData >>>", causeOneData)
    // setCauseOneLookupData(causeOneData)
  }, [causeData]);




  // useEffect(() => {
  //   console.log("running inserting call start");
  //   const handleInserStopsAndCompleteOrders = async () => {
  //     const inserStopsAndCompleteOrders = await axios.get(
  //       `${baseURL}/deviceData/getDeviceDataAndEditCompleteOrdersAndInsertStops`
  //     );
  //     console.log("inserStopsAndCompleteOrders >>>", inserStopsAndCompleteOrders)
  //     return inserStopsAndCompleteOrders;
  //   }
  //   setTimeout(() => {
  //     console.log("running inserting call success");
  //     handleInserStopsAndCompleteOrders()
  //   }, 60000);
  //   // }, 300000);
  // }, [])


  const completeOrderColumns = [
    { title: "Device Name", field: "deviceName", editable: "never" },
    { title: "PO Number", field: "poNumber", },
    { title: "Distributer", field: "distributerName", },
    // { title: "Team", field: "teamName", },
    { title: "SKU", field: "skuName", },
    { title: "Pcs/min", field: "skuTarget", },
    { title: "Shift", field: "shiftName", },
    { title: "Start Time", field: "plannedStart", },
    { title: "End Time", field: "plannedEnd", },
    { title: "Status", field: "deviceStatus", },
    { title: "Counted", field: "productCount", },
    { title: "Shippable Pcs", field: "shippableProduct", },
  ]

  return (
    <>
      {/* begin: Toolbar */}
      {/* <ToolBar /> */}
      <div className="toolbar py-2">
        {/*begin::Container*/}
        <div className="container-fluid d-flex align-items-center flex-wrap mx-1">
          <div className="row w-100 m-0">
            <div className="col-sm-4 px-0">
              <div className="page-title d-flex align-items-center flex-wrap me-3 mb-lg-0 h-100">
                {/*begin::Title*/}
                <div className="h2 d-flex flex-wrap align-items-center text-dark fw-bolder my-1 fs-3 h-100 fs-xs-12">
                  PRODUCTION VIEW
                  {/*begin::Separator*/}
                  <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                  {/*end::Separator*/}
                  {/*begin::Description*/}
                  {/* <small className="text-muted fs-7 fw-bold my-1 ms-1">
                    #Description
                  </small> */}
                  {/*end::Description*/}
                </div>
                {/*end::Title*/}
              </div>
            </div>
            {/* <div className="w-75"> */}
            {/*begin::Action group*/}
            <div className="col-sm-8 px-0">
              {/*begin::Wrapper*/}
              <ul className="nav d-flex align-items-center w-100">
                <div className="row w-100 m-0">
                  <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 py-2">
                    {/* <div className="d-flex justify-content-between align-items-center h-100"> */}
                    <div className="d-flex justify-content-center align-items-center h-100">
                      {/* <li className="nav-item px-1">

                        <a
                          className="btn btn-sm btn-primary cursor-pointer"
                          href
                        >
                          View All Orders
                        </a>
                      </li> */}
                      {/* <li className="nav-item px-1"> */}
                      <a
                        className="btn btn-sm btn-success cursor-pointer"
                        onClick={handleShowLive}

                      >
                        {/* Preview Live */}
                        Live Machine Status
                      </a>
                      {/* </li> */}

                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 py-2">
                    <div className="row m-0 d-flex align-items-center justify-content-between">

                      <div className="col-lg-10 col-md-10 col-sm-8 py-2">
                        <div className="d-flex d-flex align-items-center w-100">
                          {/*begin::Daterangepicker*/}

                          <li className="nav-item px-1 w-50">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                <DatePicker
                                  disableFuture
                                  label="Start Date"
                                  openTo="day"
                                  // views={['year', 'month', 'day']}
                                  value={startDate}
                                  onChange={handleChangeOne}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </li>
                          <li className="nav-item px-1 w-50">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                <DatePicker
                                  disableFuture
                                  label="End Date"
                                  // inputFormat="MM/dd/yyyy"
                                  openTo="day"
                                  value={endDate}
                                  onChange={handleChangeTwo}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </li>
                          {/*end::Daterangepicker*/}

                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-4 py-2">
                        <div className="d-flex align-items-center w-100">

                          {/*begin::Actions*/}
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary cursor-pointer mx-1"
                              onClick={handleSearch}
                            >
                              <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                            <button
                              type="reset"
                              className="btn btn-sm btn-danger cursor-pointer mx-1"
                              onClick={handleRemoveFilter}
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </button>
                          </div>
                          {/*end::Actions*/}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </ul>
              {/*end::Wrapper*/}

            </div>
            {/*end::Action group*/}
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/* end: Toolbar */}
      <div className="content d-flex flex-column flex-column-fluid">
        {/*begin::Container*/}
        <div className="container-xxl">
          {/*begin::Row*/}
          <div className="card">
            <div className="material-table__box">
              {isFilter ?
                <MaterialTable
                  style={{
                    zIndex: 0
                  }}
                  title="Production View"
                  actions={[
                    {
                      icon: "visibility",
                      tooltip: "View Stops",
                      onClick: async (event, rowData) => {
                        let deviceName = rowData.deviceName;
                        // console.log(`Device Name: ${deviceName}`);
                        let plannedStart = rowData.plannedStart;
                        getFilterStops(deviceName, plannedStart)
                      },
                    },
                    {
                      icon: "add",
                      tooltip: "Add Shippable",
                      onClick: async (event, rowData) => {
                        setShipObj(rowData)
                        handleShowShip();
                      }
                    },
                  ]}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataUpdate = [...filterCompleteOrderData];
                          const index = oldData.id;
                          console.log("index >>>", index);
                          dataUpdate[index] = newData;
                          updateCompleteOrders(dataUpdate[index], dispatch);
                          setRefresh((preValue) => !preValue);
                          getFilterStops(
                            newData.deviceName,
                            newData.startTime
                          );
                          resolve();
                        }, 1000);
                      }),
                  }}
                  columns={completeOrderColumns}
                  data={filterCompleteOrderData}
                  options={{
                    // actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                      alert(
                        "You should develop a code to export " +
                        data.length +
                        " rows"
                      );
                    },
                    showTitle: false,
                    filtering: true,
                    search: false,

                    pageSizeOptions: [
                      5,
                      10,
                      20,
                      { value: filterCompleteOrderData?.length, label: "All" },
                    ],
                    // pageSizeOptions: [5, 10, 20],
                    headerStyle: {
                      color: "#A1A5B7 ",
                      borderBottom: "#E4E6EF",
                      borderBottomWidth: "1px",
                      borderBottomStyle: "dashed",
                      fontWeight: "600",
                      fontSize: "16px",
                    },
                  }}

                />
                :
                <MaterialTable
                  style={{
                    zIndex: 0
                  }}
                  title="Production View"
                  actions={[
                    {
                      icon: "visibility",
                      tooltip: "View Stops",
                      onClick: async (event, rowData) => {
                        let deviceName = rowData.deviceName;
                        // console.log(`Device Name: ${deviceName}`);
                        let plannedStart = rowData.plannedStart;
                        getFilterStops(deviceName, plannedStart)
                        console.log("curruntOrderStopsData >>", curruntOrderStopsData);
                        if (isFetching === true) {
                          if (curruntOrderStopsData.length === 0) {
                            toast.warn("Stops not found in this Order")
                          } else {
                            console.log("stops found >>>");
                          }
                        }
                      },
                    },
                    {
                      icon: "add",
                      tooltip: "Add Shippable",
                      onClick: async (event, rowData) => {
                        setShipObj(rowData)
                        handleShowShip();
                      }
                    },
                  ]}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataUpdate = [...curruntProductionViewDataForMT];
                          const index = oldData.id;
                          console.log("index >>>", index);
                          dataUpdate[index] = newData;
                          updateCompleteOrders(dataUpdate[index], dispatch);
                          setRefresh((preValue) => !preValue);
                          getFilterStops(
                            newData.deviceName,
                            newData.startTime
                          );
                          resolve();
                        }, 1000);
                      }),
                  }}
                  columns={completeOrderColumns}
                  data={curruntProductionViewDataForMT}
                  options={{
                    // actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                      alert(
                        "You should develop a code to export " +
                        data.length +
                        " rows"
                      );
                    },
                    showTitle: false,
                    filtering: true,
                    search: false,
                    pageSizeOptions: [
                      5,
                      10,
                      20,
                      { value: curruntProductionViewDataForMT?.length, label: "All" },
                    ],
                    // pageSizeOptions: [5, 10, 20],
                    headerStyle: {
                      color: "#A1A5B7 ",
                      borderBottom: "#E4E6EF",
                      borderBottomWidth: "1px",
                      borderBottomStyle: "dashed",
                      fontWeight: "600",
                      fontSize: "16px",
                    },
                  }}

                />
              }

            </div>
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Container*/}
      </div>
      {/* Stops Table ===============================================> */}
      <div className="content d-flex flex-column flex-column-fluid">
        {/*begin::Container*/}
        <div className="container-xxl">
          {/*begin::Row*/}
          <div className="card">
            {/* <div className="card-header border-0 pt-6 d-flex justify-content-between">
            </div> */}
            {curruntOrderStopsData.length >= 1 ?
              <div className="material-table__box">
                <MaterialTable
                  style={{
                    zIndex: 0
                  }}
                  title="Stops View"
                  actions={[
                    {
                      icon: "add",
                      tooltip: "Add Stop",
                      onClick: (event, rowData) => {
                        setStopsUpdateData(rowData)
                        handleStopModalShow()
                      },
                    },
                  ]}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataUpdate = [...curruntOrderStopsData];
                          const index = oldData.id;
                          console.log("index >>>", index);
                          dataUpdate[index] = newData;
                          updateStops({
                            tableData: dataUpdate[index],
                            stopUpdatedTime: (new Date().toLocaleString().replace(",", "")),
                            stopUpdatedBy: "Alpha"
                          }, dispatch);
                          setRefresh((preValue) => !preValue);
                          getFilterStops(
                            newData.deviceName,
                            newData.startTime
                          );
                          resolve();
                        }, 1000);
                      }),
                  }}
                  columns={[
                    { title: "Device Name", field: "deviceName", editable: "never" },
                    { title: "Start Time", field: "startTime", editable: "never" },
                    { title: "End Time", field: "endTime", editable: "never" },
                    {
                      title: "Category", field: "categoryName",
                      lookup: { Planned: "Planned", Unplanned: "Unplanned" },

                    },
                    {
                      title: "Sub Category", field: "subCategoryName",
                      lookup: {
                        Technical: "Technical",
                        Operational: "Operational ",
                      },
                      filtering: false,
                    },
                    {
                      title: "Cause One", field: "causeOne",
                      lookup: causeOneLookupObj,
                      // editComponent: props => (

                      // ),
                    },
                    {
                      title: "Cause Two", field: "causeTwo",
                      lookup: causeTwoLookupObj,
                    },
                    {
                      title: "Cause Three", field: "causeThree",
                      lookup: causeThreeLookupObj,
                    },
                    { title: "DownTime", field: "downTime", },
                    { title: "SKU", field: "skuName", editable: "never" },
                    { title: "Shift", field: "shiftName", editable: "never" },
                    { title: "Stops Update Time", field: "stopUpdatedTime", editable: "never" },
                    { title: "Stops Update By", field: "stopUpdatedBy", editable: "never" },
                  ]}
                  data={curruntOrderStopsData}
                  options={{
                    // actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                      alert(
                        "You should develop a code to export " +
                        data.length +
                        " rows"
                      );
                    },
                    showTitle: true,
                    filtering: true,
                    search: false,
                    pageSizeOptions: [
                      5,
                      10,
                      20,
                      { value: curruntOrderStopsData?.length, label: "All" },
                    ],
                    // pageSizeOptions: [5, 10, 20],
                    headerStyle: {
                      color: "#A1A5B7 ",
                      borderBottom: "#E4E6EF",
                      borderBottomWidth: "1px",
                      borderBottomStyle: "dashed",
                      fontWeight: "600",
                      fontSize: "16px",
                    },
                  }}
                />
              </div>
              :
              <div className="d-flex align-items-center justify-content-center">
                <h3>
                  Please select Specific Order
                </h3>
              </div>
            }

          </div>
          {/*end::Row*/}
        </div>
        {/*end::Container*/}
        {/*begin::Modal - Add Stop*/}
        <Modal show={showStopModal} onHide={handleStopModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          {/*begin::Modal body*/}
          <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
            {/*begin::Form*/}
            <form
              className="form fv-plugins-bootstrap5 fv-plugins-framework"
              onSubmit={handleSubmit}
            >
              {/*begin::Scroll*/}
              <div
                className="d-flex flex-column scroll-y me-n7 pe-7"
                style={{ maxHeight: "422px" }}
              >
                <div className="d-flex">

                  {/*begin::Input group*/}
                  <div className="fv-row mb-7 fv-plugins-icon-container pe-1">
                    {/*begin::Label*/}
                    <label className="required fw-bold fs-6 mb-2">
                      Start Time
                    </label>
                    {/*end::Label*/}
                    {/*begin::DatePickerComp*/}
                    <DatePickerComp value={startStopDate} setValue={setStartStopDate} />
                    {/*end::DatePicker*/}
                    <div className="fv-plugins-message-container invalid-feedback" />
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="fv-row mb-7 fv-plugins-icon-container ps-1">
                    {/*begin::Label*/}
                    <label className="required fw-bold fs-6 mb-2">
                      End Time
                    </label>
                    {/*end::Label*/}
                    {/*begin::DatePickerComp*/}
                    <DatePickerComp value={endStopDate} setValue={setEndStopDate} />

                    {/*end::DatePickerComp*/}
                    <div className="fv-plugins-message-container invalid-feedback" />
                  </div>
                  {/*end::Input group*/}
                </div>
                <div className="d-flex">


                  {/*begin::Input group*/}
                  <div className="fv-row mb-7 fv-plugins-icon-container w-100 pe-1">
                    {/*begin::Label*/}
                    <label className="required fw-bold fs-6 mb-2">
                      Category
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <div className="w-100" data-select2-id="select2-data-131-tys6">
                      {/*begin::Select2*/}
                      <select className="form-select form-select-solid select2-hidden-accessible"
                        required
                        value={categoryName}
                        onChange={(e) => {
                          console.log("e.target.value", e.target.value);
                          setCategoryName(e.target.value)
                        }
                        }
                      >
                        {/* <option /> */}
                        <option value="" selected disabled hidden>Choose</option>
                        <option value="Unplanned" >Unplanned</option>
                        <option value="Planned" >Planned</option>
                      </select>
                      {/*end::Select2*/}
                    </div>
                    {/*end::Input*/}
                    <div className="fv-plugins-message-container invalid-feedback" />
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="fv-row mb-7 fv-plugins-icon-container w-100 ps-1">
                    {/*begin::Label*/}
                    <label className="required fw-bold fs-6 mb-2">
                      Sub Category
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <div className="w-100" data-select2-id="select2-data-131-tys6">
                      {/*begin::Select2*/}
                      <select className="form-select form-select-solid select2-hidden-accessible"
                        required
                        value={subCategoryName}
                        onChange={(e) =>
                          setSubCategoryName(e.target.value)
                        }
                      >
                        {/* <option /> */}
                        <option value="" selected disabled hidden>Choose</option>
                        <option value="Unplanned" >Operational</option>
                        <option value="Planned" >Technical</option>
                      </select>
                      {/*end::Select2*/}
                    </div>
                    {/*end::Input*/}
                    <div className="fv-plugins-message-container invalid-feedback" />
                  </div>
                  {/*end::Input group*/}
                </div>


                {/*begin::Input group*/}
                <div className="fv-row mb-7 fv-plugins-icon-container">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">
                    Cause One
                  </label>
                  {/*end::Label*/}
                  <div className="w-100" data-select2-id="select2-data-131-tys6">
                    {/*begin::Select2*/}
                    <select className="form-select form-select-solid select2-hidden-accessible"
                      required
                      value={causeOne}
                      onChange={(e) =>
                        setCauseOne(e.target.value)
                      }
                    >
                      {/* <option /> */}
                      <option value="" selected disabled hidden>Choose</option>
                      <option value="Unplanned" >Unplanned</option>
                      <option value="Planned" >Planned</option>
                    </select>
                    {/*end::Select2*/}
                  </div>
                  {/*begin::Input*/}
                  {/* <SelectSearch 
                className="select-search select-search--multiple"
                options={newnewArr1} 
                search
                filterOptions={fuzzySearch}
                value={cause1Stop}
                onChange={(e) => {
                  console.log(e)
                  setCause1Stop(e);
                }}
                name="language" 
                placeholder="Choose your Cause 1" /> */}
                  {/*end::Input*/}
                  <div className="fv-plugins-message-container invalid-feedback" />
                </div>
                {/*end::Input group*/}
                <div className="d-flex">

                  {/*begin::Input group*/}
                  <div className="fv-row mb-7 fv-plugins-icon-container w-100 pe-1">
                    {/*begin::Label*/}
                    <label className="required fw-bold fs-6 mb-2">
                      Cause Two
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <div className="w-100" data-select2-id="select2-data-131-tys6">
                      {/*begin::Select2*/}
                      <select className="form-select form-select-solid select2-hidden-accessible"
                        required
                        value={causeTwo}
                        onChange={(e) => {
                          setCauseTwo(e.target.value);
                        }}
                      >
                        {/* <option /> */}
                        <option value="" selected disabled hidden>Choose</option>
                        <option value="Unplanned" >Unplanned</option>
                        <option value="Planned" >Planned</option>
                      </select>
                      {/*end::Select2*/}
                    </div>
                    {/* <SelectSearch 
                className="select-search select-search--multiple"
                options={newnewArr1} 
                search
                filterOptions={fuzzySearch}
                value={cause1Stop}
                onChange={(e) => {
                  console.log(e)
                  setCause1Stop(e);
                }}
                name="language" 
                placeholder="Choose your Cause 1" /> */}
                    {/*end::Input*/}
                    <div className="fv-plugins-message-container invalid-feedback" />
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="fv-row mb-7 fv-plugins-icon-container w-100 ps-1">
                    {/*begin::Label*/}
                    <label className="required fw-bold fs-6 mb-2">
                      Cause Three
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <div className="w-100" data-select2-id="select2-data-131-tys6">
                      {/*begin::Select2*/}
                      <select className="form-select form-select-solid select2-hidden-accessible"
                        required
                        value={causeThree}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setCauseThree(e.target.value);
                        }}
                      >
                        {/* <option /> */}
                        <option value="" selected disabled hidden>Choose</option>
                        <option value="Unplanned" >Unplanned</option>
                        <option value="Planned" >Planned</option>
                      </select>
                      {/*end::Select2*/}
                    </div>
                    {/* <SelectSearch 
                className="select-search select-search--multiple"
                options={newnewArr1} 
                search
                filterOptions={fuzzySearch}
                value={cause1Stop}
                onChange={(e) => {
                  console.log(e)
                  setCause1Stop(e);
                }}
                name="language" 
                placeholder="Choose your Cause 1" /> */}
                    {/*end::Input*/}
                    <div className="fv-plugins-message-container invalid-feedback" />
                  </div>
                  {/*end::Input group*/}
                </div>
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-15">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  onClick={handleStopModalClose}
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                // onClick={handleClose}
                >
                  <span className="indicator-label">Submit</span>
                  {/* <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span> */}
                </button>
              </div>
              {/*end::Actions*/}
              <div />
            </form>
            {/*end::Form*/}
          </Modal.Body>
          {/*end::Modal body*/}
        </Modal>
        {/*end::Modal - Add Stop*/}
        {/*begin::Modal - Add Shipable product*/}
        <Modal show={showShip} onHide={handleCloseShip}>
          <Modal.Header closeButton>
            <Modal.Title>Shippable Product</Modal.Title>
          </Modal.Header>
          {/*begin::Modal body*/}
          <Modal.Body className="scroll-y mx-5 mx-xl-15 my-7">
            {/*begin::Form*/}
            <form
              className="form fv-plugins-bootstrap5 fv-plugins-framework"
              onSubmit={handleShipable}
            >
              {/*begin::Scroll*/}
              <div
                className="d-flex flex-column scroll-y me-n7 pe-7"
                style={{ maxHeight: "422px" }}
              >
                {/*begin::Input group*/}
                <div className="fv-row mb-7 fv-plugins-icon-container">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2 ">
                    Box Type
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input Radio*/}
                  <div className="w-100">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        defaultValue="large"
                        value={"large"}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio1">Large</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        defaultValue="small"
                        value={"small"}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">Small</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio3"
                        defaultValue="packets"
                        value={"packets"}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio3">Packets</label>
                    </div>
                  </div>


                  {/*end::Input Radio*/}
                  <div className="fv-plugins-message-container invalid-feedback" />
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7 fv-plugins-icon-container">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">
                    Enter Quantity
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input
                    type="number"
                    name="quantity"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="123"
                    defaultValue="0"
                    value={shippableValue}
                    onChange={(e) => setShippableValue(e.target.value)}
                  />
                  {/*end::Input*/}
                  <div className="fv-plugins-message-container invalid-feedback" />
                </div>
                {/*end::Input group*/}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-15">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  onClick={handleCloseShip}
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                >
                  <span className="indicator-label">Submit</span>
                  {/* <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span> */}
                </button>
              </div>
              {/*end::Actions*/}
              <div />
            </form>
            {/*end::Form*/}
          </Modal.Body>
          {/*end::Modal body*/}
        </Modal>
        {/*end::Modal - Add Shipable product*/}

        <Modal show={showLive} onHide={handleCloseLive} dialogClassName="modal-styling">
          <Modal.Header closeButton>
            <Modal.Title>Preview Machine Live</Modal.Title>
          </Modal.Header>
          {/*begin::Modal body*/}
          <Modal.Body className="scroll-y px-10 px-lg-15 pt-0 pb-15 w-100" >
            {/*begin:Live Data*/}
            <div className="material-table__box">
              <MaterialTable
                style={{
                  zIndex: 0,
                }}
                title="Currunt Status Details"
                columns={[
                  { title: "Device Name", field: "deviceName", editable: "never" },
                  {
                    title: "Device Status", field: "deviceStatus", editable: "never"
                    ,
                    render: (rowData) => {
                      console.log("curruntStatusData >> ", rowData);
                      return (
                        <span style={{ display: 'flex' }} >
                          {rowData.deviceStatus === 0 ? <div className="badge badge-light-danger px-2 py-1 rounded" >Off</div> : <div className="badge badge-light-success px-2 py-1 rounded" >On</div>}
                        </span>
                      );
                    }
                  },
                  { title: "PO Number", field: "poNumber", editable: "never" },
                  { title: "SKU", field: "skuName", editable: "never", },
                  { title: "Team", field: "teamName", editable: "never" },
                  { title: "Timestamp", field: "timestamp", editable: "never" },
                ]}
                data={curruntStatusDataForMT}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: false,
                  showTitle: false,
                  filtering: false,
                  search: false,
                  toolbar: false,
                  pageSize: 10,
                  // header:false,
                  pageSizeOptions: [
                    5,
                    10,
                    20,
                    { value: curruntStatusDataForMT?.length, label: "All" },
                  ],
                  headerStyle: {
                    // color: "#A1A5B7 ",
                    color: "#FFF ",
                    borderBottom: "#E4E6EF",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dashed",
                    backgroundColor: "#14a6f5a4",
                    fontWeight: "600",
                    fontSize: "16px",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                    borderRadius: "5px",
                  },
                }}
              />
            </div>
            {/*end:Live Data*/}
          </Modal.Body>
          {/*end::Modal body*/}
        </Modal>
      </div >
    </>
  );
};

export default ProductionView;
