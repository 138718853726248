import { createSlice } from "@reduxjs/toolkit";

const stopsSlice = createSlice({
  name: "stop",
  initialState: {
    stops: [],
    filterStops: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    // GET STOPS
    getStopsStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getStopsSuccess: (state, action) => {
      state.isFetching = false;
      state.stops = action.payload;
    },
    getStopsFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // GET FILTER STOPS
    getFilterStopsStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getFilterStopsSuccess: (state, action) => {
      state.isFetching = false;
      state.filterStops = action.payload;
    },
    getFilterStopsFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE STOPS
    deleteStopStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteStopSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteStopFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE STOPS
    updateStopStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateStopSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateStopFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD StopS
    addStopStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addStopSuccess: (state, action) => {
      state.isFetching = false;
      // state.stops.push(action.payload);
    },
    addStopFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getStopsFailure,
  getStopsStart,
  getStopsSuccess,
  getFilterStopsFailure,
  getFilterStopsStart,
  getFilterStopsSuccess,
  addStopFailure,
  addStopStart,
  addStopSuccess,
  updateStopFailure,
  updateStopStart,
  updateStopSuccess,
  deleteStopFailure,
  deleteStopStart,
  deleteStopSuccess,
} = stopsSlice.actions;

export default stopsSlice.reducer;
