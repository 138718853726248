import { createSlice } from "@reduxjs/toolkit";

const teamSlice = createSlice({
  name: "team",
  initialState: {
    teams: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET TEAMS
    getTeamStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getTeamSuccess: (state, action) => {
      state.isFetching = false;
      state.teams = action.payload;
    },
    getTeamFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE TEAMS
    deleteTeamStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteTeamSuccess: (state, action) => {
      state.isFetching = false;
      // state.teams.splice(
      //   // state.teams.data?.findIndex(
      //   state.teams.findIndex(
      //     //   (item) => item.id === action.payload.id
      //     (item) => item.id === action.payload
      //   ),
      //   1
      // );
    },
    deleteTeamFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE TEAMS
    updateTeamStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateTeamSuccess: (state, action) => {
      state.isFetching = false;
      // state.teams[
      //   state.teams.findIndex((item) => item.id === action.payload.id)
      // ] = action.payload.team;
    },
    updateTeamFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD TEAMS
    addTeamStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addTeamSuccess: (state, action) => {
      state.isFetching = false;
      console.log("action.payload from team reducer", action.payload);
      state.teams.push(action.payload);
    },
    addTeamFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getTeamStart,
  getTeamSuccess,
  getTeamFailure,
  deleteTeamStart,
  deleteTeamSuccess,
  deleteTeamFailure,
  updateTeamStart,
  updateTeamSuccess,
  updateTeamFailure,
  addTeamStart,
  addTeamSuccess,
  addTeamFailure,
} = teamSlice.actions;
export default teamSlice.reducer;
