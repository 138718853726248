import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import Dashboard from "../Dashboard/Dashboard";
import Users from "../Users/Users";
import "./Home.css";
import { Routes, Route } from "react-router-dom";
import Complains from "../Complains/Complains";
import Teams from "../Teams/Teams";
import Lines from "../Lines/Lines";
import SKU from "../SKU/SKU";
import CurruntStatus from "../CurruntStatus/CurruntStatus";
import Orders from "../Orders/Orders";
import StopDetails from "../StopDetails/StopDetails";
import Shift from "../Shift/Shift";
import ProductionView from "../ProductionView/ProductionView";
import Causes from "../Causes/Causes";
import Category from "../Category/Category";
import Distributer from "../Distributer/Distributer";
import Info from "../Info/Info";
import Account from "../Account/Account";

const Home = () => {
  return (
    <div className="d-flex flex-column flex-root">
      {/*begin::Page*/}
      <div className="page d-flex flex-row flex-column-fluid">
        {/*begin::Aside*/}
        <SideBar />
        {/*end::Aside*/}

        {/*begin::Wrapper*/}
        <div className="wrapper d-flex flex-column flex-row-fluid">
          {/*begin::Header*/}
          <Header />
          {/*end::Header*/}
          {/*begin::Routing*/}
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/complains" element={<Complains />} />
            <Route path="/team" element={<Teams />} />
            <Route path="/lines" element={<Lines />} />
            <Route path="/sku" element={<SKU />} />
            <Route path="/curruntStatus" element={<CurruntStatus />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/stopDetails" element={<StopDetails />} />
            <Route path="/shift" element={<Shift />} />
            <Route path="/productionView" element={<ProductionView />} />
            <Route path="/causes" element={<Causes />} />
            <Route path="/category" element={<Category />} />
            <Route path="/distributer" element={<Distributer />} />
            <Route path="/information" element={<Info />} />
            <Route path="/account" element={<Account />} />
          </Routes>
          {/*end::Routing*/}

          {/*begin::Content*/}
          {/* <Dashboard /> */}
          {/*end::Content*/}
          {/*begin::Footer*/}
          <Footer />
          {/*end::Footer*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::Page*/}
    </div>
  );
};

export default Home;
