import { createSlice } from "@reduxjs/toolkit";

const causeSlice = createSlice({
  name: "cause",
  initialState: {
    causes: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET cause
    getCauseStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getCauseSuccess: (state, action) => {
      state.isFetching = false;
      state.causes = action.payload;
    },
    getCauseFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE cause
    deleteCauseStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteCauseSuccess: (state, action) => {
      state.isFetching = false;
      // state.causes.splice(
      //   // state.causes.data?.findIndex(
      //   state.causes.findIndex(
      //     //   (item) => item.id === action.payload.id
      //     (item) => item.id === action.payload
      //   ),
      //   1
      // );
    },
    deleteCauseFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE cause
    updateCauseStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateCauseSuccess: (state, action) => {
      state.isFetching = false;
      console.log("action.payload >>>", action.payload);
      // state.causes[
      //   state.causes.findIndex((item) => item.id === action.payload.id)
      // ] = action.payload.cause;
    },
    updateCauseFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD cause
    addCauseStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addCauseSuccess: (state, action) => {
      state.isFetching = false;
      // state.causes.push(action.payload);
    },
    addCauseFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getCauseStart,
  getCauseSuccess,
  getCauseFailure,
  deleteCauseStart,
  deleteCauseSuccess,
  deleteCauseFailure,
  updateCauseStart,
  updateCauseSuccess,
  updateCauseFailure,
  addCauseStart,
  addCauseSuccess,
  addCauseFailure,
} = causeSlice.actions;
export default causeSlice.reducer;
