import { configureStore } from "@reduxjs/toolkit";
import lineReducer from "./reducers/lineReducer";
import skuReducer from "./reducers/skuReducer";
import teamReducer from "./reducers/teamReducer";
import authReducer from "./reducers/authReducer";
import userReducer from "./reducers/userReducer";
import distributerReducer from "./reducers/distributerReducer";
import categoryReducer from "./reducers/categoryReducer";
import complainReducer from "./reducers/complainReducer";
import orderReducer from "./reducers/orderReducer";
import curruntStatusReducer from "./reducers/curruntStatusReducer";
import stopsReducer from "./reducers/stopsReducer";
import completeOrderReducer from "./reducers/completeOrderReducer";
import shiftReducer from "./reducers/shiftReducer";
import causeReducer from "./reducers/causeReducer";

export default configureStore({
  reducer: {
    sku: skuReducer,
    team: teamReducer,
    line: lineReducer,
    auth: authReducer,
    user: userReducer,
    distributer: distributerReducer,
    category: categoryReducer,
    complain: complainReducer,
    order: orderReducer,
    curruntStatus: curruntStatusReducer,
    completeOrder: completeOrderReducer,
    stop: stopsReducer,
    shift: shiftReducer,
    cause: causeReducer,
  },
});
